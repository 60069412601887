<template>
  <div id="app">
    <div id="loading">
      <img src="@/assets/img/preloader.gif" alt="Loading..." class="preloader-gif"/>
    </div>
    <v-banner
      v-if="deferredPrompt"
      color="info"
      bg-color="#033"
      class="text-left"
      sticky="true"
      style='z-index:20001;'
    ><v-icon
        slot="icon"
        color="info"
        size="36"
      >
        mdi-information
      </v-icon>
        Get our free app. It won't take up space on your device and works offline!&nbsp;&nbsp;
          <b-button size="sm" variant="primary me-1"  @click="install">Install</b-button>
          <b-button size="sm" variant="secondary me-1" @click="dismiss">Dismiss</b-button>      
    
    </v-banner>
    <v-snackbar bottom right :value="updateExists" :timeout="0" color="primary">
      An update is available
      <v-btn text @click="refreshApp">
        Update
      </v-btn>
    </v-snackbar>
    <router-view />
    <i class="fas fa-robot" @click="toggleChat" id="robot-icon"></i>
    <chat v-if="isChatVisible" />
  </div>
</template>
<script>
import { loadStyles } from "@/loadStyles";
import Cookies from "js-cookie";
export default {
  setup() {
    loadStyles();

    // Your other setup logic
    // ...

    return {};
  },
  name: "App",
  data() {
    return {
      deferredPrompt: null,
      updateExists: false,
      registration: null,
      isChatVisible: false,
    };
  },
  created(){
    window.addEventListener('load', () => {
      document.getElementById('loading').style.display = 'none';
    });
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      if (Cookies.get("add-yourdoc-to-home-screen") === undefined) {
        this.deferredPrompt = e;
      }
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
    document.addEventListener('swUpdated', this.updateAvailable, { once: true });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    });
  },
  methods: {
    toggleChat() {
      this.isChatVisible = !this.isChatVisible;
    },
    async dismiss() {
      Cookies.set("add-yourdoc-to-home-screen", null, { expires: 3 });
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    refreshApp() {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    }
  }
};
</script>
<style>
@import '@fortawesome/fontawesome-free/css/all.css';

#robot-icon {
  font-size: 3rem;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  color: #0d82ff
}

#chat-container {
  width: 100%;
  height: 500px; /* Adjust the height as needed */
  position: fixed;
  bottom: 75px;
  right: 20px;
  z-index: 999;
  background: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.preloader-gif {
  width: 120px;
  height: 120px;
}
</style>