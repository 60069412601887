<template>
  <div class="main-wrapper search-page">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" :queryParam="$route.query" />

    <div class="doctor-content content">
      <div class="container">
        <b-alert variant="danger" v-model="showError" dismissible>
          {{ errorMessage }}
          <b-link href="/doctor-dashboard" class="alert-link">Go to Dashboard</b-link>
        </b-alert>
        <div class="back-link">
          <router-link to="/">
            <i class="fa-solid fa-arrow-left-long"></i>
            {{ $t('back_to_home') }}
          </router-link>
        </div>
        <!-- Doctor Search List -->
        <div class="row">
          <div class="col-xl-12 col-lg-12 map-view">
            <div class="row">
              <div class="col-lg-9">
                <div class="doctor-filter-info">
                  <div class="doctor-filter-inner">
                    <div>
                      <div
                        v-if="filteredDoctors.length > 0"
                        class="doctors-found d-flex align-items-center justify-content-between"
                      >
                        <p>
                          <span>{{ filteredDoctors.length }} {{ $t('doctors_found') }}</span>
                          <span
                            v-if="selectedSpeciality.length > 0"
                          >{{ $t('for') }} {{ selectedSpeciality.join(', ') }} {{ $t(' in ') }} {{ locationSearched }}</span>
                          <span v-else>{{ $t(' in ') }} {{ locationSearched }}</span>
                        </p>
                        <p class="filter-today d-flex align-items-center">
                          <i class="feather-calendar"></i>
                          {{ $t('today') }} {{ start_day }} {{ $t('to') }} {{ end_day }}
                        </p>
                      </div>
                      <div v-else>
                        <p>
                          <span
                            v-if="selectedSpeciality.length > 0"
                          >{{ $t('no') }} {{ selectedSpeciality.join(', ') }} {{ $t('found') }}</span>
                          <span v-else>{{ $t('no_doctors_found') }}</span>
                          {{ $t('in') }} {{ locationSearched }}.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="doctor-filter-info">
                  <div class="doctor-filter-inner">
                    <div>
                      <div class="doctor-filter-availability">
                        <div class="dropdown">
                          <button
                            class="btn dropdown-toggle"
                            type="button"
                            id="genderDropdown"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >{{ $t('availability') }}</button>
                          <div class="dropdown-menu" aria-labelledby="genderDropdown">
                            <label class="dropdown-item">
                              <input
                                type="checkbox"
                                v-model="selectedAvailability"
                                value="today"
                                @change="applyLocalFilters()"
                              />
                              {{ $t('available_today') }}
                            </label>
                            <label class="dropdown-item">
                              <input
                                type="checkbox"
                                v-model="selectedAvailability"
                                value="In7days"
                                @change="applyLocalFilters()"
                              />
                              {{ $t('available_next_7_days') }}
                            </label>
                            <label class="dropdown-item">
                              <input
                                type="checkbox"
                                v-model="selectedAvailability"
                                value="In14days"
                                @change="applyLocalFilters()"
                              />
                              <span class="checkmark"></span>
                              {{ $t('available_next_14_days') }}
                            </label>
                            <a
                              @click="resetAvailabilityFilter()"
                              style="color: #007bff;"
                            >{{ $t('reset') }}</a>
                          </div>
                        </div>
                        <div>
                          <div class="doctor-filter-availability">
                            <div class="dropdown">
                              <button
                                class="btn dropdown-toggle"
                                type="button"
                                id="genderDropdown"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >{{ $t('gender') }}</button>
                              <div class="dropdown-menu" aria-labelledby="genderDropdown">
                                <label class="dropdown-item">
                                  <input
                                    type="checkbox"
                                    v-model="selectedGender"
                                    value="M"
                                    @change="applyLocalFilters()"
                                  />
                                  <span class="checkmark"></span>
                                  {{ $t('male') }}
                                </label>
                                <label class="dropdown-item">
                                  <input
                                    type="checkbox"
                                    v-model="selectedGender"
                                    value="F"
                                    @change="applyLocalFilters()"
                                  />
                                  <span class="checkmark"></span>
                                  {{ $t('female') }}
                                </label>

                                <a
                                  @click="resetGenderFilter()"
                                  style="color: #007bff;"
                                >{{ $t('reset') }}</a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div class="doctor-filter-availability">
                            <div class="dropdown">
                              <button
                                class="btn dropdown-toggle"
                                type="button"
                                id="genderDropdown"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >{{ $t('languages') }}</button>
                              <div class="dropdown-menu" aria-labelledby="genderDropdown">
                                <label class="dropdown-item">
                                  <input
                                    type="checkbox"
                                    v-model="selectedLanguage"
                                    value="English"
                                    @change="applyLocalFilters()"
                                  />
                                  English
                                </label>

                                <label class="dropdown-item">
                                  <input
                                    type="checkbox"
                                    v-model="selectedLanguage"
                                    value="Hindi"
                                    @change="applyLocalFilters()"
                                  />
                                  हिंदी
                                </label>
                                <label class="dropdown-item">
                                  <input
                                    type="checkbox"
                                    v-model="selectedLanguage"
                                    value="Kannada"
                                    @change="applyLocalFilters()"
                                  />
                                  ಕನ್ನಡ
                                </label>
                                <label class="dropdown-item">
                                  <input type="checkbox" v-model="selectedLanguage" value="Tulu"
                                    @change="applyLocalFilters()" />
                                  ತುಳು
                                </label>
                                <label class="dropdown-item">
                                  <input
                                    type="checkbox"
                                    v-model="selectedLanguage"
                                    value="Malayalam"
                                    @change="applyLocalFilters()"
                                  />
                                  <span class="checkmark"></span>
                                  മലയാളം
                                </label>
                                <a
                                  @click="resetLanguagesFilter()"
                                  style="color: #007bff;"
                                >{{ $t('reset') }}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="doctor-filter-option">
                      <div class="doctor-filter-sort">
                        <p style="color: #3b3b3b; ">{{ $t('sort') }}</p>
                        <div class="doctor-filter-select">
                          <!-- <vue-select :options="Sort" placeholder="A to Z" /> -->
                          <select v-model="selectedSort" @change="sortDoctors">
                            <option value="A to Z">{{ $t('a_to_z') }}</option>
                            <option value="Z to A">{{ $t('z_to_a') }}</option>
                          </select>
                        </div>
                      </div>

                      <!--<div class="doctor-filter-sort">
                        <ul class="nav">
                          <li>
                            <a href="javascript:void(0);" id="map-list">
                              <i class="feather-map-pin"></i>
                            </a>
                          </li>
                          <li>
                            <router-link
                              @click="redirectReloadSearch"
                              to="doctor-search-grid"
                            >
                              <i class="feather-grid"></i>
                            </router-link>
                          </li> 
                          <li>
                            <router-link to="/search-2" class="active">
                              <i class="feather-list"></i>
                            </router-link>
                          </li>
                        </ul>
                      </div>-->
                    </div>
                  </div>
                </div>
                <div v-if="!doctors && !noDataFound" class="col-md-10" style="padding:30px">
                  <div id="loading">
                    <img src="@/assets/img/search-svg.gif" alt="Loading..." class="preloader-gif" />
                  </div>
                </div>
                <div v-else-if="noDataFound" class="col-md-10" style="padding:30px">
                  <div>{{ $t('no_doctors_found_broad_search') }}</div>
                </div>
                <div
                  v-else
                  class="card doctor-card"
                  v-for="doctor in paginatedDoctors"
                  :key="doctor.doctorid"
                >
                  <div class="card-body">
                    <div class="doctor-widget-one">
                      <div class="doc-info-left">
                        <div class="doctor-img">
                          <router-link
                            :to="{ name: 'doctor-profile', params: { id: doctor.doctorid } }"
                          >
                            <img
                              :src="getDoctorProfileImage(doctor.doctorid)"
                              class="img-fluid"
                              alt="Doctor Image"
                            />
                          </router-link>
                          <div v-if="isPatient" class="favourite-btn">
                            <a
                              href="javascript:void(0)"
                              class="favourite-icon"
                              :class="{ favourite: isFavorite(doctor) }"
                              @click="toggleFavourite(doctor)"
                            >
                              <i class="fas fa-heart"></i>
                            </a>
                          </div>
                        </div>
                        <div class="doc-info-cont">
                          <router-link
                            :to="{ name: 'doctor-profile', params: { id: doctor.doctorid } }"
                          >
                            <h4 class="doc-name">
                              Dr. {{ doctor.doctorname }}
                              <i class="fas fa-circle-check"></i>
                            </h4>
                          </router-link>
                          <p
                            class="doc-speciality"
                          >{{ doctor.qualifications }}, {{ doctor.specialization }}</p>
                          <div class="clinic-details">
                            <p class="doc-location">
                              <i class="fas fa-house-chimney-medical"></i>
                              <span>{{ doctor.clincorhospname }}</span>
                            </p>
                            <p class="doc-location">
                              <i class="feather-map-pin"></i>
                              <span class="location-area">{{ doctor.area }}&nbsp;</span>
                              <!-- <span>{{ item.Km }}</span
                              >{{ item.Location }}
                              <a href="javascript:void(0);">Get Direction</a>-->
                              <!-- <img src="@/assets/img/gmaps.png" alt="Google Maps Icon" style="width: 25px; height: 30px;" /> -->
                              <i class="feather-map"></i>
                              <a
                                target="_blank"
                                :href="'https://www.google.com/maps/search/?api=1&ll=12.9141%2C74.8560&query_place_id=&z=13&query='+doctor.clincorhospname"
                              >
                                <i v-if="isLargeScreen" class="feather-map"></i>Directions
                              </a>
                              <!-- <span>{{ item.Km }}</span
                              >{{ item.Location }}
                              <a href="javascript:void(0);">Get Direction</a>-->
                            </p>
                            <p class="doc-location">
                              <i class="feather-award"></i>
                              <span>{{ doctor.experience }}</span> Years of Experience
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="doc-info-right">
                        <div class="clini-infos">
                          <ul>
                            <li>
                              <!-- <i class="feather-clock available-icon"></i> -->
                              <span
                                :class="getAvailabilityClass(doctor)"
                              >{{ getAvailabilityMessage(doctor) }}</span>
                            </li>
                            <li>
                              <span
                                class="AvailabilityRow-slots"
                                v-for="(time, index) in getFirstTwoSlots(doctor)"
                                :key="index"
                              >
                                <a
                                  v-if="isSlotAvailable({ day: doctor.slots[0].day, time })"
                                  class="AvailabilityRow-action AvailabilityRow-pill"
                                  @click="bookAppointment(doctor.doctorid, { clinicid: doctor.slots[0].clinicid, clinic: doctor.slots[0].clinic, day: doctor.slots[0].day, time })"
                                >{{ formatTime(time) }}</a>
                                <span v-else class="text-muted">{{ formatTime(time) }}</span>
                                &nbsp;
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div
                          class="clinic-booking book-appoint"
                          v-if="isAvailableToBook(doctor.slots)"
                        >
                          <router-link
                            @click="bookAppointment(doctor.doctorid, null)"
                            to
                            class="btn btn-primary"
                          >{{ $t('book_appointment') }}</router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="blog-pagination rev-page">
                      <nav>
                        <ul class="pagination justify-content-center">
                          <li class="page-item" :class="{ disabled: currentPage === 1 }">
                            <a
                              class="page-link"
                              href="javascript:;"
                              @click="changePage(currentPage - 1)"
                            >
                              <i class="feather-chevrons-left me-1"></i>
                              {{ $t('prev') }}
                            </a>
                          </li>
                          <li class="page-item" v-for="page in visiblePages" :key="page">
                            <a
                              class="page-link"
                              href="javascript:;"
                              @click="changePage(page)"
                              :class="{ active: currentPage === page }"
                            >{{ page }}</a>
                          </li>
                          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                            <a
                              class="page-link page-next"
                              href="javascript:;"
                              @click="changePage(currentPage + 1)"
                            >
                              {{ $t('next') }}
                              <i class="feather-chevrons-right ms-1"></i>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 theiaStickySidebar">
                <search2filter
                  v-model:selectedGender="selectedGender"
                  v-model:selectedSpeciality="selectedSpeciality"
                  v-model:selectedLanguage="selectedLanguage"
                  v-model:selectedAvailability="selectedAvailability"
                  @apply-filters="applyFilters"
                  @reset-filters="handleResetFilters"
                ></search2filter>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-12 theiaStickySidebar">
            <search2map></search2map>
          </div>
        </div>
      </div>
    </div>

    <indexfooter></indexfooter>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import defaultImageUrl from "@/assets/img/doctors/doctor-thumb-01.jpg";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const currentDate = new Date();
const startday =
  currentDate.getDate() +
  " " +
  currentDate.toLocaleString("default", { month: "short" });
currentDate.setDate(currentDate.getDate() + 30);
const endday =
  currentDate.getDate() +
  " " +
  currentDate.toLocaleString("default", { month: "short" });

export default {
  data() {
    return {
      title: this.$t("search_doctors_title"),
      text: this.$t("home"),
      text1: this.$t("search_doctors"),
      doctors: null,
      filteredDoctors: [],
      selectedGender: [],
      selectedSpeciality: [],
      selectedLanguage: [],
      selectedAvailability: [],
      numberOfDoctorsFound: 0,
      selectedSort: "A to Z",
      Sort: ["A to Z", "Z to A"],
      currentPage: 1,
      doctorsPerPage: 10,
      isFavourite: false,
      start_day: startday,
      end_day: endday,
      availabilityCheck: false,
      noDataFound: false,
      locationSearched: "",
      favoriteDoctors: [],
      patientid: null,
      doctorImage: [],
      fetchedImages: {},
      defaultImageUrl: defaultImageUrl,
      serverDateTime: null,
      currentMoment: null,
      showError: false,
      errorMessage: ""
    };
  },
  watch: {
    currentPage(newPage, oldPage) {
      this.paginatedDoctors.forEach(doctor => {
        this.fetchDoctorProfileImage(doctor.doctorid, doctor.doctorimage);
      });
    }
  },
  computed: {
    isPatient() {
      return sessionStorage.getItem("usertype") === "Patient";
    },
    paginatedDoctors() {
      const startIdx = (this.currentPage - 1) * this.doctorsPerPage;
      const endIdx = startIdx + this.doctorsPerPage;
      return this.filteredDoctors.slice(startIdx, endIdx);
    },
    totalPages() {
      return Math.ceil(this.filteredDoctors.length / this.doctorsPerPage);
    },
    visiblePages() {
      const total = this.totalPages;
      const current = this.currentPage;
      const maxVisible = 5;
      const start = Math.max(1, current - Math.floor(maxVisible / 2));
      const end = Math.min(total, start + maxVisible - 1);

      const adjustedStart = Math.max(1, end - maxVisible + 1);

      return Array.from(
        { length: end - adjustedStart + 1 },
        (_, i) => adjustedStart + i
      );
    },
    getDoctorProfileImage() {
      return doctorId => {
        return this.doctorImage[doctorId] || this.defaultImageUrl;
      };
    }
  },
  methods: {
    async fetchServerDateTime() {
      try {
        const response = await axios.get("/doctors/datetime");
        const serverData = response.data;
        this.serverDateTime = {
          fullDate: serverData[1], // "YYYY-MM-DD"
          fullTime: serverData[3] // "HH:mm:ss" 24-hour format
        };
        this.currentMoment = moment(
          `${this.serverDateTime.fullDate} ${this.serverDateTime.fullTime}`
        );
      } catch (error) {
        console.error("Error fetching server date and time:", error);
        return null;
      }
    },
    checkScreenSize() {
      this.isLargeScreen = window.innerWidth > 768; // Adjust the width as needed
    },
    formatTime(time) {
      if (!time) return "";
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },
    bookAppointment(doctorid, slot) {
      const userType = sessionStorage.getItem("usertype");
      if (userType === "Doctor") {
        this.showError = true;
        this.errorMessage =
          "It looks like you're logged in as a doctor. Switch to a patient account to book an appointment or";
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      if (slot) {
        this.$store.dispatch("updateAppointmentDraft", {
          doctorid: doctorid,
          clinicname: slot.clinic,
          clinic: slot.clinicid,
          day: slot.day,
          time: slot.time,
          doctorImage: this.doctorImage[doctorid]
        });
        if (userType === "Patient") {
          this.$router.push("/patient-details/" + doctorid);
        } else {
          this.$router.push("/phone-number/" + doctorid);
        }
      } else {
        this.$store.dispatch("updateAppointmentDraft", {
          doctorid: doctorid,
          doctorImage: this.doctorImage[doctorid]
        });
        this.$router.push("/booking/" + doctorid);
      }
    },
    getBookClass(slots) {
      return slots && slots.length > 0
        ? "btn btn-primary"
        : "nav-link disabled";
    },
    toggleSortDirection() {
      this.selectedSort = this.selectedSort === "A to Z" ? "Z to A" : "A to Z";
      this.sortDoctors();
    },
    getDoctorImage(doctor) {
      return (
        doctor.doctorimage || require("@/assets/img/doctors/doctor-14.jpg")
      );
    },
    redirectReloadSearch() {
      this.$router.push({ path: "/doctor-search-grid" }).then(() => {
        this.$router.go();
      });
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },

    sortDoctors() {
      this.filteredDoctors.sort((a, b) => {
        const nameA = a.doctorname.toUpperCase();
        const nameB = b.doctorname.toUpperCase();

        if (this.selectedSort === "A to Z") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    },

    applyLocalFilters() {
      this.applyFilters({
        selectedGender: this.selectedGender,
        selectedSpeciality: this.selectedSpeciality,
        selectedLanguage: this.selectedLanguage,
        selectedAvailability: this.selectedAvailability
      });
    },
    applyFilters({
      selectedGender,
      selectedSpeciality,
      selectedLanguage,
      selectedAvailability
    }) {
      this.selectedGender = selectedGender;
      this.selectedSpeciality = selectedSpeciality;
      this.selectedLanguage = selectedLanguage;
      this.selectedAvailability = Array.from(selectedAvailability);

      this.currentPage = 1;

      if (
        selectedGender.length ||
        selectedSpeciality.length ||
        selectedLanguage.length ||
        selectedAvailability.length ||
        this.availabilityCheck
      ) {
        this.filteredDoctors = this.filterDoctors();
      } else {
        this.filteredDoctors = this.doctors;
      }
      this.paginatedDoctors.forEach(doctor => {
        this.fetchDoctorProfileImage(doctor.doctorid, doctor.doctorimage);
      });
    },
    filterDoctors() {
      return this.doctors.filter(doctor => {
        const genderMatch =
          !this.selectedGender.length ||
          this.selectedGender.includes(doctor.gender);
        const specialityMatch =
          !this.selectedSpeciality.length ||
          this.selectedSpeciality.includes(doctor.specialization);
        const languageMatch =
          !this.selectedLanguage.length ||
          this.selectedLanguage.some(lang =>
            doctor.languagesspoken.includes(lang)
          );
        const availabilityMatch = this.checkAvailability(
          doctor,
          this.selectedAvailability
        );

        return (
          genderMatch && specialityMatch && languageMatch && availabilityMatch
        );
      });
    },
    checkAvailability(doctor, selectedAvailability) {
      const currentDate = this.serverDateTime.fullDate;
      const currentDateTime = this.serverDateTime.fullTime;

      const doctorSlots = doctor.slots;

      //console.log("Doctor Slots:", doctorSlots);

      switch (selectedAvailability[0]) {
        case "today":
          const today = currentDate;
          const todayAvailability = doctorSlots.some(
            slot => slot.day === today
          );

          return todayAvailability;

        case "In7days":
          const sevenDaysLater = new Date(currentDate);
          sevenDaysLater.setDate(sevenDaysLater.getDate() + 6);
          const sevenDaysAvailability = doctorSlots.some(
            slot => new Date(slot.day) <= sevenDaysLater
          );

          return sevenDaysAvailability;

        case "In14days":
          let fourteenDaysLater;
          if (this.currentMoment) {
            fourteenDaysLater = moment(currentDate)
              .add(13, "days")
              .format("YYYY-MM-DD");
          }
          const fourteenDaysAvailability = doctorSlots.some(
            slot => slot.day <= fourteenDaysLater
          );

          return fourteenDaysAvailability;

        default:
          if (this.availabilityCheck) {
            let thirtyDaysLater;
            if (this.currentDate) {
              thirtyDaysLater = moment(currentDate)
                .add(29, "days")
                .format("YYYY-MM-DD");
            }
            const thirtyDaysAvailability = doctorSlots.some(
              slot => slot.day <= thirtyDaysLater
            );

            return thirtyDaysAvailability;
          } else {
            return true;
          }
      }
    },
    resetAvailabilityFilter() {
      this.selectedAvailability = [];
      this.fetchData();
    },
    resetGenderFilter() {
      this.selectedGender = [];
      this.fetchData();
    },
    resetLanguagesFilter() {
      this.selectedLanguage = [];
      this.fetchData();
    },

    handleResetFilters() {
      this.selectedGender = [];
      this.selectedSpeciality = [];
      this.selectedLanguage = [];
      this.fetchData();
    },

    async fetchData() {
      const PatientId = sessionStorage.getItem("userdbid");
      this.patientid = PatientId;
      let { filter, city, speciality } = this.$route.query;
      this.locationSearched = city ? city : this.$t("your_area");

      filter = filter === undefined ? "" : filter;
      city = city === undefined ? "" : city;
      speciality = speciality === undefined ? "" : speciality;

      const apiUrl = `doctors/?searchtext=${filter}&area=${city}&specialization=${speciality}`;

      try {
        const response = await axios.get(apiUrl);
        this.doctors = response.data;

        this.filteredDoctors = this.doctors;
        this.numberOfDoctorsFound = response.data.length;
        this.noDataFound = false;
        this.sortDoctors();
        this.paginatedDoctors.forEach(doctor => {
          this.fetchDoctorProfileImage(doctor.doctorid, doctor.doctorimage);
        });
        this.currentPage = 1;
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.response && error.response.status === 404) {
          this.doctors = null;
          this.filteredDoctors = [];
          this.numberOfDoctorsFound = 0;
          this.noDataFound = true;
        }
      }
      if (sessionStorage.getItem("usertype") === "Patient") {
        try {
          const response = await axios.get(
            `/patients/${this.patientid}/favourites`
          );
          //console.log("Favourites API response:", response.data);
          if (response.data && Array.isArray(response.data.doctors)) {
            this.favoriteDoctors = response.data.doctors.map(
              doctor => doctor.doctorid
            );
          } else {
            this.favoriteDoctors = [];
          }
        } catch (error) {
          console.error("Error fetching favorite doctors:", error);
          this.favoriteDoctors = [];
        }
      }
    },
    async toggleFavourite(doctor) {
      const isCurrentlyFavourite = this.isFavorite(doctor);

      try {
        await axios.post(
          `/patients/${this.patientid}/favourites/${doctor.doctorid}`
        );
        this.updateFavoriteDoctorsList(doctor.doctorid, !isCurrentlyFavourite);
      } catch (error) {
        console.error("Error toggling favorite status:", error);
      }
    },

    isFavorite(doctor) {
      return this.favoriteDoctors.includes(doctor.doctorid);
    },

    updateFavoriteDoctorsList(doctorId, isFavorite) {
      if (isFavorite) {
        this.favoriteDoctors.push(doctorId);
      } else {
        const index = this.favoriteDoctors.indexOf(doctorId);
        if (index !== -1) {
          this.favoriteDoctors.splice(index, 1);
        }
      }
    },
    getFirstTwoSlots(doctor) {
      const currentDateTime = this.currentMoment;

      if (doctor.slots && doctor.slots.length > 0) {
        const todaySlots = doctor.slots[0].times.filter(time =>
          this.isSlotAvailable({ day: doctor.slots[0].day, time })
        );

        const futureSlots = todaySlots.filter(time =>
          moment(`${doctor.slots[0].day} ${time}`, "YYYY-MM-DD HH:mm").isAfter(
            currentDateTime
          )
        );

        return futureSlots.slice(0, 2);
      }

      return [];
    },
    isAvailableToBook(slots) {
      return slots && slots.length > 0 ? true : false;
    },
    formatShortDate(date) {
      if (!date) return "";
      return new Date(date).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric"
      });
    },
    getAvailabilityMessage(doctor) {
      let today, tomorrow;
      if (this.currentMoment) {
        today = this.currentMoment.format("YYYY-MM-DD");
        tomorrow = this.currentMoment
          .clone()
          .add(1, "day")
          .format("YYYY-MM-DD");
      }
      let todaySlots = doctor.slots.find(slot => slot.day === today);
      const tomorrowSlots = doctor.slots.find(slot => slot.day === tomorrow);

      if (todaySlots) {
        return "Available Today";
      } else if (tomorrowSlots) {
        return "Available Tomorrow";
      } else if (!doctor.slots || doctor.slots.length < 1) {
        return "Not Available";
      } else {
        const nextAvail = doctor.slots[0].day;
        let availDate = this.formatShortDate(nextAvail);

        return `Available from ${availDate}`;
      }
    },
    getAvailabilityClass(doctor) {
      const availability = this.getAvailabilityMessage(doctor);

      if (availability === "Available Today") {
        return "available-date available-today";
      } else if (availability === "Available Tomorrow") {
        return "available-date available-today";
      } else if (availability === "Not Available") {
        return "text-danger bg-danger-light rounded px-4 py-0";
      } else {
        return "available-date available-today";
      }
    },

    isSlotAvailable(slot) {
      const currentDateTime = this.currentMoment;
      const slotDateTime = moment(
        `${slot.day} ${slot.time}`,
        "YYYY-MM-DD HH:mm"
      );
      if (currentDateTime) {
        return currentDateTime.isBefore(slotDateTime);
      }
    },
    async fetchDoctorProfileImage(doctorId, doctorImage) {
      if (this.fetchedImages[doctorId]) {
        this.doctorImage[doctorId] = this.fetchedImages[doctorId];
        return;
      }

      if (doctorImage == null) {
        this.doctorImage[doctorId] = this.defaultImageUrl;
        this.fetchedImages[doctorId] = this.defaultImageUrl;
      } else {
        try {
          const response = await axios.get(
            `doctors/doctor-images?profilePath=${doctorImage}`,
            {
              responseType: "arraybuffer"
            }
          );
          const objectURL = window.URL.createObjectURL(
            new Blob([response.data])
          );
          this.doctorImage[doctorId] = objectURL;
          this.fetchedImages[doctorId] = objectURL;
        } catch (error) {
          console.error("Error fetching Doctor profile image:", error);
        }
      }
    }
  },

  mounted() {
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeMount() {
    this.fetchServerDateTime();
    this.fetchData();
  },
  created() {
    this.$watch(
      () => this.$route.query,
      (toParams, previousParams) => {
        this.fetchData();
      }
    );
  }
};
</script>
<style scoped>
.doctors-found {
  padding: 10px 0;
}

.doctors-found p {
  margin: 0 10px;
}

.filter-today {
  display: flex;
  align-items: center;
}

.filter-today i {
  margin-right: 5px;
}
.dropdown .btn,
.custom-dropdown .btn {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #3b3b3b;
  padding: 15;
  font-size: 1em;
}
.dropdown .btn:hover,
.dropdown .btn:focus,
.custom-dropdown .btn:hover,
.custom-dropdown .btn:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  color: #007bff;
}
.dropdown .dropdown-item {
  padding: 0.5em 1em;
}
.custom-dropdown .dropdown-item {
  padding: 0.5em 1em;
}
.custom-dropdown .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
.filter-section h4 i {
  transition: transform 0.3s ease;
}

.filter-section h4 i.fa-chevron-up {
  transform: rotate(180deg);
}
#loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.preloader-gif {
  width: 150px;
  height: 120px;
}
.bg-blur {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
}

.pagination .page-item.disabled .page-link {
  background-color: rgba(128, 128, 128, 0.529) !important;
  color: black !important;
  font: bold;
}

@media (max-width: 767px) {
  .pagination .page-link {
    font-size: 0.5rem;
    padding: 0.5rem 0.75rem;
  }

  .pagination .page-item i {
    font-size: 0.75rem;
  }
}

@media (max-width: 767px) {
  .clinic-booking.book-appoint {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.feather-map {
  margin-left: 20px;
}

@media (max-width: 768px) {
  .feather-map {
    margin-bottom: 0;
  }
}

.location-area {
  margin-bottom: 0;
}

/* Apply margin-bottom only for small screens */
/* @media (max-width: 768px) {
  .location-area {
    margin-bottom: 20px;
  }
} */

@media (max-width: 767.98px) {
  .doctor-widget-one .doc-location {
    flex-direction: row;
    padding-left: 76px;
  }
  .doctor-widget-one .clinic-details {
    padding-bottom: 1px;
  }
  .clinic-details {
    margin-bottom: 1px;
  }
  .doctor-widget-one .doc-location span {
    color: #1f2937;
    margin-left: 10px;
  }
}
</style>
