<template>
  <ul class="main-nav">
    
    
    <li class="login-link" v-if="isHomeOneRoute && !user">
      <router-link to="/login" @click.native="closeMenu">Login / Signup</router-link>
    </li>
    <li class="login-link" v-if="user">
      <router-link to="/patient-dashboard"  @click.native="closeMenu">Dashboard</router-link>
    </li>
    <li class="login-link" v-if="user">
      <router-link to="" @click="signOutUser">Logout</router-link>
    </li>
    <li class="login-link" v-if="isDoctorLogin && !user">
      <router-link to="/doctor-login" @click.native="closeMenu">Doctor Login</router-link>
    </li>
    <li class="login-link" v-if="isDoctorLoginPage && !user">
      <router-link to="/login" @click.native="closeMenu">Patient Login</router-link>
    </li>
    <li class="register-btn" v-if="isHomeOneRoute && !user">
      <router-link to="/register" class="btn reg-btn"
        ><i class="feather-user"></i>Register</router-link
      >
    </li>
    <li class="register-btn" v-if="isHomeOne1Route && !user">
      <router-link to="/login" class="btn btn-primary log-btn"
        ><i class="feather-lock"></i>Login</router-link
      >
    </li>
    <li class="register-btn" v-if="isDoctorLoginPage && !user">
      <router-link to="/login" class="btn btn-primary log-btn"
        ><i class="feather-lock"></i>Patient Login</router-link
      >
    </li>
    <li class="register-btn" v-if="isDoctorLogin && !user">
      <router-link to="/doctor-login" class="btn doc-log-btn"
        ><i class="feather-lock"></i>Doctor Login</router-link
      >
    </li>
  </ul>
</template>
<script>
import { signOut } from 'aws-amplify/auth';

export default {
  props: {
    user: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isVisible: false,
      isVisiblehomes: false,
      isVisibledoctor: false,
      isVisibledoctorsblog: false,
      isVisiblepatients: false,
      isVisiblepatientsdoctor: false,
      isVisiblepatientssearch: false,
      isVisiblepatientsbooking: false,
      isVisiblepharmacy: false,
      isVisiblepages: false,
      isVisiblecall: false,
      isVisibleinvoice: false,
      isVisibleauthentication: false,
      isVisibleerror: false,
      isVisibleblog: false,
      isVisibleadmin: false,
    };
  },
  computed: {
    isHomeOneRoute() {
      return (
        this.$route.path === "/" ||
        this.$route.path === "/about-us" ||
        this.$route.path === "/blank-page" ||
        this.$route.path === "/blog-details" ||
        this.$route.path === "/blog-grid" ||
        this.$route.path === "/blog-list" ||
        this.$route.path === "/coming-soon" ||
        this.$route.path === "/components" ||
        this.$route.path === "/contact-us" ||
        this.$route.path === "/doctor-register" ||
        this.$route.path === "/doctor-search-grid" ||
        this.$route.path === "/doctor-signup" ||
        this.$route.path === "/error-404" ||
        this.$route.path === "/error-500" ||
        this.$route.path === "/faq" ||
        this.$route.path === "/forgot-password" ||
        this.$route.path === "/forgot-password2" ||
        this.$route.path === "/login-email-otp" ||
        this.$route.path === "/login-email" ||
        this.$route.path === "/login-phone-otp" ||
        this.$route.path === "/login" ||
        this.$route.path === "/maintenance" ||
        this.$route.path === "/map-grid" ||
        this.$route.path === "/map-list" ||
        this.$route.path === "/mobile-otp" ||
        this.$route.path === "/patient-signup" ||
        this.$route.path === "/pharmacy-register" ||
        this.$route.path === "/pricing" ||
        this.$route.path === "/privacy-policy" ||
        this.$route.path === "/register" ||
        this.$route.path === "/search-2" ||
        this.$route.path === "/search" ||
        this.$route.path === "/signup-success" ||
        this.$route.path === "/terms-condition" ||
        this.$route.path === "/email-otp" ||
        this.$route.path === "/login-phone"
      );
    },
    isHomeOne1Route() {
      return (
        this.$route.path === "/" ||
        this.$route.path === "/about-us" ||
        this.$route.path === "/blank-page" ||
        this.$route.path === "/blog-details" ||
        this.$route.path === "/blog-grid" ||
        this.$route.path === "/blog-list" ||
        this.$route.path === "/coming-soon" ||
        this.$route.path === "/components" ||
        this.$route.path === "/contact-us" ||
        this.$route.path === "/doctor-register" ||
        this.$route.path === "/doctor-search-grid" ||
        this.$route.path === "/doctor-signup" ||
        this.$route.path === "/error-404" ||
        this.$route.path === "/error-500" ||
        this.$route.path === "/faq" ||
        this.$route.path === "/forgot-password" ||
        this.$route.path === "/forgot-password2" ||
        this.$route.path === "/login-email-otp" ||
        this.$route.path === "/login-email" ||
        this.$route.path === "/login-phone-otp" ||
        // this.$route.path === "/login" ||
        this.$route.path === "/maintenance" ||
        this.$route.path === "/map-grid" ||
        this.$route.path === "/map-list" ||
        this.$route.path === "/mobile-otp" ||
        this.$route.path === "/patient-signup" ||
        this.$route.path === "/pharmacy-register" ||
        this.$route.path === "/pricing" ||
        this.$route.path === "/privacy-policy" ||
        // this.$route.path === "/register" ||
        this.$route.path === "/search-2" ||
        this.$route.path === "/search" ||
        this.$route.path === "/signup-success" ||
        this.$route.path === "/terms-condition" ||
        this.$route.path === "/email-otp" ||
        this.$route.path === "/login-phone"
      );
    },
    currentPath() {
      return this.$route.name;
    },
    homeMenu() {
      return (
        this.$route.name == "index"
      );
    },
    isDoctorLogin(){
      return(
        this.$route.path === "/" ||
        this.$route.path === "/login"||
        this.$route.path === "/register"
        // this.$route.path === "/forgot-password" && this.$route.query.type === "doctor"
      );
    },
    isDoctorLoginPage(){
      return(
        this.$route.path === "/doctor-login"
      );
    },
    //  isPatientLoginPage(){
    //   return(
         
    //   );
    // }

   
  },
  methods: {
    signOutUser() {
      signOut()
      .then(data => {
        this.$router.push("/");
        this.closeMenu();
      }).catch(err => console.log(err))
    },
    submitForm() {
      this.$router.push("/search");
    },
    toggleElement() {
      this.isVisible = !this.isVisible;
    },
    toggleVisibilityhomes() {
      this.isVisiblehomes = !this.isVisiblehomes;
    },
    toggleVisibilitydoctors() {
      this.isVisibledoctor = !this.isVisibledoctor;
    },
    toggleVisibilitydoctorsblog() {
      this.isVisibledoctorsblog = !this.isVisibledoctorsblog;
    },
    toggleVisibilitypatients() {
      this.isVisiblepatients = !this.isVisiblepatients;
    },
    toggleVisibilitypatientsdoctors() {
      this.isVisiblepatientsdoctor = !this.isVisiblepatientsdoctor;
    },
    toggleVisibilitysearch() {
      this.isVisiblepatientssearch = !this.isVisiblepatientssearch;
    },
    toggleVisibilitybooking() {
      this.isVisiblepatientsbooking = !this.isVisiblepatientsbooking;
    },
    toggleVisibilitypharmacy() {
      this.isVisiblepharmacy = !this.isVisiblepharmacy;
    },
    toggleVisibilitypages() {
      this.isVisiblepages = !this.isVisiblepages;
    },
    toggleVisibilitycall() {
      this.isVisiblecall = !this.isVisiblecall;
    },
    toggleVisibilityinvoice() {
      this.isVisibleinvoice = !this.isVisibleinvoice;
    },
    toggleVisibilityauthentication() {
      this.isVisibleauthentication = !this.isVisibleauthentication;
    },
    toggleVisibilityerror() {
      this.isVisibleerror = !this.isVisibleerror;
    },
    toggleVisibilityblog() {
      this.isVisibleblog = !this.isVisibleblog;
    },
    toggleVisibilityadmin() {
      this.isVisibleadmin = !this.isVisibleadmin;
    },
    redirectReloadmapgrid() {
      this.$router.push({ path: "/map-grid" }).then(() => {
        this.$router.go();
      });
    },
    redirectReloadmaplist() {
      this.$router.push({ path: "/map-list" }).then(() => {
        this.$router.go();
      });
    },
    redirectReloadsearch() {
      this.$router.push({ path: "/search-2" }).then(() => {
        this.$router.go();
      });
    },
    redirectReloadsearch() {
      this.$router.push({ path: "/search-2" }).then(() => {
        this.$router.go();
      });
    },
    redirectReloadIndex11() {
      this.$router.push({ path: "index-11" }).then(() => {
        this.$router.go();
      });
    },
    closeMenu() {
      const menu = document.getElementById('menu_close');
      if (menu) {
        menu.click()
      }
    },
  },
};
</script>
<style scoped>
.doc-log-btn {
  min-width: 87px;
  color: #ffffff;
  background-color: #09e5ab;
  border: 1px solid #09e5ab !important;
  box-shadow: inset 0 0 0 0 #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}
.doc-log-btn:hover {
  color: #09e5ab !important;
  background-color: #ffffff;
  border: 1px solid #09e5ab;
  box-shadow: inset 0 0 0 50px #ffffff;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}

</style>