<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <clinicheader v-if="!isModalOpen &&  !ischeckinModalOpen"></clinicheader>
    <breadcrumb-clinic :title="title" :text="text" :text1="text1" />
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <router-link to="/clinic-dashboard">
            <i class="fa-solid fa-arrow-left-long"></i> Back
          </router-link>

          <doctorsidebar2></doctorsidebar2>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="row">
              <div class="col-md-12">
                <div class="card dash-card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 col-lg-4">
                        <div class="dash-widget dct-border-rht">
                          <div class="circle-bar circle-bar2">
                            <div class="circle-graph2">
                              <circle-progress :percent="65" />
                              <img src="@/assets/img/icon-02.png" class="img-fluid" alt="Patient" />
                            </div>
                          </div>
                          <div class="dash-widget-info">
                            <h6>Appointments Today</h6>
                            <h3>{{ statDetails.todayAppointments }}</h3>
                            <p class="text-muted">{{ formattedDate }}</p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-4">
                        <div class="dash-widget">
                          <div class="circle-bar circle-bar3">
                            <div class="circle-graph3">
                              <circle-progress :percent="50" />
                              <img src="@/assets/img/icon-03.png" class="img-fluid" alt="Patient" />
                            </div>
                          </div>
                          <div class="dash-widget-info">
                            <h6>Upcoming Appointments</h6>
                            <h3>{{ statDetails.upcomingAppointment }}</h3>
                            <p class="text-muted">{{ formattedDate }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-4">
                        <div class="dash-widget dct-border-rht">
                          <div class="circle-bar circle-bar1">
                            <div class="circle-graph1">
                              <circle-progress :percent="75" />
                              <img src="@/assets/img/icon-01.png" class="img-fluid" alt="patient" />
                            </div>
                          </div>
                          <div class="dash-widget-info">
                            <h6>Total Patients</h6>
                            <h3>{{ statDetails.totalPatients }}</h3>
                            <p class="text-muted">Till Today</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row"  style="margin-top: 5px; margin-bottom: 5px;">
              <div class="col-md-12">
                <div class="d-flex flex-column flex-md-row justify-content-between mb-3">
                  <div class="dropdown mb-2 mb-md-0">
                    <p
                      class="download-text dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fas fa-download"></i> Download
                    </p>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          @click="exportAppointments('pdf')"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="PDF"
                        >
                          <img src="@/assets/img/pdf.svg" alt="Export to PDF" class="export-icon" />
                          as PDF
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          @click="exportAppointments('excel')"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Excel"
                        >
                          <img
                            src="@/assets/img/excel.svg"
                            alt="Export to Excel"
                            class="export-icon"
                          />
                          as Excel
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3 class="mb-4 mb-md-0">Patient Appointments</h3>
                  </div>
                  <div class="d-flex justify-content-end">
                    <router-link
                    to="/add-clinic-appointment"
                    class="main-btn add-appointment-btn"
                    style="margin-left: 5px;"
                  >Add Appointment</router-link>
                </div>
                </div>

                <div class="appointment-tab">
                  <div class="d-flex justify-content-center">
                  <div class="appointment-tab tab-selector">
                    <!-- Today Tab -->
                    <button
                      :class="{ active: selectedTab === 'today' }"
                      @click="selectTodayAppointments"
                    >
                      Today
                      <span class="badge custom-badge ms-1">{{ todayCount }}</span>
                    </button>

                    <!-- Specific Day Tabs (Next 2 Days) -->
                    <button
                      v-for="(day, index) in nextTwoDays"
                      :key="index"
                      :class="{ active: selectedTab === 'specific' && selectedSpecificDay === day }"
                      @click="selectSpecificDay(day)"
                    >
                      {{ formatShortDate(day) }}
                      <span
                        class="badge custom-badge ms-1"
                      >{{ nextTwoDaysCount[index] }}</span>
                    </button>

                    <button
                      :class="{ active: selectedTab === 'nextSevenDays' }"
                      @click="selectNextSevenDaysAppointments"
                    >
                      Next 7 Days
                      <span class="badge custom-badge ms-1">{{ nextSevenDaysCount }}</span>
                    </button>

                    <!-- Upcoming Tab -->
                    <button
                      :class="{ active: selectedTab === 'upcoming' }"
                      @click="selectUpcomingAppointments"
                    >
                      All Upcoming
                      <span class="badge custom-badge ms-1">{{ upcomingCount }}</span>
                    </button>
                  </div>
                  </div>
                  <!-- /Appointment Tab -->
                  <b-alert v-model="showAlertx" variant="warning" class="mt-2" dismissible @dismissed="handleDismiss">Refresh to load the latest appointments.
        <span class="text-primary font-weight-bold" role="button" @click="refreshAppointments" style="cursor: pointer;">
          Refresh
        </span></b-alert>
                  <div class="align-container" v-if="selectedTab === 'today'">
                    <div class="left-align">
                      <i class="fa fa-long-arrow-left" aria-hidden="true" @click="scrollTableLeftx"></i>
                    </div>
                    <div class="right-align">
                      <i
                        class="fa fa-long-arrow-right"
                        aria-hidden="true"
                        @click="scrollTableRightx"
                      ></i>
                    </div>
                  </div>
                  <div class="align-container" v-if="selectedTab === 'specific'">
                    <div class="left-align">
                      <i class="fa fa-long-arrow-left" aria-hidden="true" @click="scrollTableLefty"></i>
                    </div>
                    <div class="right-align">
                      <i
                        class="fa fa-long-arrow-right"
                        aria-hidden="true"
                        @click="scrollTableRighty"
                      ></i>
                    </div>
                  </div>
                  <div class="align-container" v-if="selectedTab === 'upcoming'">
                    <div class="left-align">
                      <i class="fa fa-long-arrow-left" aria-hidden="true" @click="scrollTableLeft"></i>
                    </div>
                    <div class="right-align">
                      <i
                        class="fa fa-long-arrow-right"
                        aria-hidden="true"
                        @click="scrollTableRight"
                      ></i>
                    </div>
                  </div>
                  <div class="align-container" v-if="selectedTab === 'nextSevenDays'">
                    <div class="left-align">
                      <i class="fa fa-long-arrow-left" aria-hidden="true" @click="scrollTableLeftz"></i>
                    </div>
                    <div class="right-align">
                      <i
                        class="fa fa-long-arrow-right"
                        aria-hidden="true"
                        @click="scrollTableRightz"
                      ></i>
                    </div>
                  </div>

                  <!-- Upcoming Appointment Tab -->
                  <div v-if="selectedTab === 'upcoming'" class="tab-content">
                    <div class="tab-pane show active" id="upcoming-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive" ref="tableContainer">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr></tr>
                                <tr>
                                  <th>Patient Name</th>
                                  <th>Appt Date</th>
                                  <th>Clinic Name</th>
                                  <!-- <th>Type</th> -->
                                  <!-- <th class="text-center">Paid Amount</th> -->
                                  <th style="text-align: right;">
                                    <label class="custom_check" style="text-align: right;">
                                      <input
                                        type="checkbox"
                                        id="active-appointments"
                                        v-model="isActive"
                                        @change="toggleActiveStatus"
                                        checked
                                      />
                                      <span class="checkmark"></span>
                                      Include Cancelled
                                    </label>
                                  </th>
                                  <th>Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="appointment in upcomingAppointmentsfilter"
                                  :key="appointment.id"
                                >
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link
                                        :to="'patient-profile/' + appointment.patientid"
                                        class="avatar avatar-sm me-2"
                                      >
                                        <img
                                          class="avatar-img rounded-circle"
                                          :src="getPatientImage(appointment)"
                                          alt="User Image"
                                        />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        {{ appointment.firstname }} {{ appointment.lastname }}
                                        <span
                                          v-if="appointment.patientage"
                                        >{{ appointment.patientid }} (Age: {{appointment.patientage}})</span>
                                        <span v-else>{{ appointment.patientid }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <td>
                                    {{ formatedDate(appointment.appointmentdate) }}
                                    <span
                                      class="d-block text-info"
                                    >
                                      {{
                                      formatTime(appointment.appointmenttime)
                                      }}
                                    </span>
                                  </td>
                                  <td>{{ appointment.clinicname }}</td>
                                  <!-- <td>{{ item.Type }}</td> -->
                                  <!-- <td class="text-center">{{ item.PaidAmount }}</td> -->
                                  <td class="text-end">
                                    <div class="table-action">
                                      <!-- <a
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-success-light me-2"
                                      >
                                        <i class="fas fa-check"></i> Accept
                                      </a>-->
                                      <a
                                        v-if="appointment.appointmentstatus === 'E' && !appointment.ispast"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-danger-light"
                                        @click="cancelAppointment(appointment)"
                                      >
                                        <i class="fas fa-times"></i> Cancel
                                      </a>
                                      <a
                                        v-else-if="appointment.appointmentstatus === 'E' && appointment.ispast"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-warning-light"
                                        @click="markasAbsent(appointment.patientid, appointment.appointmentid)"
                                      >
                                        <i class="fas fa-user-slash"></i> Mark as No-Show
                                      </a>
                                      <span v-else-if="appointment.prescripid > 0">
                                        <span
                                          class="badge bg-success-light text-white badge-lg"
                                        >Visited</span>
                                      </span>

                                      <span v-else-if="appointment.appointmentstatus === 'A'">
                                        <span
                                          class="badge bg-warning-light text-dark badge-lg"
                                        >No-Show</span>
                                      </span>
                                      <span v-else-if="appointment.appointmentstatus === 'C'">
                                        <span class="badge bg-danger text-white">Cancelled</span>
                                      </span>
                                    </div>
                                  </td>
                                  <td>{{ appointment.reason }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- /Upcoming Appointment Tab -->

                  <div v-if="selectedTab === 'nextSevenDays'" class="tab-content">
                    <div class="tab-pane show active" id="nextSevenDays">
                      <!-- nextSevenDays Appointment Tab -->
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive" ref="table-containerz">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr></tr>
                                <tr>
                                  <th>Patient Name</th>
                                  <th>Appt Date</th>
                                  <th>Clinic Name</th>
                                  <!-- <th>Type</th> -->
                                  <!-- <th class="text-center">Paid Amount</th> -->
                                  <th style="text-align: right;">
                                    <label class="custom_check" style="text-align: right;">
                                      <input
                                        type="checkbox"
                                        id="active-appointments"
                                        v-model="isActive"
                                        @change="toggleActiveStatus"
                                        checked
                                      />
                                      <span class="checkmark"></span>
                                      Include Cancelled
                                    </label>
                                  </th>
                                  <th>Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="appointment in nextSevenDaysAppointments"
                                  :key="appointment.id"
                                >
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link
                                        :to="'patient-profile/' + appointment.patientid"
                                        class="avatar avatar-sm me-2"
                                      >
                                        <img
                                          class="avatar-img rounded-circle"
                                          :src="getPatientImage(appointment)"
                                          alt="User Image"
                                        />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        {{ appointment.firstname }} {{ appointment.lastname }}
                                        <span
                                          v-if="appointment.patientage"
                                        >{{ appointment.patientid }} (Age: {{appointment.patientage}})</span>
                                        <span v-else>{{ appointment.patientid }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <td>
                                    {{ formatedDate(appointment.appointmentdate) }}
                                    <span
                                      class="d-block text-info"
                                    >
                                      {{
                                      formatTime(appointment.appointmenttime)
                                      }}
                                    </span>
                                  </td>
                                  <td>{{ appointment.clinicname }}</td>
                                  <td class="text-end">
                                    <div class="table-action">
                                      <a
                                        v-if="appointment.appointmentstatus === 'E' && !appointment.ispast"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-danger-light"
                                        @click="cancelAppointment(appointment)"
                                      >
                                        <i class="fas fa-times"></i> Cancel
                                      </a>
                                      <a
                                        v-else-if="appointment.appointmentstatus === 'E' && appointment.ispast"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-warning-light"
                                        @click="markasAbsent(appointment.patientid, appointment.appointmentid)"
                                      >
                                        <i class="fas fa-user-slash"></i> Mark as No-Show
                                      </a>
                                      <span v-else-if="appointment.prescripid > 0">
                                        <span
                                          class="badge bg-success-light text-white badge-lg"
                                        >Visited</span>
                                      </span>

                                      <span v-else-if="appointment.appointmentstatus === 'A'">
                                        <span
                                          class="badge bg-warning-light text-dark badge-lg"
                                        >No-Show</span>
                                      </span>
                                      <span v-else-if="appointment.appointmentstatus === 'C'">
                                        <span class="badge bg-danger text-white">Cancelled</span>
                                      </span>
                                    </div>
                                  </td>
                                  <td>{{ appointment.reason }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /nextSevenDays Appointment Tab -->
                  <!-- Today Appointment Tab -->
                  <div v-if="selectedTab === 'today'" class="mb-3">
                    <div class="form-check form-switch">
                      <label class="form-check-label" for="sort-switch">Sort by Token Number</label>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="sort-switch"
                        v-model="isSorted"
                        @change="toggleSorting"
                      />
                    </div>
                  </div>
                  <div v-if="selectedTab === 'today'" class="tab-content">
                    <div class="tab-pane show active" id="today-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive" ref="tableContainerx">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Patient Name</th>
                                  <th>Appt Date</th>
                                  <th>Clinic Name</th>
                                  <!-- <th>Type</th> -->
                                  <!-- <th class="text-center">Paid Amount</th> -->
                                  <th style="text-align: right;">
                                    <label class="custom_check" style="text-align: right;">
                                      <input
                                        type="checkbox"
                                        id="active-appointments"
                                        v-model="isActive"
                                        @change="toggleActiveStatus"
                                        checked
                                      />
                                      <span class="checkmark"></span>
                                      Include Cancelled
                                    </label>
                                  </th>
                                  <th>Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="appointment in sortedFilteredAppointments"
                                  :key="appointment.appointmentid"
                                >
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link
                                        :to="'patient-profile/' + appointment.patientid"
                                        class="avatar avatar-sm me-2"
                                      >
                                        <img
                                          class="avatar-img rounded-circle"
                                          :src="getPatientImage(appointment)
                                          "
                                          alt="User Image"
                                        />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        {{ appointment.firstname }} {{ appointment.lastname }}
                                        <span>{{ appointment.patientid }}</span>
                                      </router-link>

                                      <div
                                        v-if="appointment.tokenid > 0 && appointment.appointmentstatus !==  'C'"
                                        class="checked-in-box"
                                      >
                                        <div class="token-number">
                                          <span>
                                            Token No:
                                            <b>{{ appointment.tokenid }}</b>
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        v-else-if="appointment.tokenid === 0 && appointment.appointmentstatus !== 'C'"
                                        class="check-in-container"
                                      >
                                        <button
                                          class="main-btn"
                                          @click="opencheckinModal(appointment)"
                                        >Generate Token</button>
                                      </div>
                                    </h2>
                                  </td>
                                  <td>
                                    {{ formatedDate(appointment.appointmentdate)
                                    }}
                                    <span
                                      class="d-block text-info"
                                    >
                                      {{
                                      formatTime(appointment.appointmenttime)
                                      }}
                                    </span>
                                  </td>
                                  <td>{{ appointment.clinicname }}</td>
                                  <!-- <td>{{ item.Type }}</td>
                                  <td class="text-center">{{ item.PaidAmount }}</td>-->
                                  <td class="text-end">
                                    <div class="table-action">
                                      <!-- Add Prescription Button -->
                                      <button
                                        v-if="appointment.tokenid > 0 && appointment.prescripid > 0"
                                        class="main-btn"
                                        @click="viewPrescription(appointment.prescripid, appointment.patientid, appointment.appointmentid, appointment.firstname, appointment.lastname)"
                                      >View Prescription</button>

                                      <button
                                        v-if="appointment.tokenid > 0 && appointment.prescripid === 0  && (appointment.appointmentstatus === 'E' || appointment.appointmentstatus === 'V')"
                                        class="main-btn"
                                        @click="openPrescription(appointment.patientid, appointment.appointmentid, appointment.firstname, appointment.lastname)"
                                      >Add Prescription</button>
                                      <a
                                        v-if="appointment.tokenid > 0 && appointment.prescripid > 0 && isMobile"
                                        href="javascript:void(0);"
                                        @click="print_Download(appointment.prescripid, appointment.appointmentid, appointment.patientid)"
                                        class="btn btn-sm bg-primary-light me-2"
                                      >
                                        <i class="fas fa-download"></i> Download PDF
                                      </a>
                                      <a
                                        v-if="appointment.tokenid > 0 && appointment.prescripid > 0 && !isMobile"
                                        href="javascript:void(0);"
                                        @click="print_Download(appointment.prescripid, appointment.appointmentid, appointment.patientid)"
                                        class="btn btn-sm bg-primary-light me-2"
                                      >
                                        <i class="fas fa-print"></i> Print
                                      </a>
                                      <a
                                        v-if="(appointment.tokenid > 0 && (appointment.appointmentstatus === 'E' || appointment.appointmentstatus ==='V') ) && (appointment.tokenid > 0 &&  !(appointment.prescripid > 0)) "
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-warning-light"
                                        @click="markasAbsent(appointment.patientid, appointment.appointmentid)"
                                      >
                                        <i class="fas fa-user-slash"></i> Mark as No-Show
                                      </a>
                                      <!-- <a
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-success-light me-2"
                                      >
                                        <i class="fas fa-check"></i> Accept
                                      </a>-->
                                      <a
                                        v-if="appointment.tokenid === 0 && appointment.appointmentstatus === 'E' && !appointment.ispast"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-danger-light"
                                        @click="cancelAppointment(appointment)"
                                      >
                                        <i class="fas fa-times"></i> Cancel
                                      </a>
                                      <a
                                        v-else-if="appointment.tokenid === 0 && appointment.appointmentstatus === 'E' && appointment.ispast"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-warning-light"
                                        @click="markasAbsent(appointment.patientid, appointment.appointmentid)"
                                      >
                                        <i class="fas fa-user-slash"></i> Mark as No-Show
                                      </a>

                                      <span
                                        v-else-if="appointment.prescripid > 0"
                                        class="badge bg-success-light text-white badge-lg"
                                      >Visited</span>
                                      <span
                                        v-else-if="appointment.appointmentstatus === 'A'"
                                        class="badge bg-warning-light text-dark badge-lg"
                                      >No-Show</span>

                                      <span v-else-if="appointment.appointmentstatus === 'C'">
                                        <span class="badge bg-danger text-white">Cancelled</span>
                                      </span>
                                    </div>
                                  </td>
                                  <td>{{ appointment.reason }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Today Appointment Tab -->
                  <!-- Specific Day Appointment Tab -->
                  <div v-if="selectedTab === 'specific'" class="tab-content">
                    <div class="tab-pane show active" id="specific-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive" ref="tableContainery">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <!-- king -->
                                  <th>Patient Name</th>
                                  <th>Appt Date</th>
                                  <th>Clinic Name</th>
                                  <th style="text-align: right;">
                                    <label class="custom_check" style="text-align: right;">
                                      <input
                                        type="checkbox"
                                        id="active-appointments"
                                        v-model="isActive"
                                        @change="toggleActiveStatus"
                                        checked
                                      />
                                      <span class="checkmark"></span>
                                      Include Cancelled
                                    </label>
                                  </th>

                                  <th>Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="appointment in filteredAppointments"
                                  :key="appointment.id"
                                >
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link
                                        :to="'patient-profile/' + appointment.patientid"
                                        class="avatar avatar-sm me-2"
                                      >
                                        <img
                                          class="avatar-img rounded-circle"
                                          :src="getPatientImage(appointment)"
                                          alt="User Image"
                                        />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        {{ appointment.firstname }} {{ appointment.lastname }}
                                        <span
                                          v-if="appointment.patientage"
                                        >{{ appointment.patientid }} (Age: {{appointment.patientage}})</span>
                                        <span v-else>{{ appointment.patientid }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <td>
                                    {{ formatedDate(appointment.appointmentdate) }}
                                    <span
                                      class="d-block text-info"
                                    >{{ formatTime(appointment.appointmenttime) }}</span>
                                  </td>
                                  <td>{{ appointment.clinicname }}</td>
                                  <td>
                                    <div class="table-action">
                                      <a
                                        href="javascript:void(0)"
                                        class="btn btn-sm bg-info-light"
                                        data-bs-toggle="modal"
                                        @click="viewAppointmentDetails(appointment)"
                                        data-bs-target="#appt_details"
                                      >
                                        <i class="far fa-eye"></i> View
                                      </a>
                                      <span style="margin-left: 5px;"></span>
                                      <a
                                        v-if="appointment.appointmentstatus === 'E'"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-danger-light"
                                        @click="cancelAppointment(appointment)"
                                      >
                                        <i class="fas fa-times"></i> Cancel
                                      </a>
                                      <span v-if="appointment.appointmentstatus === 'V'">
                                        <span
                                          class="badge rounded-pill bg-success-light"
                                          style="font-size: 1em; padding: 0.4em 0.8em;"
                                        >Visited</span>
                                      </span>
                                      <span v-if="appointment.appointmentstatus === 'A'">
                                        <span
                                          class="badge rounded-pill bg-warning-light"
                                          style="font-size: 1em; padding: 0.4em 0.8em;"
                                        >No-Show</span>
                                      </span>
                                      <span v-if="appointment.appointmentstatus === 'C'">
                                        <span
                                          class="badge rounded-pill bg-danger text-white ms-2"
                                          style="font-size: 1em; padding: 0.4em 0.8em;"
                                        >Cancelled</span>
                                      </span>
                                    </div>
                                  </td>
                                  <td>{{ appointment.reason }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Specific Day Appointment Tab -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- /Page Content -->

  <!-- /Main Wrapper -->
  <!-- Appointment Details Modal -->
  <div class="modal fade custom-modal" id="appt_details">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Appointment Details</h5>
          <b-button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></b-button>
        </div>
        <div class="modal-body">
          <ul class="info-details">
            <li>
              <div class="details-header">
                <div class="row">
                  <div class="col-md-6">
                    <li>
                      <span class="title">Appointment ID:</span>
                      <span class="text">{{ selectedAppointment.appointmentid }}</span>
                    </li>
                    <li>
                      <span class="title">Appointment Date & Time:</span>
                      <span class="text">
                        {{ formatDate(selectedAppointment.appointmentdate) }} {{
                        formatTime(selectedAppointment.appointmenttime) }}
                      </span>
                    </li>
                  </div>
                  <div class="col-md-6">
                    <div class="text-end">
                      <b-button
                        type="button"
                        :class="{ 'btn bg-success-light btn-sm': appointmentStatus === 'Upcoming', 'btn bg-danger-light btn-sm': appointmentStatus !== 'Upcoming' }"
                        id="topup_status"
                      >{{ appointmentStatus }}</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <span class="title">Status:</span>
              <span class="text">{{ appointmentStatus }}</span>
            </li>
            <li>
              <span class="title">Confirm Date:</span>
              <span class="text">{{ formatedDate(selectedAppointment.createddate) }}</span>
            </li>
            <!-- <li>
              <span class="title">Paid Amount</span>
              <span class="text">$450</span>
            </li>-->
          </ul>
        </div>
      </div>
    </div>
  </div>
  <clinicfooter />
  <!-- /Appointment Details Modal -->
  <!-- Add Prescription Modal -->
  <div v-if="isModalOpen" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Prescription</h2>
        <h4>Patient Name: {{ patientFirstName }} {{ patientLastName }}</h4>
        <button @click="closeModal">✕</button>
      </div>
      <div class="patient-description-section">
        <h5>Patient Description:</h5>
        <textarea
          id="patientDescription"
          v-model="prescripNote"
          :readonly="!editable"
          maxlength="100"
          placeholder="Enter patient condition, diagnosis, etc."
          class="patient-description-textarea"
          @input="prescripEdited(prescripId)"
        ></textarea>
      </div>
      <div class="modal-body">
        <div class="medicine-list-container">
          <div class="search-bar">
            <input type="text" placeholder="Search medicines..." v-model="search" />
            <i class="fa fa-search"></i>
          </div>

          <draggable
            v-model="filteredMedicines"
            class="medicine-list"
            :group="{ name: 'prescription', pull: true, put: false }"
            :disable="!editable"
            item-key="medicinename"
          >
            <template #header>
              <h3>Medicine List</h3>
            </template>
            <template #item="{ element }">
              <div class="medicine-item">
                <div class="medicine-details">
                  <p>
                    <strong>{{ element.medicinename }}</strong>
                    ({{ element.brandname }})
                  </p>
                  <p>{{ element.dosage }}</p>
                </div>
                <!--button @click="addToPrescription(element)">+</button-->
              </div>
            </template>
          </draggable>
        </div>
        <div class="prescription-list-container">
          <draggable
            v-model="prescription"
            class="prescription-list"
            :group="{ name: 'prescription', pull: false, put: true }"
            :disable="!editable"
            item-key="prescripmedid"
            @add="onAddToPrescription"
          >
            <template #header>
              <h3>Prescription List</h3>
            </template>
            <template #item="{ element }">
              <div class="medicine-item">
                <div class="medicine-details">
                  <div class="edit-section">
                    <div v-if="!element.isEditing">
                      <p>
                        <strong>{{ element.medicinename }}</strong>
                        ({{ element.brandname }})
                        <span
                          v-if="editable"
                          @click="toggleEdit(element)"
                        >
                          <i class="fas fa-pencil-alt"></i> Edit
                        </span>
                      </p>
                      <p>{{ element.frequency }}, {{ element.duration }} days</p>
                    </div>
                    <div v-if="element.isEditing">
                      <input v-model="element.medicinename" placeholder="Medicine Name" />
                      <input v-model="element.brandname" placeholder="Brand Name" />
                      <input v-model="element.dosage" placeholder="Type, Strength (mg)" />
                      <input v-model="element.frequency" placeholder="Frequency" />
                      <button @click="saveEdit(element)">OK</button>

                      <!-- Frequency Buttons -->
                      <!--div class="frequency-buttons">
            <button
              v-for="time in ['Morning', 'Afternoon', 'Night']"
              :key="time"
              :class="{'active': element.frequency && element.frequency.includes(time)}"
              @click="toggleFrequency(element, time)"
            >
              {{ time }}
            </button>
                      </div-->

                      <!-- Meal Toggle -->
                      <!--div class="meal-toggle">
            <span @click="toggleMeal(element, 'before')" :class="{ active: element.meal === 'before' }">Before Food</span> | 
            <span @click="toggleMeal(element, 'after')" :class="{ active: element.meal === 'after' }">After Food</span>
                      </div-->

                      <!-- duration-->
                      <div class="duration-field">
                        <p>
                          No. of Days:
                          <input
                            type="number"
                            v-model="element.duration"
                            class="duration-input"
                            placeholder="1"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Note Section -->
                  <div>
                    <span @click="toggleNoteSection(element)">
                      <i class="fas fa-pencil-alt"></i>
                      <!-- Small pencil icon -->
                      {{ element.showNote ? 'Close Note' : 'Open Note' }}
                      <p v-if="!element.showNote" class="note-preview">
                        {{ element.note ? (element.note.length > 25 ? element.note.substring(0, 25) + '...' :
                        element.note) : 'Click to Add Note: a/f food or b/f food, etc' }}
                      </p>
                    </span>
                    <textarea
                      class="note-textarea"
                      v-if="element.showNote"
                      :readonly="!editable"
                      maxlength="250"
                      v-model="element.note"
                      @input="markAsEdited(element)"
                    ></textarea>
                  </div>
                </div>

                <!-- Delete Button -->
                <button
                  v-if="!element.isEditing && editable"
                  class="delete"
                  @click="removeFromPrescription(element)"
                >✕</button>
              </div>
            </template>
          </draggable>
        </div>
      </div>
      <div class="modal-footer">
        <button
          v-if="prescripId === 0"
          :disabled="isSaveDisabled"
          @click="addPrescription(patientid, appointmentid, prescripNote)"
        >Create</button>
        <button
          v-else-if="editable && isprescripEdited "
          :disabled="isSaveDisabled"
          @click="updatePrescription(patientid, appointmentid, prescripId, prescripNote)"
        >Save</button>
        <button v-else-if="editable" :disabled="isSaveDisabled" @click="createPrescription">Save</button>
        <button @click="closeModal">Close</button>
      </div>
    </div>
  </div>

  <!-- Check-in Modal -->

  <div v-if="ischeckinModalOpen" class="checkinmodal-overlay" @click.self="closecheckinModal">
    <div class="checkinmodal-content">
      <div class="checkinmodal-header">
        <h3>Appointment Details</h3>
        <button type="button" class="btn-close" @click="back" aria-label="Close"></button>
      </div>
      <div class="checkinmodal-body">
        <p>
          Patient Name:
          <strong>{{ selectedAppointment.firstname }} {{ selectedAppointment.lastname }}</strong>
        </p>
        <p>
          Appointment Date:
          <strong>{{ formatDate(selectedAppointment.appointmentdate) }}</strong>
        </p>
        <p>
          Appointment Time:
          <strong>{{ formatTime(selectedAppointment.appointmenttime) }}</strong>
        </p>
        <p>
          Clinic:
          <strong>{{ selectedAppointment.clinicname }}</strong>
        </p>
      </div>
      <b-alert
        v-model="showAlert"
        variant="warning"
        class="mt-2"
      >Tokens can only be generated 30 minutes prior to the Appointment Time.</b-alert>
      <div class="checkinmodal-footer">
        <button
          type="button"
          :disabled="isDisabled"
          class="confirm-btn"
          @click="confirmCheckIn(selectedAppointment)"
        >
          <span class="spinner-border spinner-border-sm me-2" role="status" v-if="loading"></span>
          Confirm
        </button>
        <button type="button" class="back-btn" @click="back">Back</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import doctorupcoming from "@/assets/json/doctor/doctorupcoming.json";
import doctortoday from "@/assets/json/doctor/doctortoday.json";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import draggable from "vuedraggable";
import CircleProgress from "vue3-circle-progress";
import "vue3-circle-progress/dist/circle-progress.css";
import { ref } from "vue";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import "jspdf-autotable";
import ExcelJS from "exceljs";
export default {
  components: {
    CircleProgress,
    draggable
  },
  data() {
    return {
      showAlertx: false, 
      alertInterval: null,
      title: "Dashboard",
      text: "Clinic Dashboard",
      text1: "Clinic Doctor Dashboard ",
      selectedClinic: "all",
      isSorted: false,
      isSaveDisabled: true,
      isCreateDisabled: true,
      isModalOpen: false,
      ischeckinModalOpen: false,
      isEditingNote: false,
      newNote: "",
      medicines: [],
      medicine: [],
      patientFirstName: "",
      patientLastName: "",
      appointmentid: null,
      precrip_id: null,
      prescription: [],
      prescripNote: "",
      isprescripEdited: false,
      editable: false,
      prescription_exists: false,
      search: "",
      doctorDetails: [],
      DoctorId: null,
      statDetails: [],
      formattedDate: "",
      upcomingAppointments: [],
      upcomingAppointmentsfilter: [],
      nextSevenDaysAppointments: [],
      currentAppointments: [],
      currentAppointmentsfilter: [],
      selectedAppointment: {},
      filteredAppointments: [],
      selectedDate: null,
      doctorupcoming: doctorupcoming,
      doctortoday: doctortoday,
      selectedTab: "today",
      selectedSpecificDay: null,
      isActive: false,
      doctorinfo: [],
      tokenid: null,
      istokengen: false,
      selectedAppointment: {},
      isDisabled: true,
      loading: false,
      showAlert: true,
      prescripId: null,
      appointmentid: null,
      todaydate: "",
      currDateTime: ""
    };
  },
  computed: {
    sortedFilteredAppointments() {
      let appointmentsToDisplay = [...this.currentAppointmentsfilter];

      if (this.isSorted) {
        appointmentsToDisplay.sort((a, b) => {
          if (a.tokenid === 0 && b.tokenid !== 0) {
            return 1;
          } else if (a.tokenid !== 0 && b.tokenid === 0) {
            return -1;
          } else {
            return a.tokenid - b.tokenid;
          }
        });
      }

      return appointmentsToDisplay;
    },
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isWindows = /Windows/i.test(userAgent);
      const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
      const isMobile = /Mobi|Android/i.test(userAgent);
      return (isMobile || isiOS) && !isWindows;
    },
    filteredMedicines() {
      return this.medicines.filter(item => {
        return (
          !this.search ||
          item.medicinename.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    appointmentStatus() {
      if (this.selectedAppointment.appointmentstatus === "E") {
        return "Upcoming";
      } else {
        return "Cancelled";
      }
    },
    nextTwoDays() {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      const nextTwoDays = [];
      for (let i = 0; i < 2; i++) {
        const nextDate = new Date(today);
        nextDate.setDate(today.getDate() + i);
        const formattedDate = nextDate.toISOString().slice(0, 10);
        nextTwoDays.push(formattedDate);
      }
      return nextTwoDays;
    },
    todayCount() {
      return this.currentAppointmentsfilter.length;
    },
    nextTwoDaysCount() {
      return this.nextTwoDays.map(date => {
        return this.upcomingAppointmentsfilter.filter(appointment => {
          return (
            new Date(appointment.appointmentdate).toISOString().slice(0, 10) ===
            date
          );
        }).length;
      });
    },
    upcomingCount() {
      return this.upcomingAppointmentsfilter.length;
    },
    nextSevenDaysCount() {
      return this.nextSevenDaysAppointments.length;
    }
  },
  watch: {
    search(newValue) {
      this.filteredMedicines;
    },
    prescription(newValue) {
      if (newValue.length > 0) {
        this.isSaveDisabled = false;
        this.isCreateDisabled = false;
      }
    }
  },
  methods: {
    handleDismiss() {
      this.resetAlertInterval(); 
      this.showAlertx = false;
    },
    refreshAppointments() {
      this.fetchUpcomingAppointments();
      this.showAlertx = false;
      this.resetAlertInterval();
      this.startAlertTimer();
    },
    startAlertTimer() {
      this.alertInterval = setInterval(() => {
        this.showAlertx = true;
      }, 300000);
      // 300000
    },
    resetAlertInterval() {
      if (this.alertInterval) {
        clearInterval(this.alertInterval); 
      }
    },
    async fetchCurrDate() {
      try {
        const response = await axios.get("/doctors/datetime");
        this.todaydate = response.data[1];
        this.currDateTime = response.data;
      } catch (error) {
        console.error("Error fetching today's date:", error);
      }
    },
    changeTab(tab) {
      this.selectedTab = tab;
    },
    async isValidTime(appointmentDate, appointmentTime) {
      try {
        const response = await axios.get("/doctors/datetime");
        const [month, date, day, time24, time12] = response.data;

        //console.log("response:", response);
        //console.log("Appointment Date:", appointmentDate);
        //console.log("Appointment Time:", appointmentTime);

        const appointmentDateTimeString = `${appointmentDate}T${appointmentTime}`;
        const appointmentDateTime = new Date(appointmentDateTimeString);

        const currentDateTimeString = `${date}T${time24}`;
        const currentDateTime = new Date(currentDateTimeString);

        const appointmentDateTimeMinus30 = new Date(
          appointmentDateTime.getTime() - 30 * 60000
        );

        if (currentDateTime >= appointmentDateTimeMinus30) {
          this.isDisabled = false;
          this.showAlert = false;
        }
      } catch (error) {
        console.error("Error fetching current date and time:", error);
        return false;
      }
    },
    async opencheckinModal(item) {
      await this.isValidTime(item.appointmentdate, item.appointmenttime);
      //console.log("appt:", item);
      this.selectedAppointment = item;
      this.ischeckinModalOpen = true;
      this.$emit("openModal");
    },
    async confirmCheckIn(item) {
      this.loading = true;
      try {
        //console.log("iteminconfirm", item.patientid);
        const response = await axios.post(
          `/patients/${item.patientid}/appointments/${item.appointmentid}/tokens`
        );
        console.log("Confirmed Check-in"); //, this.selectedAppointment);
        this.fetchUpcomingAppointments();
        this.ischeckinModalOpen = false;
        this.$emit("closeModal");
        this.isDisabled = true;
        this.showAlert = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    back() {
      this.ischeckinModalOpen = false;
      this.showAlert = true;
      this.isDisabled = true;
      this.$emit("closeModal");
    },

    async markasAbsent(patientid, appointmentid) {
      try {
        const status_data = "A";
        const response = await axios.put(
          `/patients/${patientid}/appointments/${appointmentid}/update?status_data=${status_data}`
        );
        console.log("Marked as Absent");
        this.fetchUpcomingAppointments();
      } catch (error) {
        console.error(error);
      }
    },
    async checkToken(appointmentid, patientid) {
      try {
        const response = await axios.get(
          `/patients/${patientid}/appointments/${appointmentid}/tokens`
        );
        if (response.data && response.data.length > 0) {
          return response.data[0].tokenid;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkPrescriptionExists(appointmentid, patientid) {
      try {
        const response = await axios.get(
          `/patients/${patientid}/appointments/${appointmentid}/prescriptions`
        );

        if (response.data && response.data.length > 0) {
          return {
            prescripId: response.data[0].prescripid,
            prescripNote: response.data[0].note
          };
        } else {
          return null;
        }
      } catch (error) {
        console.error("Error checking prescription:", error);
        return null;
      }
    },
    async fetchMedicines() {
      try {
        const response = await axios.get(
          `/doctors/${this.doctorinfo.doctorId}/medicines`
        );
        this.medicine = response.data.map(med => {
          const [frequencyValue, durationValue] = (med.defaultdose || "").split(
            ","
          );
          const numericDuration = durationValue
            ? parseInt(durationValue.match(/\d+/), 10)
            : 5;
          return {
            medicinename: med.medicinename,
            brandname: med.brandname,
            dosage: `${med.medicinetype}, ${med.medstrength}`,
            frequency: frequencyValue || null,
            duration: numericDuration || 5
          };
        });
      } catch (error) {
        console.error("Error fetching medicines:", error);
      }
    },
    addToPrescription(element) {
      if (!this.prescriptionList.includes(element)) {
        /* const newItem = {
            ...element,
            prescripmedid: 0,
            medicinename: element.medicinename,
            brandname: element.brandname,
            dosage: element.medstrength,
            frequency: element.defaultdose, 
            duration: element.defaultdose, 
            meal: "before",
            note: element.medstrength,
            isEditing: false,
            showNote: false,
          };*/

        this.prescription.push(newItem);
        this.filteredMedicines = this.filteredMedicines.filter(
          med => med.medicinename !== medicine.medicinename
        );
      }
    },
    onAddToPrescription(event) {
      this.isSaveDisabled = false;
      const addedMedicine = event.clone;
      const isAlreadyInPrescription = this.prescription.some(
        med =>
          med.medicinename === addedMedicine.medicinename &&
          med.brandname === addedMedicine.brandname
      );
      event.preventDefault();
      if (isAlreadyInPrescription) {
        console.log("This medicine is already in the prescription.");
      }
      this.$nextTick(() => {
        event.item.remove();
      });
    },
    checkSaveButtonState() {
      this.isSaveDisabled = this.prescription.length === 0;
    },
    initializePrescriptionItem(element) {
      return {
        ...element,
        frequency: element.frequency || [],
        meal: element.meal || "",
        showNote: element.showNote || false,
        note: element.note || "",
        isEditing: element.isEditing || false
      };
    },
    handleDrop(event) {
      const droppedElement = event.item;
      const newElement = this.initializePrescriptionItem(droppedElement);
      this.prescription.push(newElement);
    },
    async openPrescription(patientid, appointmentid, firstname, lastname) {
      try {
        this.prescription = [];
        this.medicines = this.medicine;
        this.openModal(
          patientid,
          appointmentid,
          firstname,
          lastname,
          0,
          "",
          true
        );
      } catch (error) {
        console.error("Failed to open prescription:", error);
      }
    },
    async addPrescription(patientid, appointmentid, prescripNote) {
      try {
        this.isCreateDisabled = true;
        const response = await axios.post(
          `/patients/${patientid} /appointments/${appointmentid}/prescriptions`,
          {
            appointmentid: appointmentid,
            prescrippath: "default-path",
            note: prescripNote,
            status: "A"
          }
        );

        this.prescripId = response.data;
        await this.createPrescription();
        this.fetchUpcomingAppointments();
      } catch (error) {
        console.error("Failed to create prescription:", error);
      }
    },
    async viewPrescription(
      prescripId,
      patientid,
      appointmentid,
      firstname,
      lastname
    ) {
      try {
        const { note: prescripNote, prescripdate } = await this.getNote(
          appointmentid,
          patientid
        );
        this.getMedicines(
          prescripId,
          patientid,
          appointmentid,
          firstname,
          lastname,
          prescripNote,
          prescripdate
        );
      } catch (error) {
        console.error(error);
      }
    },
    async getMedicines(
      prescripId,
      patientid,
      appointmentid,
      firstname,
      lastname,
      prescripNote,
      prescripdate
    ) {
      try {
        const response = await axios.get(
          `/patients/1/prescriptions/${prescripId}/medications`
        );

        this.prescription = response.data;

        this.medicines = this.medicine.filter(medicine => {
          return !this.prescription.some(
            prescripItem =>
              prescripItem.medicinename === medicine.medicinename &&
              prescripItem.brandname === medicine.brandname
          );
        });
        this.fetchCurrDate();
        const [month, currDate, weekday, currTime24] = this.currDateTime;

        const currDateTimeString = `${currDate}T${currTime24}`;
        const currDateObject = new Date(currDateTimeString);
        const prescripDateObject = new Date(prescripdate);
        const timeDifference = Math.abs(currDateObject - prescripDateObject);
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        const editable = hoursDifference <= 2;

        this.openModal(
          patientid,
          appointmentid,
          firstname,
          lastname,
          prescripId,
          prescripNote,
          editable
        );
      } catch (error) {
        console.error("Failed to fetch prescription medications:", error);
      }
    },
    openModal(
      patientid,
      appointmentid,
      firstname,
      lastname,
      prescripId,
      prescripNote,
      editable
    ) {
      this.patientFirstName = firstname;
      this.patientLastName = lastname;
      this.prescripId = prescripId;
      this.patientid = patientid;
      this.appointmentid = appointmentid;
      this.prescripNote = prescripNote;
      this.editable = editable;
      this.isModalOpen = true;
    },
    /*async openModal(patientid, appointmentid, firstname, lastname, prescripid) {
       try {
         console.log("apptid:",appointmentid);
         console.log("appt prescripi:",prescripid);
         const response = await axios.post(`/patients/${patientid}   /appointments/${appointmentid}/prescriptions`, {
           appointmentid: appointmentid,
           prescrippath: 'default-path', // Replace with actual data or leave empty if not needed
           note: '', // Default note or replace with actual data
           status: 'A', 
           medications: [{
       prescripmedid: 0,
       prescripid: 0,
       medicinename: "string",
       dosage: "string",
       frequency: "string",
       duration: "string",
       note: "string"
     }]// Default status or replace with actual data
         });
 
         // Store the returned prescrip_id
         this.prescripId = response.data;
 
         // Open the modal after successful API call
         this.openAddPrescriptionModal();
 
       } catch (error) {
         console.error("Failed to create prescription:", error);
         // Handle error (e.g., show a notification to the user)
       }
     this.patientFirstName = firstname;
     this.patientLastName = lastname;
     this.isModalOpen = true;
   },*/
    closeModal() {
      this.isModalOpen = false;
    },
    async removeFromPrescription(element) {
      const index = this.prescription.indexOf(element);

      if (element.prescripmedid && element.prescripmedid !== 0) {
        try {
          await this.deleteMedicationFromServer(element.prescripmedid);
          if (index !== -1) {
            this.prescription.splice(index, 1);
          }
          this.medicines.push(element);
        } catch (error) {
          console.error("Failed to delete medication:", error);
        }
      } else {
        if (index !== -1) {
          this.prescription.splice(index, 1);
          this.medicines.push(element);
        }
      }
      this.checkSaveButtonState();
    },
    async deleteMedicationFromServer(prescripmedid) {
      try {
        const response = await axios.delete(
          `/patients/1/medications/${prescripmedid}`
        );
        this.checkSaveButtonState();
      } catch (error) {
        console.error("Failed to delete medications:", error);
      }
    },
    onDragEnd(evt) {
      const draggedItem = this.medicines[evt.oldIndex];
      if (evt.from === evt.to) {
      } else {
        this.addToPrescription(draggedItem);
        this.filteredMedicines = this.filteredMedicines.filter(
          med => med.medicinename !== movedItem.medicinename
        );
      }
    },
    async updatePrescription(
      patientid,
      appointmentid,
      prescrip_id,
      prescripnote
    ) {
      try {
        const response = await axios.put(
          `/patients/${patientid}/appointments/${appointmentid}/prescriptions`,
          {
            appointmentid: appointmentid,
            prescrippath: "default-path",
            note: prescripnote,
            status: "A"
          },
          {
            params: {
              prescrip_id: prescrip_id
            }
          }
        );

        this.prescripId = response.data;
        await this.createPrescription();
        this.fetchUpcomingAppointments();
      } catch (error) {
        console.error(error);
      }
    },
    async createPrescription() {
      try {
        const newMedications = this.prescription
          .filter(med => !med.prescripmedid)
          .map(med => {
            //const [frequencyValue, durationValue] = (med.frequency || "").split(","); // Split frequency and duration
            return {
              prescripmedid: 0,
              medicinename: med.medicinename,
              brandname: med.brandname,
              dosage: med.dosage,
              frequency: med.frequency,
              duration: med.duration,
              note: med.note || ""
            };
          });

        const editedMedications = this.prescription
          .filter(
            med => med.prescripmedid && (med.isEdited || med.isnoteEdited)
          ) // Check for edited medicines
          .map(med => {
            const [frequencyValue, durationValue] = (med.frequency || "").split(
              ","
            ); // Split frequency and duration
            return {
              prescripmedid: med.prescripmedid,
              medicinename: med.medicinename,
              brandname: med.brandname,
              dosage: med.dosage,
              frequency: med.frequency,
              duration: med.duration,
              note: med.note || " "
            };
          });
        const medications = [...newMedications, ...editedMedications];
        //console.log("med:", medications);
        const response = await axios.post(
          `/patients/1/prescriptions/${this.prescripId}/medications`,
          medications,
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        );
        console.log("Prescription created successfully"); //, response.data);

        this.closeModal();
      } catch (error) {
        console.error("Failed to add medications:", error);
      }
    },
    resetPrescription() {
      this.prescription = [];
    },
    toggleEdit(item) {
      item.isEditing = !item.isEditing;
    },
    toggleNoteSection(element) {
      element.showNote = !element.showNote;
    },
    toggleFrequency(element, time) {
      if (!element.frequency) {
        element.frequency = [];
      }
      const index = element.frequency.indexOf(time);
      if (index !== -1) {
        element.frequency.splice(index, 1);
      } else {
        element.frequency.push(time);
      }
    },
    toggleMeal(element, mealType) {
      element.meal = mealType;
    },
    toggleNoteSection(element) {
      element.showNote = !element.showNote;
    },
    addNote() {
      this.isEditingNote = true;
    },
    saveNote() {
      if (this.newNote.trim()) {
        this.$set(this.element, "note", this.newNote);
        this.isEditingNote = false;
      }
    },
    editNote() {
      this.newNote = this.element.note;
      this.isEditingNote = true;
    },
    prescripEdited() {
      this.isprescripEdited = true;
    },
    saveEdit(med) {
      med.isEditing = false;
      med.isEdited = true; // Mark this medicine as edited
    },
    markAsEdited(med) {
      med.isnoteEdited = true;
    },
    toggleEdit(med) {
      med.isEditing = true;
    },
    formatDatecheckin(dateTimeString) {
      const date = new Date(dateTimeString);
      const options = { day: "numeric", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    },
    formatTimecheckin(dateTimeString) {
      const date = new Date(dateTimeString);
      const options = { hour: "2-digit", minute: "2-digit", hour12: true };
      return date.toLocaleTimeString("en-US", options);
    },
    toggleActiveStatus() {
      this.isActive = !this.isActive;
      this.toggle();
    },
    toggle() {
      this.isActive = !this.isActive;
      if (this.selectedDate) {
        this.filterAppointmentsByDate(this.selectedDate);
      }
      this.filterCurrentAppointments();
      this.filterUpcomingAppointments();
      this.filternextSevenDaysAppointments();
    },

    formatShortDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric"
      });
    },
    filternextSevenDaysAppointments() {
      const sortedAppointments = this.upcomingAppointments.sort((a, b) => {
        return new Date(a.appointmentdate) - new Date(b.appointmentdate);
      });
      const currentDate = new Date(this.todaydate);
      const nextWeekDate = new Date();
      nextWeekDate.setDate(currentDate.getDate() + 7);
      const filteredAppointments = sortedAppointments.filter(appointment => {
        const appointmentDate = new Date(appointment.appointmentdate);
        return (
          appointmentDate >= currentDate && appointmentDate <= nextWeekDate
        );
      });
      if (!this.isActive) {
        this.nextSevenDaysAppointments = filteredAppointments.filter(
          appointment => appointment.appointmentstatus !== "C"
        );
      } else {
        this.nextSevenDaysAppointments = [...filteredAppointments];
      }
    },
    filterAppointmentsByDate(date) {
      if (!this.isActive) {
        this.filteredAppointments = this.upcomingAppointments.filter(
          appointment => {
            return (
              new Date(appointment.appointmentdate).toLocaleDateString() ===
                date.toLocaleDateString() &&
              appointment.appointmentstatus !== "C"
            );
          }
        );
      } else {
        this.filteredAppointments = this.upcomingAppointments.filter(
          appointment => {
            return (
              new Date(appointment.appointmentdate).toLocaleDateString() ===
              date.toLocaleDateString()
            );
          }
        );
      }
      //console.log("Filtered appointments:", this.filteredAppointments);
    },

    filterUpcomingAppointments() {
      if (!this.isActive) {
        this.upcomingAppointmentsfilter = this.upcomingAppointments.filter(
          appointment => {
            return appointment.appointmentstatus !== "C";
          }
        );
      } else {
        this.upcomingAppointmentsfilter = [...this.upcomingAppointments];
      }
      //console.log("Filtered appointments:", this.filteredAppointments);
    },

    filterCurrentAppointments() {
      if (!this.isActive) {
        //console.log("curr appts:", this.currentAppointments);
        this.currentAppointmentsfilter = this.currentAppointments.filter(
          appointment => {
            return appointment.appointmentstatus !== "C";
          }
        );
        //console.log("Active filtered appointments:",this.currentAppointmentsfilter);
      } else {
        this.currentAppointmentsfilter = [...this.currentAppointments];
      }
      //console.log("Active filtered appointments show:", this.currentAppointmentsfilter);
    },

    selectSpecificDay(date) {
      this.selectedDate = new Date(date);
      this.selectedTab = "specific";
      this.filterAppointmentsByDate(this.selectedDate);
      this.selectedSpecificDay = date;
      this.changeTab("specific");
    },

    selectUpcomingAppointments() {
      this.selectedTab = "upcoming";
      this.selectedSpecificDay = null;
      this.changeTab("upcoming");
    },
    selectNextSevenDaysAppointments() {
      this.selectedTab = "nextSevenDays";
      this.selectedSpecificDay = null;
      this.changeTab("nextSevenDays");
    },

    selectTodayAppointments() {
      this.selectedTab = "today";
      this.selectedSpecificDay = null;
      this.changeTab("today");
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    formatedDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    formatTime(time) {
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },
    getPatientImage(appointment) {
      return (
        appointment.patientimage || require("@/assets/img/patients/patient.jpg")
      );
    },

    async fetchDoctorStatCount() {
      const doctorId = this.doctorinfo.doctorId;

      const clinicId = this.doctorinfo.clinicId;
      try {
        const response = await axios.get(
          `/clinic/${doctorId}/doctorstats/${clinicId}`
        );
        this.statDetails = response.data;
        //console.log("Additional Details:", this.statDetails);
      } catch (error) {
        console.error("Error fetching doctors count:", error);
      }
    },
    async fetchTodaysAppointments() {
      try {
        const response = await axios.get(
          `/clinic/${this.doctorinfo.doctorId}/clinicappointments/${this.doctorinfo.clinicId}/today`
        );
        this.currentAppointments = response.data.current_day_appointments;
        this.filterCurrentAppointments();
      } catch (error) {
        console.error(error);
      }
    },
    async fetchUpcomingAppointments() {
      try {
        const response = await axios.get(
          `/clinic/${this.doctorinfo.doctorId}/clinicappointments/${this.doctorinfo.clinicId}?filter_type=upcoming`
        );
        this.upcomingAppointments = response.data.upcoming_appointments;
        /*const checkUpcomingPromises = this.upcomingAppointments.map(
          async appointment => {
            const prescriptionExists = await this.checkPrescriptionExists(
              appointment.appointmentid,
              appointment.patientid
            );
            if (prescriptionExists) {
              appointment.prescriptionStatus = "exists";
              appointment.prescripId = prescriptionExists.prescripId;
              appointment.prescripNote = prescriptionExists.prescripNote; // Store the prescription ID if it exists
            } else {
              appointment.prescriptionStatus = "new";
            }
          }
        );
        const checkCurrentPromises = this.currentAppointments.map(
          async appointment => {
            const prescriptionExists = await this.checkPrescriptionExists(
              appointment.appointmentid,
              appointment.patientid
            );
            const tokenExists = await this.checkToken(
              appointment.appointmentid,
              appointment.patientid
            );

            if (prescriptionExists) {
              appointment.prescriptionStatus = "exists";
              appointment.prescripId = prescriptionExists.prescripId;
              appointment.prescripNote = prescriptionExists.prescripNote;
            } else {
              appointment.prescriptionStatus = "new";
            }

            if (tokenExists) {
              appointment.tokenNumber = tokenExists;
            } else {
              appointment.tokenNumber = null;
            }
          }
        );
        await Promise.all([...checkUpcomingPromises, ...checkCurrentPromises]);*/

        if (this.selectedDate && this.selectedTab === "specific") {
          this.filterAppointmentsByDate(this.selectedDate);
        } 
        this.fetchTodaysAppointments();
        this.filterUpcomingAppointments();
        this.filternextSevenDaysAppointments();
      } catch (error) {
        console.error("Error fetching upcoming appointments", error);
      }
    },
    viewAppointmentDetails(appointment) {
      this.selectedAppointment = appointment;
    },
    cancelAppointment(appointment) {
      const {
        appointmentid,
        appointmentby,
        appointmentdate,
        appointmenttime
      } = appointment;

      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to cancel the appointment on ${this.formatDate(
          appointmentdate
        )} at ${this.formatTime(appointmenttime)}?`,
        input: "text",
        inputLabel: "Reason for cancellation",
        inputPlaceholder: "Enter reason here...",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, cancel it!"
      }).then(result => {
        if (result.isConfirmed) {
          const cancelReason = result.value;

          const cancelApiUrl = `/patients/${appointmentby}/appointments/${appointmentid}`;
          axios
            .put(cancelApiUrl, { cancelreason: cancelReason })
            .then(response => {
              console.log("Appointment cancelled successfully"); //, response.data);

              this.fetchUpcomingAppointments();
              this.fetchDoctorStatCount(this.DoctorId);
            })
            .catch(error => {
              console.error("Error cancelling appointment", error);
            });
        }
      });
    },
    fetchAppointments() {
      axios
        .get(
          `/doctors/${this.doctorinfo.doctorId}/appointments?filter_type=upcoming`
        )
        .then(response => {
          this.currentAppointments = response.data.current_day_appointments;
        })
        .catch(error => {
          console.error("Error fetching appointments:", error);
        });
    },
    exportAppointments(format) {
      if (format === "pdf") {
        if (this.selectedTab === "upcoming") {
          if (!this.isActive) {
            const upcomingAppointmentspdf = this.upcomingAppointments.filter(
              appointment => {
                return appointment.appointmentstatus !== "C";
              }
            );
            this.exportToPDF(upcomingAppointmentspdf);
          } else {
            this.exportToPDF(this.upcomingAppointments);
          }
        } else if (this.selectedTab === "nextSevenDays") {
          if (!this.isActive) {
            const nextsevenAppointmentspdf = this.nextSevenDaysAppointments.filter(
              appointment => {
                return appointment.appointmentstatus !== "C";
              }
            );
            this.exportToPDF(nextsevenAppointmentspdf);
          } else {
            this.exportToPDF(this.nextSevenDaysAppointments);
          }
        } else if (this.selectedTab === "today") {
          if (!this.isActive) {
            const currentAppointmentspdf = this.currentAppointments.filter(
              appointment => {
                return appointment.appointmentstatus !== "C";
              }
            );
            this.exportToPDF(currentAppointmentspdf);
          } else {
            this.exportToPDF(this.currentAppointments);
          }
        } else if (this.selectedTab === "specific") {
          if (!this.isActive) {
            const appointmentsForSelectedDayfilter = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                    this.selectedDate.toLocaleDateString() &&
                  appointment.appointmentstatus !== "C"
                );
              }
            );
            this.exportToPDF(appointmentsForSelectedDayfilter);
          } else {
            const appointmentsForSelectedDay = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                  this.selectedDate.toLocaleDateString()
                );
              }
            );
            this.exportToPDF(appointmentsForSelectedDay);
          }
        }
      } else if (format === "excel") {
        if (this.selectedTab === "upcoming") {
          if (!this.isActive) {
            this.upcomingAppointmentspdf = this.upcomingAppointments.filter(
              appointment => {
                return appointment.appointmentstatus !== "C";
              }
            );
            this.exportToExcel(this.upcomingAppointmentspdf);
          } else {
            this.exportToExcel(this.upcomingAppointments);
          }
        } else if (this.selectedTab === "nextSevenDays") {
          if (!this.isActive) {
            this.nextSevenDaysAppointmentspdf = this.nextSevenDaysAppointments.filter(
              appointment => {
                return appointment.appointmentstatus !== "C";
              }
            );
            this.exportToExcel(this.nextSevenDaysAppointmentspdf);
          } else {
            this.exportToExcel(this.nextSevenDaysAppointments);
          }
        } else if (this.selectedTab === "today") {
          if (!this.isActive) {
            this.currentAppointmentspdf = this.currentAppointments.filter(
              appointment => {
                return appointment.appointmentstatus !== "C";
              }
            );
            this.exportToExcel(this.currentAppointmentspdf);
          } else {
            this.exportToExcel(this.currentAppointments);
          }
        } else if (this.selectedTab === "specific") {
          if (!this.isActive) {
            const appointmentsForSelectedDayfilter = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                    this.selectedDate.toLocaleDateString() &&
                  appointment.appointmentstatus !== "C"
                );
              }
            );
            this.exportToExcel(appointmentsForSelectedDayfilter);
          } else {
            const appointmentsForSelectedDay = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                  this.selectedDate.toLocaleDateString()
                );
              }
            );
            this.exportToExcel(appointmentsForSelectedDay);
          }
        }
      }
    },
    exportToPDF(Appointments) {
      const doc = new jsPDF();
      const appointments = Appointments.map(appointment => [
        appointment.firstname + " " + appointment.lastname,
        appointment.appointmentdate,
        this.formatTime(appointment.appointmenttime),
        appointment.clinicname,
        this.isActive && appointment.appointmentstatus === "C"
          ? "Cancelled"
          : ""
      ]);

      let heading;
      if (this.selectedTab === "today") {
        heading = "Appointments for Today";
      } else if (this.selectedTab === "upcoming") {
        heading = "Upcoming Appointments";
      } else {
        heading = "Appointments for " + this.formatDate(this.selectedDate);
      }

      const gap = 10;
      const yPos = gap;

      const doctorText = "Doctor: Dr." + this.doctorDetails.doctorname;
      const doctorTextWidth =
        (doc.getStringUnitWidth(doctorText) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const xPosDoctor = (doc.internal.pageSize.width - doctorTextWidth) / 2;

      doc.text(doctorText, xPosDoctor, yPos + gap);
      const headingTextWidth =
        (doc.getStringUnitWidth(heading) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const xPosHeading = (doc.internal.pageSize.width - headingTextWidth) / 2;

      doc.text(heading, xPosHeading, yPos + gap * 2);
      const headers = ["Patient Name", "Appt Date", "Appt Time", "Clinic Name"];
      if (this.isActive) headers.push("");
      doc.autoTable({
        head: [headers],

        body: appointments,
        startY: yPos + gap * 3
      });
      doc.save("appointments.pdf");
    },
    exportToExcel(Appointments) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Appointments");
      const doctorText = "Doctor: Dr. " + this.doctorDetails.doctorname;

      worksheet.mergeCells("A1", "E1");
      worksheet.getCell("A1").value = doctorText;
      worksheet.getCell("A1").font = { bold: true, size: 14 };
      worksheet.getCell("A1").alignment = {
        vertical: "middle",
        horizontal: "center"
      };

      worksheet.addRow([]);
      const headers = [
        "Patient Name",
        "Appt Date",
        "Appt Time",
        "Clinic Name",
        "Appointment Status"
      ];
      worksheet.addRow(headers).font = { bold: true };

      worksheet.columns = [
        { key: "patientName", width: 30 },
        { key: "appointmentDate", width: 20 },
        { key: "appointmentTime", width: 20 },
        { key: "appointmentClinic", width: 40 },
        { key: "appointmentStatus", width: 20 }
      ];
      Appointments.forEach(appointment => {
        const rowData = {
          patientName: `${appointment.firstname} ${appointment.lastname}`,
          appointmentDate: appointment.appointmentdate,
          appointmentTime: this.formatTime(appointment.appointmenttime),
          appointmentClinic: appointment.clinicname,
          appointmentStatus:
            appointment.appointmentstatus === "C" ? "Cancelled" : ""
        };
        worksheet.addRow(rowData);
      });
      try {
        workbook.xlsx.writeBuffer().then(buffer => {
          const blob = new Blob([buffer], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "appointments.xlsx";
          link.click();
        });
      } catch (error) {
        console.error("Error generating Excel file:", error);
      }
    },
    async downloadPrescriptionPDF(prescripId, appointmentId, patientid) {
      try {
        const apidata = await this.fetchPrescripData(
          patientid,
          appointmentId,
          prescripId
        );
        const apptdata = apidata.appointment_details;
        let prescripNote = apidata.prescriptions[0].note;
        prescripNote = prescripNote
          ? prescripNote.replace(/\n/g, "<br>")
          : "No description provided.";

        //const apptdata = await this.fetchApptDetails(patientid, slno);
        //console.log(apptdata);
        //const prescriptionData = await this.fetchPrescipDetails(prescripId);
        const prescriptionData = apidata.prescription_medications;
        const formattedDate = await this.formattedDateandTime(
          apptdata.date_and_time
        );
        const logoUrl = require("@/assets/img/logo.png");
        const doc = this.doctorDetails;

        const clinicSettings = await this.fetchClinicSettings(
          apptdata.doctor_id,
          apptdata.clinicid
        );
        const headerImage = clinicSettings.prescrip_header_image
          ? `data:image/png;base64,${clinicSettings.prescrip_header_image}`
          : null;
        const footerImage = clinicSettings.prescrip_footer_image
          ? `data:image/png;base64,${clinicSettings.prescrip_footer_image}`
          : null;
        const doctorSignature = clinicSettings.docsignature
          ? `data:image/png;base64,${clinicSettings.docsignature}`
          : null;

        const tableRows = prescriptionData
          .map(
            medication => `
      <tr>
        <td>${medication.medicinename}</td>
        <td>${medication.brandname}</td>
        <td>${medication.dosage}</td>
        <td>${medication.frequency}</td>
        <td>${medication.duration}</td>
        <td>${medication.note || "-"}</td>
      </tr>`
          )
          .join("");

        const tableHTML = `
      <table>
        <thead>
          <tr>
            <th>Medicine Name</th>
            <th>Brand Name</th>
            <th>Dosage</th>
            <th>Frequency</th>
            <th>No. of Days</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
      </table>
    `;

        const htmlContent = `
      <html>
        <head>
          <title>Prescription - YourDoc</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              color: black;
              margin: 0;
              padding: 0;
              height: 297mm;
              width: 210mm;
              display: flex;
              flex-direction: column;
            }
            .content-wrapper {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: calc(297mm - 20mm);
              page-break-before: avoid;
              page-break-inside: always;
              margin: 0;
              padding: 0;
            }
            .header, .footer {
              page-break-inside: avoid;
            }
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #000;
              padding-bottom: 10px;
              margin-bottom: 20px;
            }
            .header-left, .header-right {
              width: 45%;
            }
            .header-image {
              max-width: 100px;
              height: auto;
            }
            .doctor-info {
              text-align: left;
            }
            .doctor-info p {
              margin: 2px 0;
            }
            .clinic-info {
              text-align: right;
            }
            .clinic-info p {
              margin: 2px 0;
            }
            .patient-details {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 14px;
              margin: 10px 0;
            }
            .patient-details p {
              margin: 0; 
            }
            .patient-details p strong {
              font-weight: bold;
            }
            .prescription-details {
              flex: 1;
            }
            
            .rx-symbol {
              font-size: 18px;
              margin: 2px 0;
              text-align: left;
              font-weight: bold;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 10px;
            }
            th, td {
              padding: 8px;
              border: 1px solid #ddd;
            }
            th {
              background-color: #f4f4f4;
              text-align: left;
            }
            tr {
              page-break-inside: avoid;
            }
            .doctor-signature {
              margin-top: 20px;
              text-align: right;
            }
            .doctor-signature img {
              max-width: 200px;
              height: auto;
            }
            .footer {
              text-align: right;
              margin-top: auto;
              background: none;
            }
            .footer-image {
              width: 100%;
              max-height: 40px;
            }
            .powered-by {
              margin-top: 10px;
              display: flex;
              justify-content: flex-end;
              align-items: right;
            }
            .powered-by img {
              max-height: 30px;
              width: auto;
            }
          </style>
        </head>
        <body>
          <div class="content-wrapper">
            <div class="header">
              <div class="header-left">
                <div class="doctor-info">
                  <p><strong>Dr. ${apptdata.doctor_name}</strong></p>
                  <p>${apptdata.speciality}</p>
                  <p>${doc.qualifications} | Reg. No: ${
          doc.registration_info
        }</p>
                </div>
              </div>
              ${
                headerImage
                  ? `<img src="${headerImage}" class="header-image" />`
                  : ""
              }
              <div class="header-right">
                <div class="clinic-info">
                  <p><strong>${apptdata.clinic_name}</strong></p>
                  <p>${apptdata.clinicaddress}</p>
                  <p>Phone: ${apptdata.clinic_phone}</p>
                </div>
              </div>
            </div>

            <div class="prescription-details">
              <div class="patient-details">
                <p><strong>Patient Name:</strong> ${apptdata.patient_name}</p>
                <p><strong>Date:</strong> ${formattedDate}</p>
              </div>
              <p><strong>Prescription Note:</strong> 
              ${prescripNote ? `${prescripNote}` : ""}</p>
              <div class="rx-symbol">℞</div>
              <div>${tableHTML}</div>

              ${
                doctorSignature
                  ? `<div class="doctor-signature">
                <img src="${doctorSignature}" alt="Doctor Signature" />
                <p><strong>Dr. ${apptdata.doctor_name}</strong></p>
                <p>${apptdata.speciality}</p>
              </div>`
                  : ""
              }
            </div>

            <div class="footer">
              ${
                footerImage
                  ? `<img src="${footerImage}" class="footer-image" />`
                  : ""
              }
              <div class="powered-by">
                <span>Powered By</span>
                <img src="${logoUrl}" alt="YourDoc Logo" />
              </div>
            </div>
          </div>
        </body>
      </html>
    `;

        const element = document.createElement("div");
        element.innerHTML = htmlContent;
        const opt = {
          margin: 5,
          filename: `prescription_${apptdata.patient_name}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          pagebreak: { mode: ["css", "legacy"], avoid: ".footer" }
        };

        html2pdf()
          .set(opt)
          .from(element)
          .save();
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
    async print_Download(prescripId, appointmentId, patientid) {
      try {
        //const { note: prescripNote, prescripdate } = await this.getNote(appointmentId, patientid);

        if (this.isMobile) {
          this.downloadPrescriptionPDF(prescripId, appointmentId, patientid);
        } else {
          this.printPrescription(prescripId, appointmentId, patientid);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async printPrescription(prescripId, appointmentId, patientid) {
      try {
        const apidata = await this.fetchPrescripData(
          patientid,
          appointmentId,
          prescripId
        );
        const apptdata = apidata.appointment_details;
        let prescripNote = apidata.prescriptions[0].note;
        prescripNote = prescripNote
          ? prescripNote.replace(/\n/g, "<br>")
          : "No description provided.";
        //const apptdata = await this.fetchApptDetails(patientid, appointmentId);
        //console.log(apptdata);
        //const prescriptionData = await this.fetchPrescipDetails(prescripId);
        const prescriptionData = apidata.prescription_medications;
        const formattedDate = await this.formattedDateandTime(
          apptdata.date_and_time
        );
        const logoUrl = require("@/assets/img/logo.png");
        const doc = this.doctorDetails;

        const clinicSettings = await this.fetchClinicSettings(
          apptdata.doctor_id,
          apptdata.clinicid
        );
        const headerImage = clinicSettings.prescrip_header_image
          ? `data:image/png;base64,${clinicSettings.prescrip_header_image}`
          : null;
        const footerImage = clinicSettings.prescrip_footer_image
          ? `data:image/png;base64,${clinicSettings.prescrip_footer_image}`
          : null;
        const doctorSignature = clinicSettings.docsignature
          ? `data:image/png;base64,${clinicSettings.docsignature}`
          : null;

        const tableRows = prescriptionData
          .map(
            medication => `
      <tr>
        <td>${medication.medicinename}</td>
        <td>${medication.brandname}</td>
        <td>${medication.dosage}</td>
        <td>${medication.frequency}</td>
        <td>${medication.duration}</td>
        <td>${medication.note || "-"}</td>
      </tr>`
          )
          .join("");

        const tableHTML = `
      <table>
        <thead>
          <tr>
            <th>Medicine Name</th>
            <th>Brand Name</th>
            <th>Dosage</th>
            <th>Frequency</th>
            <th>No. of Days</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
      </table>
    `;

        const htmlContent = `
      <html>
        <head>
          <title>Prescription - YourDoc</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              color: black;
              margin: 0;
              padding: 0;
              height: 297mm;
              width: 210mm;
              display: flex;
              flex-direction: column;
            }
            .content-wrapper {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: calc(297mm - 20mm);
              page-break-before: avoid;
              page-break-inside: always;
              margin: 0;
              padding: 0;
            }
            .header, .footer {
              page-break-inside: avoid;
            }
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #000;
              padding-bottom: 10px;
              margin-bottom: 20px;
            }
            .header-left, .header-right {
              width: 45%;
            }
            .header-image {
              max-width: 100px;
              height: auto;
            }
            .doctor-info {
              text-align: left;
            }
            .doctor-info p {
              margin: 2px 0;
            }
            .clinic-info {
              text-align: right;
            }
            .clinic-info p {
              margin: 2px 0;
            }
            .patient-details {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 14px;
              margin: 10px 0;
            }
            .patient-details p {
              margin: 0; 
            }
            .patient-details p strong {
              font-weight: bold;
            }
            .prescription-details {
              flex: 1;
            }
            
            .rx-symbol {
              font-size: 18px;
              margin: 2px 0;
              text-align: left;
              font-weight: bold;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 10px;
            }
            th, td {
              padding: 8px;
              border: 1px solid #ddd;
            }
            th {
              background-color: #f4f4f4;
              text-align: left;
            }
            tr {
              page-break-inside: avoid;
            }
            .doctor-signature {
              margin-top: 20px;
              text-align: right;
            }
            .doctor-signature img {
              max-width: 200px;
              height: auto;
            }
            .footer {
              text-align: right;
              margin-top: auto;
              background: none;
            }
            .footer-image {
              width: 100%;
              max-height: 40px;
            }
            .powered-by {
              margin-top: 10px;
              display: flex;
              justify-content: flex-end;
              align-items: right;
            }
            .powered-by img {
              max-height: 30px;
              width: auto;
            }
          </style>
        </head>
        <body>
          <div class="content-wrapper">
            <div class="header">
              <div class="header-left">
                <div class="doctor-info">
                  <p><strong>Dr. ${apptdata.doctor_name}</strong></p>
                  <p>${apptdata.speciality}</p>
                  <p>${doc.qualifications} | Reg. No: ${
          doc.registration_info
        }</p>
                </div>
              </div>
              ${
                headerImage
                  ? `<img src="${headerImage}" class="header-image" />`
                  : ""
              }
              <div class="header-right">
                <div class="clinic-info">
                  <p><strong>${apptdata.clinic_name}</strong></p>
                  <p>${apptdata.clinicaddress}</p>
                  <p>Phone: ${apptdata.clinic_phone}</p>
                </div>
              </div>
            </div>

            <div class="prescription-details">
              <div class="patient-details">
                <p><strong>Patient Name:</strong> ${apptdata.patient_name}</p>
                <p><strong>Date:</strong> ${formattedDate}</p>
              </div>
              <p><strong>Prescription Note:</strong> 
              ${prescripNote ? `${prescripNote}` : ""}</p>
              <div class="rx-symbol">℞</div>
              <div>${tableHTML}</div>

              ${
                doctorSignature
                  ? `<div class="doctor-signature">
                <img src="${doctorSignature}" alt="Doctor Signature" />
                <p><strong>Dr. ${apptdata.doctor_name}</strong></p>
                <p>${apptdata.speciality}</p>
              </div>`
                  : ""
              }
            </div>

            <div class="footer">
              ${
                footerImage
                  ? `<img src="${footerImage}" class="footer-image" />`
                  : ""
              }
              <div class="powered-by">
                <span>Powered By</span>
                <img src="${logoUrl}" alt="YourDoc Logo" />
              </div>
            </div>
          </div>
        </body>
      </html>
    `;

        const element = document.createElement("div");
        element.innerHTML = htmlContent;
        const opt = {
          margin: 5,
          filename: `prescription_${apptdata.patient_name}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          pagebreak: { mode: ["css", "legacy"], avoid: ".footer" }
        };

        html2pdf()
          .set(opt)
          .from(element)
          .toPdf()
          .get("pdf")
          .then(function(pdf) {
            pdf.autoPrint();
            const pdfBlob = pdf.output("bloburl");

            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.src = pdfBlob;

            document.body.appendChild(iframe);
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
          });
      } catch (error) {
        console.error("Error printing prescription:", error);
      }
    },
    async getNote(appointmentid, patientid) {
      try {
        const response = await axios.get(
          `/patients/${patientid}/appointments/${appointmentid}/prescriptions`
        );

        if (response.data && response.data.length > 0) {
          const { note, prescripdate } = response.data[0];
          return { note, prescripdate };
        } else {
          return { note: null, prescripdate: null };
        }
      } catch (error) {
        console.error("Error fetching prescription data:", error);
        return { note: null, prescripdate: null };
      }
    },
    async fetchPrescripData(patientId, appointmentId, prescripId) {
      try {
        const response = await axios.get(
          `/patients/${patientId}/appointmentdetails/${appointmentId}?prescripid=${prescripId}`
        );
        return response.data;
      } catch (error) {
        console.error(
          "Error fetching appointment and prescription details:",
          error
        );
        return null;
      }
    },
    async fetchClinicSettings(doctor_id, clinic_id) {
      try {
        const response = await axios.get(
          `/doctors/${doctor_id}/doctorclinic-settings/${clinic_id}`
        );
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async formattedDateandTime(dateString) {
      const date = new Date(dateString);

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];

      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      let hours = date.getHours();
      const minutes = date
        .getMinutes()
        .toString()
        .padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;

      return `${month} ${day}, ${year}, <b>Time: </b>${hours}:${minutes} ${ampm}`;
    },
    scrollTableRight() {
      const tableContainer = this.$refs.tableContainer;
      tableContainer.scrollBy({
        left: 200,
        behavior: "smooth"
      });
    },
    async fetchDoctorDetails() {
      const doctorId = this.doctorinfo.doctorId;
      try {
        const response = await axios.get(`/doctors/${doctorId}`);
        this.doctorDetails = response.data;
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    },
    scrollTableLeft() {
      const tableContainer = this.$refs.tableContainer;
      tableContainer.scrollBy({
        left: -200,
        behavior: "smooth"
      });
    },

    scrollTableRightx() {
      const tableContainerx = this.$refs.tableContainerx;
      tableContainerx.scrollBy({
        left: 200,
        behavior: "smooth"
      });
    },

    scrollTableLeftx() {
      const tableContainerx = this.$refs.tableContainerx;
      tableContainerx.scrollBy({
        left: -200,
        behavior: "smooth"
      });
    },
    scrollTableRightz() {
      const tableContainerz = this.$refs.tableContainerz;
      tableContainerz.scrollBy({
        left: 200,
        behavior: "smooth"
      });
    },

    scrollTableLeftz() {
      const tableContainerz = this.$refs.tableContainerz;
      tableContainerz.scrollBy({
        left: -200,
        behavior: "smooth"
      });
    },
    scrollTableRighty() {
      const tableContainery = this.$refs.tableContainery;
      tableContainery.scrollBy({
        left: 200,
        behavior: "smooth"
      });
    },

    scrollTableLefty() {
      const tableContainery = this.$refs.tableContainery;
      tableContainery.scrollBy({
        left: -200,
        behavior: "smooth"
      });
    }
  },
  mounted() {
    this.startAlertTimer();
  },
  beforeDestroy() {
    this.resetAlertInterval();
  },
  beforeMount() {
    this.doctorinfo = this.$store.getters.getclinicDetails;
    //console.log("store:",this.doctorinfo);
    this.fetchCurrDate();
    this.fetchDoctorStatCount();
    this.fetchUpcomingAppointments();
    this.fetchMedicines();
    this.formatDate();
    this.fetchDoctorDetails();

    //console.log("this is doctor details", this.doctorclinicid);
    const percent = ref(75);
    setInterval(() => {
      if (percent.value === 25) {
        percent.value = 75;
      } else {
        percent.value = 25;
      }
    }, 1000);
  },
  name: "doctor-dashboard"
};
</script>
<style scoped>
.download-text {
  cursor: pointer;
}

.export-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.dropdown-menu a.dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tab-selector button {
  background-color: #f1f5f9;
  color: #334155;
  border: none;
  padding: 8px 16px;
  margin-right: 1px;
  margin-bottom: 8px;
  border-radius: 14px;
  cursor: pointer;
}

.tab-selector button.active {
  background-color: #0e82fd;
  color: #ffffff;
}

.tab-selector button:hover {
  background-color: #0c72e0;
  color: #ffffff;
}

.badge.custom-badge {
  background-color: #ffffff;
  color: #000;
  font-size: 12px;
  margin-left: 8px;
}

.tab-button-style .nav-link {
  display: inline-block;
  padding: 8px 16px;
  background-color: #f1f5f9;
  color: #0f172a;
  border-radius: 4px;
}

.tab-button-style .nav-link.active {
  background-color: #0e82fd;
  color: #d8f1ff;
}
.tab-button-style .nav-link:hover {
  background-color: #0e82fd;
  color: #ffffff;
}
.tab-button-style .nav-link .badge.custom-badge {
  background-color: #ffffff;
  color: #0f172a;
  font-size: 12px;
  margin-left: 8px;
}

.tab-button-style .nav-link:focus-visible {
  background-color: #0e82fd;
  color: #d8f1ff;
}
.mb-3 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
  color: #080808;
}
.form-check.form-switch.form-check-label {
  display: flex;
  align-items: center;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  width: 90%;
  max-width: 900px;
  padding: 15px;
  max-height: 95vh;
  min-height: 95vh;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal-body {
  display: flex;
  gap: 20px;
  flex-grow: 1;
}

.medicine-list,
.prescription-list {
  flex: 1;
  max-height: 50vh;
  min-height: 50vh;
  /* Fixed height */
  overflow-y: auto;
  /* Scrollable when items exceed height */
  padding: 5px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

.medicine-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
}

.medicine-item:hover {
  background-color: #e9e9e9;
}

.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.modal-content .btn {
  cursor: auto;
}

.custom-badge {
  background-color: #708090;
  color: white;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}

button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  opacity: 0.6;
}

.edit-icon {
  cursor: pointer;
  color: #007bff;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}

.frequency-buttons button {
  border: 1px solid #007bff;
  background: white;
  color: #007bff;
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.frequency-buttons button.active {
  background-color: green;
  color: white;
}

.meal-toggle span {
  cursor: pointer;
  color: #007bff;
  margin-right: 10px;
}

.meal-toggle span.active {
  font-weight: bold;
  color: green;
}

textarea {
  width: 100%;
  padding: 5px;
  margin-top: 10px;
}

.note-preview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #333;
  font-size: 14px;
}

.patient-description-section {
  margin-top: none;
  margin-bottom: 5px;
  padding: 10px;
  background-color: #f4f4f4;
}

.patient-description-textarea {
  width: 100%;
  height: 50px;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
  resize: none;
  margin-top: 1px;
  margin-bottom: 2px;
  color: black;
}

.note-textarea {
  resize: none;
}

.delete {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: red;
  color: white;
  border: none;
  padding: 5px;
  font-size: 12px;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.search-bar input {
  flex: 1;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.search-bar i {
  margin-left: 5px;
  color: #888;
}

.medicine-list-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.prescription-list-container {
  display: flex;
  color: #000;
  flex-direction: column;
  width: 50%;
}

.duration-input {
  width: 35px;
}

.token {
  background-color: #8ce4f3;
  color: #0c91ce;
  border: none;
  border-radius: 20px;
  padding: 5px;
  font-size: 13px;
}

.checked-in-box {
  background-color: #e6f5e9;
  border: 1px solid #28a745;
  border-radius: 8px;
  margin-top: 5px;
  padding: 1px 1px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.token-number {
  margin-top: 1px;
  font-size: 13px;
  color: #000000;
  padding: 1px 1px;
}

.token-number b {
  color: #000;
}

/* pdf and excel icon */
.export-icon {
  width: 30px;
  height: 30px;
}

/* swirl alert close button css*/
.swal-popup-custom {
  position: relative;
}

.swal-close-button-custom {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #d33;
}

.badge-lg {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.check-in-container {
  margin-left: 57px;
  margin-top: 5px;
}

.checkinmodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.checkinmodal-content {
  background-color: #fff;
  width: 42%;
  max-width: 1500px;
  padding: 15px;
  max-height: 57vh;
  min-height: 57vh;
  /* width: 100%;
  height: 100%; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(71, 70, 70, 0.2);
  overflow: hidden;
}

@media (max-width: 768px) {
  .checkinmodal-content {
    width: 90%;
    max-width: 600px;
    min-height: auto;
    max-height: 80vh;
    overflow-y: auto;
  }
}

.checkinmodal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.checkinmodal-footer {
  margin-top: 1px;
  display: flex;
  justify-content: flex-end;
}

button {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}

.main-btn {
  background-color: #0e82fd;
  border: 1px solid;
  border-radius: 4px;
  color: #fff;
  padding: 2px 7px;
  margin-right: 5px;
  margin-left: 1px;

  font-size: 15px;
}
.main-btn:hover {
  background-color: #ffffff;
  border: 1px solid #0e82fd;
  color: #0e82fd;
}
.add-appointment-btn {
  background-color: #0e82fd;
  color: #ffffff;
  padding: 5px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 15px;
}

.add-appointment-btn:hover {
  background-color: #ffffff;
  color: #0e82fd;
}

button:disabled {
  background-color: #cccccc;
  color: rgb(75, 75, 75);
  cursor: not-allowed;
  opacity: 0.6;
}

.confirm-btn {
  background-color: rgb(0, 199, 90);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}

.back-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}

.checked-in-box {
  background-color: #e6f5e9;
  border: 1px solid #28a745;
  border-radius: 8px;
  padding: 3px 2px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.checked-in-label {
  font-size: 14px;
  background-color: rgb(0, 199, 90);
  display: flex;
  border-radius: 10px;
  color: white;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.checked-in-label i {
  margin-right: 5px;
}

.token-number {
  margin-top: 1px;
  font-size: 13px;
  color: #333;
  padding: 1px 1px;
}

.token-number b {
  color: #000;
}

.nav-link {
  background-color: #d3d9df;
  border-color: #ced4da;
  transition: background-color 0.3s;
  margin: 5px 0;
}

.nav-link:hover {
  background-color: #0056b3;
}

.modal-content .btn {
  cursor: auto;
}

.custom-badge {
  background-color: #708090;
  color: white;
}

.export-icon {
  width: 40px;
  height: 40px;
}

.left-align {
  text-align: left;
  font-size: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.align-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-align {
  text-align: right;
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
}
</style>