<template>
  <div role="tabpanel" id="doc_overview" class="tab-pane fade show active" v-if="doctor">
    <div class="row responsive-margin" style="margin-top: 30px">
      <div class="col-md-12 col-lg-12">
        <div class="widget about-widget">
          <!-- <h4 class="widget-title">About Me</h4> -->
          <p>{{ doctor.description }}</p>
        </div>
        <div class="widget about-widget">
          <h4 class="widget-title">Education</h4>
          <p>{{ doctor.qualifications }}</p>
        </div>
        <div class="widget about-widget">
          <h4 class="widget-title">Experience</h4>
          <p>{{ doctor.experience }}</p>
        </div>
        <div class="widget about-widget">
          <h4 class="widget-title">Specialization</h4>
          <p>{{ doctor.specialization }}</p>
        </div>
        <div class="widget about-widget">
          <h4 class="widget-title">Languages Spoken</h4>
          <p>{{ doctor.languagesspoken }}</p>
        </div>
        <div class="widget about-widget">
          <h4 class="widget-title">URL</h4>
          <p style="display: inline; margin-right: 8px;" @click="shareUrlx(doctor.weburl)">{{ doctor.weburl }}</p>
          <i class="fas fa-share" @click="shareUrlx(doctor.weburl)" style="font-size: 1.5em; color: grey;"></i>
        </div>
        <div class="widget about-widget">
          <h4 class="widget-title">Social Media</h4>
          <p>
            <a v-if="doctor.facebookurl" :href="doctor.facebookurl" target="_blank" rel="noopener noreferrer">
              <img class="hover-effect" src="@/assets/img/Social-media/facebook.png" alt="Facebook"
                style="width: 20px; height: 20px; margin-right: 20px;" />
            </a>

            <a v-if="doctor.twitterurl" :href="doctor.twitterurl" target="_blank" rel="noopener noreferrer">
              <img class="hover-effect" src="@/assets/img/Social-media/twitter.png" alt="Twitter"
                style="width: 20px; height: 20px; margin-right: 20px;" />
            </a>

            <a v-if="doctor.linkedinurl" :href="doctor.linkedinurl" target="_blank" rel="noopener noreferrer">
              <img class="hover-effect" src="@/assets/img/Social-media/linkedin.png" alt="LinkedIn"
                style="width: 20px; height: 20px; margin-right: 20px;" />
            </a>

            <a v-if="doctor.youtubeurl" :href="doctor.youtubeurl" target="_blank" rel="noopener noreferrer">
              <img class="hover-effect" src="@/assets/img/Social-media/youtube.png" alt="YouTube"
                style="width: 20px; height: 20px; margin-right: 20px;" />
            </a>

            <a v-if="doctor.instagramurl" :href="doctor.instagramurl" target="_blank" rel="noopener noreferrer">
              <img class="hover-effect" src="@/assets/img/Social-media/instagram.png" alt="Instagram"
                style="width: 20px; height: 20px; margin-right: 20px;" />
            </a>

            <a v-if="doctor.pintresturl" :href="doctor.pintresturl" target="_blank" rel="noopener noreferrer">
              <img class="hover-effect" src="@/assets/img/Social-media/pinterest.png" alt="Pinterest"
                style="width: 20px; height: 20px;" />
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    doctor: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      showShareModal: false,
    };
  },
  methods: {
    shareUrlx(url) {
      if (navigator.share) {
        navigator.share({
          title: 'Doctor profile of ' + this.doctor.doctorname,
          url: url
        }).then(() => {
          console.log('Successfully shared');
        }).catch((error) => {
          console.log('Error sharing', error);
        });
      } else {
        this.showShareModal = !this.showShareModal;
      }
    },
    formatDescription(description) {
      return description.replace(/\n/g, '<br>');
    },
    share(platform) {
      let url = this.doctor.weburl;
      let encodedUrl = encodeURIComponent(url);
      let shareUrl = '';

      switch (platform) {
        case 'whatsapp':
          shareUrl = `https://wa.me/?text=${encodedUrl}`;
          break;
        case 'facebook':
          shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
          break;
        case 'twitter':
          shareUrl = `https://twitter.com/intent/tweet?text=${encodedUrl}`;
          break;
        case 'linkedin':
          shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
          break;
        case 'telegram':
          shareUrl = `https://t.me/share/url?url=${encodedUrl}`;
          break;
      }
      window.open(shareUrl, '_blank');
      this.showShareModal = false;
    },
  },
};
</script>

<style scoped>
.hover-effect {
  transition: transform 0.3s ease;
}

.hover-effect:hover {
  transform: scale(1.2);
}

.responsive-margin {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .responsive-margin {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.about-widget p {
  color: grey;
}

.timeline-content .name {
  color: grey;
}
</style>