<script setup>
  import { toRefs } from 'vue';
  import { Authenticator, useAuthenticator } from '@aws-amplify/ui-vue';
  // `route` `user` and `signOut` all are reactive.
  const { route, user, signOut } = toRefs(useAuthenticator());
</script>
<template>
  <!-- Header -->
  <header class="header header-fixed" v-if="isHomeOneRoute" :class="{ 'header-one': true, 'header-space': isScrolled }">
    <div class="container">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/referee-dashboard" class="navbar-brand logo">
            <img src="@/assets/img/logo.png" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/logo.png" class="img-fluid" alt="Logo" />
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);" @click="closeSidebar">
              <i class="fas fa-times"></i>
            </a>
          </div>
          <mainnav :user="user"></mainnav>
        </div>
        <ul class="nav header-navbar-rht" v-if="isHomeRefereeRoute && user && usertype === 'Referee'">
          

            <!-- Notifications -->
            <!-- <li class="nav-item dropdown noti-nav me-3 pe-0">
              <a href="javascript:;" class="dropdown-toggle nav-link p-0" data-bs-toggle="dropdown">
                <i class="fa-solid fa-bell"></i> <span class="badge" v-if="notificationCount">{{ notificationCount }}</span>
              </a>
              <div class="dropdown-menu notifications dropdown-menu-end">
                <div class="topnav-dropdown-header">
                  <span class="notification-title">Notifications</span>
                </div>
                <div class="noti-content">
                  <ul class="notification-list" v-if="notifications">
                    <li v-for="(notification, index) in notifications" :key="index" class="notification-message">
                      <a href="javascript:;">
                        <div class="notify-block d-flex">
                          <div class="media-body">
                            <h6>
                              {{ notification.title }} <span class="notification-time">{{ notification.time }}</span>
                            </h6>
                            <p class="noti-details">{{ notification.description }}</p>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li> -->
            <!-- /Notifications -->



          <!-- User Menu -->
          <li class="nav-item dropdown has-arrow logged-item">
            <a href="javacript:;" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
              <span class="user-img">
                <img class="rounded-circle" src="@/assets/img/patients/patient.jpg" width="31" alt="{{given_name}}" />
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <div class="user-header">
                <div class="avatar avatar-sm">
                  <img src="@/assets/img/patients/patient.jpg" alt="User Image" class="avatar-img rounded-circle" />
                </div>
                <div class="user-text">
                  <h6>{{ given_name }}</h6>
                  
                </div>
              </div>
              <router-link class="dropdown-item" to="/clinic-dashboard">Dashboard</router-link>
              <!--router-link class="dropdown-item" to="/profile-settings">Profile Settings</router-link-->
              <div class="dropdown-item" @click="signOut">Logout</div>
            </div>
          </li>
          <!-- /User Menu -->
        </ul>
      </nav>
      <div class="sidebar-overlay" :class="{ opened: isSidebarOpen }" @click="closeSidebar"></div>
    </div>
  </header>
  <header class="header" v-else-if="isHomePharmacyRoute">
    <div class="container">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/" class="navbar-brand logo">
            <img src="@/assets/img/logo.png" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/logo.png" class="img-fluid" alt="Logo" />
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);" @click="closeSidebar">
              <i class="fas fa-times"></i>
            </a>
          </div>
          <mainnav></mainnav>
        </div>
        <ul class="nav header-navbar-rht">
          <li class="nav-item contact-item">
            <div class="header-contact-img">
              <i class="far fa-hospital"></i>
            </div>
            <div class="header-contact-detail">
              <p class="contact-header">Contact</p>
              <p class="contact-info-header">+1 315 369 5943</p>
            </div>
          </li>
          <li class="nav-item">
            <router-link class="nav-link header-login" to="login">login / Signup
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="sidebar-overlay" :class="{ opened: isSidebarOpen }" @click="closeSidebar"></div>
    </div>
  </header>
  <header class="header header-fixed" v-else v-bind:class="$route.meta.headerClass">
    <div class="container">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/" class="navbar-brand logo" v-if="isHomeFive1Route">
            <img src="@/assets/img/logo-white.svg" class="img-fluid" alt="Logo" />
          </router-link>
          <router-link to="/" class="navbar-brand logo" v-else>
            <img src="@/assets/img/logo.png" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/logo.png" class="img-fluid" alt="Logo" />
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);" @click="closeSidebar">
              <i class="fas fa-times"></i>
            </a>
          </div>
          <mainnav></mainnav>
        </div>
      </nav>
      <div class="sidebar-overlay" :class="{ opened: isSidebarOpen }" @click="closeSidebar"></div>
    </div>
  </header>
  <!-- /Header -->
</template>
<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { fetchAuthSession, fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth';
const notifications = ref([]);


export default {
  data() {
    return {
      // isHomeSeven1Route: false,
      isScrolled: false,
      isSidebarOpen: false,
      isVisible: false,
      email: sessionStorage.getItem("useremail"),
      family_name: '',
      gender: '',
      given_name: '',//sessionStorage.getItem("usergivenname"),
      usertype: sessionStorage.getItem('usertype'),
      phone_number: '',
      idpid: '',
      patientid: sessionStorage.getItem('userdbid'),
    };
  },
  
  
  

  mounted() {
    // Add a scroll event listener when the component is mounted
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeMount() {
    this.currentAppUser();
  },
  beforeUnmount() {
    // Remove the scroll event listener when the component is about to be unmounted
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async getPatientDetails(dbid) {
      try {
        //console.log('Fetching Patient with dbid:', dbid);
        const response = await axios.get(`/patients/${dbid}`);
        const patient = response.data;
        this.given_name = patient.firstname;
        this.email = patient.email;
        this.phone_number = patient.contactnumbers;
        this.family_name = patient.lastname;
        this.gender = patient.gender;
      } catch (error) {
        console.error('Error fetching db ID:', error);
      }
    },
     async fetchRefereeDetails() {
      const refereeId = sessionStorage.getItem("userdbid");
      //console.log("doctor id is ", doctorId);
      try {
        let refDetails = sessionStorage.getItem(`refereeDetails${refereeId}`);
        if (!refDetails) {
          const response = await axios.get(`/referee/${refereeId}`);
          refDetails = response.data;
          sessionStorage.setItem("loginusername", refDetails.fullname);
          sessionStorage.setItem(
            `refereeDetails${this.refereeId}`,
            JSON.stringify(refDetails)
          );
        } else {
          refDetails = JSON.parse(refDetails);
        }
        this.refereeDetails = refDetails;
        this.given_name = refDetails.fullname;
        this.email = refDetails.email;
        this.phone_number = refDetails.contactnumber;
        // this.family_name = patient.lastname;
        // this.gender = patient.gender;
        //console.log("Doctor Details:", this.doctorDetails);
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    },
    async fetchRefereeDBId(idpid) {
      try {
        //console.log('Fetching db ID with idpid:', idpid);
        const response = await axios.get(`/auth/identifier/${idpid}`);

      //  console.log('Response from get_patient_id API:', response);

        if (response.data && response.data.dbid) {
          const dbid = response.data.dbid;
          sessionStorage.setItem('userdbid', dbid);
          this.usertype = response.data.profile;
          sessionStorage.setItem('usertype', this.usertype);
          if (this.usertype === "Referee") {
            this.fetchRefereeDetails(dbid);
          } else {
            return null;
          }
          return dbid;
        } else {
          console.error('DB ID not found in the response.');
        }

      } catch (error) {
        console.error('Error fetching db ID:', error);
      }
      return null;
    },
    async currentAppUser() {
      let dbid = sessionStorage.getItem('userdbid');
      let idpid = sessionStorage.getItem('useridpid')
      //console.log(`The db is is ${dbid}`);
      if (!dbid) {
        //console.log("No db user id found");
        try {
          const session = await fetchAuthSession();
          store.commit('setAuthUser', session);
          const userSub = session.userSub;
          //console.log(`The userId: ${userSub}`);
          sessionStorage.setItem("useridpid", userSub);
          const dbid = await this.fetchRefereeDBId(userSub);
          // this.fetchNotifications();
          return true;
        } catch (err) {
          console.log("error fetching db user id", err);
          return false;
        }
      } else {
        if (!this.isUserLoggedIn()) {
          sessionStorage.clear("useridpid");
          store.commit('setAuthUser', null);
          return false;
        } else {
          const username = sessionStorage.getItem("loginusername");
          if(!username){
            this.fetchRefereeDBId(idpid)
          }else{
            this.given_name = username;
          }
          // this.fetchNotifications();
          return true;
        }
      }
    },
     async isUserLoggedIn() {
      try {
          await currentAuthenticatedUser();
          return true;
      } catch {
          return false;
      }
    },
    // async currentAuthenticatedUser() {
    //   let dbid = sessionStorage.getItem('userdbid');
    //   if (!dbid) {
    //     try {
    //       const session = await fetchAuthSession();
    //       store.commit('setAuthUser', session);
    //       const userSub = session.userSub;
    //       //console.log(`The userId: ${userSub}`);
    //       sessionStorage.setItem("useridpid", userSub);
    //       const dbid = await this.fetchRefereeDBId(userSub);
    //       return true;
    //     } catch (err) {
          
    //       return false;
    //     }
    //   } else {
    //     return true;
    //   }
    // },
    submitForm() {
      this.$router.push("/doctor-search-list");
    },
    handleScroll() {
      // Get the scroll position
      const scroll = window.scrollY;

      // Update the isScrolled data property based on the scroll position
      this.isScrolled = scroll > 35;
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      document.documentElement.classList.toggle("menu-opened");
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      document.documentElement.classList.remove("menu-opened");
    },
    toggleElement() {
      this.isVisible = !this.isVisible;
    },
    signOut() {
      Auth.signOut()
      .then(data => {
        this.$router.push("/");
      }).catch(err => console.log(err))
    },
    
  // Function to fetch notifications from the API
 
  },
  computed: {
    getNotificationsCount()  {
      return Array.isArray(notifications.value) ? notifications.value.length : 0;
    },

    isHomeOneRoute() {
      return (
        this.$route.path === "/referee-dashboard" ||
        this.$route.path === "/referee-sidebar" ||
        this.$route.path === "/referee-change-password"||
        this.$route.path === "/my-referrals"||
        this.$route.path === "/referee-login"||
        this.$route.path === "/referee-add-doctor"
      );
    },
    isHomeRefereeRoute() {
      return (
        this.$route.path === "/referee-dashboard" ||
        this.$route.path === "/referee-sidebar" ||
        this.$route.path === "/referee-change-password"||
        this.$route.path === "/my-referrals"||
        this.$route.path === "/referee-add-doctor"
      );
    },
   isHomeFiveRoute() {
      return this.$route.path === "/index-5";
    },
    isHomeFive1Route() {
      return this.$route.path === "/index-5";
    },
    isHomeSevenRoute() {
      return this.$route.path === "/index-7" || this.$route.path === "/index-8";
    },
    isHomeSeven1RouteComputed() {
      return this.$route.path === "/index-7";
    },
    isHomeSeven2Route() {
      return (
        this.$route.path === "/index-7" ||
        this.$route.path === "/index-8" ||
        this.$route.path === "/index-9" ||
        this.$route.path === "/index-10"
      );
    },
    isHomeEightRoute() {
      return this.$route.path === "/index-8";
    },
    isHomeNineRoute() {
      return this.$route.path === "/index-9";
    },
    isHomeNine1Route() {
      return this.$route.path === "/index-9";
    },
    isHomeTenRoute() {
      return this.$route.path === "/index-10" || this.$route.path === "/index-11";
    },
    isHomeTen1Route() {
      return this.$route.path === "/index-10";
    },
    isHomeElevenRoute() {
      return this.$route.path === "/index-11";
    },
  },

};

</script>

<style scoped>
.navbar .nav-link:hover {
  color: inherit;
  background-color: transparent !important; 
}
</style>
