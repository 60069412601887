<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <referee-header></referee-header>
    <breadcrumb-referee :title="title" :text="text" :text1="text1" />
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <referee-sidebar></referee-sidebar>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <!-- Dashboard Section -->
            <div class="row dashboard-section">
              <!-- Left Column: Counts -->
              <div class="col-md-4">
                <div class="card dash-card mb-3">
                  <div class="card-body">
                    <div class="dash-widget">
                      <div class="circle-bar circle-bar1">
                        <div class="circle-graph6">
                          <circle-progress :percent="75" />
                          <img
                            src="@/assets/img/icon-04.png"
                            class="img-fluid"
                            alt="Total Referred Doctors"
                          />
                        </div>
                      </div>
                      <div class="dash-widget-info">
                        <h6>Total Referred Doctors</h6>
                        <h3>{{ dashboardData.total_records }}</h3>
                        <p class="text-muted">Till Today</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card dash-card mb-3">
                  <div class="card-body">
                    <div class="dash-widget">
                      <div class="circle-bar circle-bar2">
                        <div class="circle-graph6">
                          <circle-progress :percent="65" />
                          <img
                            src="@/assets/img/icon-03.png"
                            class="img-fluid"
                            alt="Appointment Count"
                          />
                        </div>
                      </div>
                      <div class="dash-widget-info">
                        <h6>Appointment Count</h6>
                        <h3>{{ dashboardData.total_cur_month_appointments }}</h3>
                        <p class="text-muted">Current Month</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card dash-card mb-3">
                  <div class="card-body">
                    <div class="dash-widget">
                      <div class="circle-bar circle-bar3">
                        <div class="circle-graph6">
                          <circle-progress :percent="80" />
                          <img
                            src="@/assets/img/icon-03.png"
                            class="img-fluid"
                            alt="Revenue"
                          />
                        </div>
                      </div>
                      <div class="dash-widget-info">
                        <h6>Appointment Count</h6>
                        <h3>{{ dashboardData.total_prev_month_appointments }}</h3>
                        <p class="text-muted">Prev Month</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Right Column: Referrals -->
              <div class="col-md-8">
                <div class="card">
                  <div class="card-header">
                    <h4>Recent Referrals</h4>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-hover table-center mb-0">
                        <thead>
                          <tr>
                            <th>Doctor Name</th>
                            <th>Joining Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in recentReferrals" :key="item.doctorid">
                            
                            <td>
                              <h2 class="table-avatar">
                                <div  class="avatar avatar-sm me-2">
                                  <img
                                    class="avatar-img rounded-circle"
                                    :src="//item.doctor_image ? require('@/assets/img/doctors/'+item.doctor_image) : 
                                    require('@/assets/img/doctors/doctor-thumb-01.jpg')"
                                    alt="Doctor Image"
                                  />
                                </div>
                                <div >
                                Dr. {{ item.doctorname }}
                                  <span>{{ item.specialization }}</span>
                                </div>
                              </h2>
                            </td>
                            <td>{{ formatDate(item.joiningdate) }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Dashboard Section -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <referee-footer></referee-footer>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import CircleProgress from "vue3-circle-progress";
import "vue3-circle-progress/dist/circle-progress.css";
import { ref } from "vue";
import axios from "axios";

export default {
  components: {
    CircleProgress,
  },
  data() {
    return {
      title: "Dashboard",
      text: "Home",
      text1: "Dashboard",
      dashboardData: [],
      recentReferrals: [],
      refereeid: "",
    };
  },
  methods: {
    async fetchDashBoard() {
      this.refereeid = sessionStorage.getItem("userdbid");
      try {
        const response = await axios.get(`referee/${this.refereeid}/dashboard`);
        this.dashboardData = response.data;
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    },
    async fetchReferrals() {
      const RefereeId = sessionStorage.getItem("userdbid");
      try {
        const response = await axios.get(`referee/${RefereeId}/recent-doctor`);
        this.recentReferrals = response.data;
      } catch (error) {
        console.error("Error fetching referrals:", error);
      }
    },
    formatDate(dateTimeString) {
      const date = new Date(dateTimeString);
      const options = { day: "numeric", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    },
  },
  beforeMount() {
    this.fetchDashBoard();
    this.fetchReferrals();
  },
  name: "referee-dashboard",
};
</script>

<style scoped>
.content {
  padding: 40px 0 60px;
}
.card-header h4 {
  margin: 0;
  font-size: 18px;
}
.dash-card {
  margin-bottom: 20px;
}
.circle-bar {
  position: relative;
}
.circle-bar img {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}
.table-responsive {
  max-height: 300px;
  overflow-y: auto;
}

.dashboard-section {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .dashboard-section {
    flex-direction: column; 
  }
  .dashboard-section .col-md-4,
  .dashboard-section .col-md-8 {
    max-width: 100%; /* Ensure columns take full width */
  }
}
</style>
