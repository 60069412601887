<template>
  <div class="main-wrapper">
    <layoutheader v-if="!isModalOpen"></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />
    <div class="page-wrapper">
      <div class="content">
        <div class="container">
          <div class="row">
            <!-- Profile Sidebar -->
            <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <div class="stickysidebar">
                <profilesidebar></profilesidebar>
              </div>
            </div>
            <!-- / Profile Sidebar -->
            <div class="col-md-7 col-lg-8 col-xl-9">
              <div class="card">
                <div class="card-body pt-0">
                  <!-- Tab Menu -->
                  <nav class="user-tabs mb-4">
                    <div class="col-sm-6" style="padding-top:10px">
                      <h3 class="card-title">Appointments</h3>
                    </div>
                    <!-- Appointment Tab -->
                    <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#pat_appointments"
                          :class="{ active: activeTab === 'Upcoming' }"
                          @click="setActiveTab('Upcoming')"
                          data-bs-toggle="tab"
                        >Upcoming</a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#pat_prescriptions"
                          :class="{ active: activeTab === 'Past' }"
                          @click="setActiveTab('Past')"
                          data-bs-toggle="tab"
                        >Past</a>
                      </li>
                    </ul>
                    <!-- /Appointment Tab -->
                  </nav>
                  <!-- /Tab Menu -->

                  <!-- Tab Content -->
                  <div class="tab-content pt-0">
                    <b-alert v-model="showAlertx" variant="warning" class="mt-2" dismissible @dismissed="handleDismiss">Refresh to load the latest appointments.
        <span class="text-primary font-weight-bold" role="button" @click="refreshAppointments" style="cursor: pointer;">
          Refresh
        </span></b-alert>
                    <!-- Appointment Tab -->
                    <patientappointment
                      v-bind:isModalOpen="isModalOpen"
                      @openModal="openModal"
                      @closeModal="closeModal"
                      :futureAppointments="futureAppointments"
                      :fetchAppointments="fetchAppointments"
                      @add-medical-records="handleAddMedicalRecords"
                      @view-medical-records="handleViewMedicalRecords"
                      @fetchAppointments="fetchAppointments"
                    ></patientappointment>
                    <!-- /Appointment Tab -->

                    <!-- Past Appointment Tab -->
                    <prescription
                      v-bind:isModalOpen="isModalOpen"
                      @openModal="openModal"
                      @closeModal="closeModal"
                      :pastAppointments="pastAppointments"
                      @add-medical-records="handleAddMedicalRecords"
                      @view-medical-records="handleViewMedicalRecords"
                    ></prescription>
                    <!-- /Past Appointment Tab -->
                  </div>
                  <!-- Tab Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <indexfooter></indexfooter> -->
       <patientfooter></patientfooter>
    </div>
  </div>
  <!-- Add medical Records -->
  <patient-medical-record
    :appointmentId="selectedAppointmentId"
    :patientId="selectedPatientId"
    :doctorId="selectedDoctorId"
    :doctor_name="selectedDoctor_name"
    @records-added="onRecordsAdded"
  ></patient-medical-record>
  <!-- /Add medical Records -->

  <!-- view medical records -->
  <view-medical-records
    :patientId="recordsPatientId"
    :appointmentId="recordsAppointmentId"
    ref="viewMedicalRecords"
  ></view-medical-records>
  <!-- view medical records -->
  <graphone></graphone>
  <graphtwo></graphtwo>
  <graphthree></graphthree>
  <graphfour></graphfour>
</template>

<script>
import axios from "axios";
import graphstatus from "@/assets/json/patientdashboard/graphstatus.json";
import patient from "@/assets/json/patientdashboard/patient.json";
export default {
  data() {
    return {
      showAlertx: false, 
      alertInterval: null,
      title: "Dashboard",
      text: "Home",
      text1: "Dashboard",
      graphstatus: graphstatus,
      patient: patient,
      idpid: "",
      patientid: "",
      futureAppointments: [],
      pastAppointments: [],
      selectedAppointmentId: null,
      selectedDoctorId: null,
      selectedDoctor_name: "",
      selectedPatientId: null,
      recordsPatientId: null,
      isModalOpen: false,
      recordsAppointmentId: null,
      activeTab: "Upcoming"
    };
  },
  methods: {
    handleDismiss() {
      this.resetAlertInterval(); 
      this.showAlertx = false;
    },
    refreshAppointments() {
      this.fetchAppointments();
      this.showAlertx = false;
      this.resetAlertInterval();
      this.startAlertTimer();
    },
    startAlertTimer() {
      this.alertInterval = setInterval(() => {
        this.showAlertx = true;
      }, 300000);
      // 300000
    },
    resetAlertInterval() {
      if (this.alertInterval) {
        clearInterval(this.alertInterval); 
      }
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleAddMedicalRecords({ appointmentId, patientId, doctorId, doctor_name }) {
      this.selectedAppointmentId = appointmentId;
      this.selectedPatientId = patientId;
      this.selectedDoctorId = doctorId;
      this.selectedDoctor_name = doctor_name;
    },
    handleViewMedicalRecords({ patientId, appointmentId }) {
      this.recordsPatientId = patientId;
      this.recordsAppointmentId = appointmentId;
      //console.log("this is recordid",this.recordsPatientId,this.recordsAppointmentId);
    },
    onRecordsAdded() {
      this.recordsPatientId = this.selectedPatientId;
      this.recordsAppointmentId = this.selectedAppointmentId;
      // Fetch the updated list of medical records
      this.$refs.viewMedicalRecords.fetchMedicalRecords();
    },
    async fetchAppointments() {
      this.patientid = sessionStorage.getItem("userdbid");
      try {
        const response = await axios.get(
          `/patients/${this.patientid}/appointments`
        );

        //console.log("Response from get_patient_appointments API:", response);

        if (response.data && response.data.future && response.data.past) {
          this.futureAppointments = response.data.future;
          this.pastAppointments = response.data.past;

          /*const past_promises = this.pastAppointments.map(
            async (appointment, index) => {
              const prescrip_data = await this.checkPrescription(appointment);
              if (prescrip_data) {
                this.pastAppointments[index].prescripid =
                  prescrip_data.prescripId;
                this.pastAppointments[index].prescripNote =
                  prescrip_data.prescripNote;
              }
            }
          );
          // const future_promises = this.futureAppointments.map(
          //   async (appointment, index) => {
          //     const tokenExists = await this.checkToken(
          //       appointment.appointment_id,
          //       appointment.patientid
          //     );
          //     if (tokenExists) {
          //       appointment.tokenNumber = tokenExists;
          //     } else {
          //       appointment.tokenNumber = null;
          //     }
          //   }
          // );
          await Promise.all(past_promises);*/
        } else {
          console.error("Appointments not found.");
        }
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    },
    /*async checkToken(appointmentid, patientid) {
      try {
        const response = await axios.get(
          `/patients/${patientid}/appointments/${appointmentid}/tokens`
        );
        if (response.data && response.data.length > 0) {
          //console.log("chectoken:", response.data[0].tokenid);
          return response.data[0].tokenid;
        }
      } catch (error) {
        //console.log(error);
      }
    },*/
    /*async checkPrescription(appointment) {
      try {
        // API call to check if the prescription exists for the given appointment
        const response = await axios.get(
          `/patients/1/appointments/${appointment.appointment_id}/prescriptions`
        );
        //console.log(`Prescription check for appointment ${appointment.appointment_id}:`,response.data);
        if (response.data && response.data.length > 0) {
          // Return the first prescription's ID if any prescriptions exist
          return {
            prescripId: response.data[0].prescripid,
            prescripNote: response.data[0].note
          };
        } else {
          return null; // No prescriptions found
        }
      } catch (error) {
       return null;
      }
    }*/
  },
  beforeDestroy() {
    this.resetAlertInterval();
  },
  mounted() {
    this.fetchAppointments();
    this.startAlertTimer();
  }
};
</script>
