<template>
  <div role="tabpanel" id="doc_locations" class="tab-pane fade">
    <!-- Location List -->
    <div class="responsive-margin" style="margin-top: 40px">
      <div class="row" v-for="clinic in doctorclinic" :key="clinic.clinicid">
        <!-- Clinic Content -->
        <div class="col-md-6">
          <div class="clinic-content">
            <h4 class="clinic-name">
              <a href="javascript:;">{{ clinic.clinicname }}</a>
            </h4>
            <p class="doc-speciality">{{ clinic.clincid }}</p>
            <div class="clinic-details mb-0">
              <h5 class="clinic-direction">
                <!-- <i class="fas fa-map-marker-alt"></i> -->
                {{ clinic.clinicaddress }}
                <br />
                <a target="_blank"
                  :href="'https://www.google.com/maps/search/?api=1&ll=12.9141%2C74.8560&query_place_id=&z=13&query=' + clinic.clinicname">Get
                  Directions</a>
              </h5>
            </div>
          </div>
        </div>
        <!-- /Clinic Content -->

        <!-- Clinic Timing -->
        <div class="col-md-6 d-md-flex justify-content-md-end">
          <div class="clinic-timing text-right-align">
            <template v-for="day in ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']" :key="day">
              <div>
                <p class="timings">
                  <span class="day bold">{{ day }}: </span>
                  <span class="time">{{ getTimings(fullDayName(day), clinic.clinicid) }}</span>
                </p>
              </div>
            </template>
          </div>
        </div>
        <!-- /Clinic Timing -->
        <hr style="margin-top: 10px; margin-bottom: 30px; border: 0; border-top: 1px solid #bbb;" />
      </div>
    </div>
    <!-- /Location List -->

    <vue-easy-lightbox :visible="visible" :index="index" :imgs="galleryImages.map((image) => ({
      src: require(`@/assets/img/features/${image.src}`),
    }))" @hide="visible = false" @on-prev="handlePrev" @on-next="handleNext"></vue-easy-lightbox>
  </div>
</template>
<script>
import VueEasyLightbox from "vue-easy-lightbox";
import axios from "axios";
export default {
  props: {
    doctor: {
      type: Object,
      default: {}
    },
    doctortimmings: {
      type: Array,
    },
    doctorclinic: {
      type: Array,
    }
  },
  components: {
    VueEasyLightbox
  },
  data() {
    return {
      visible: false,
      index: 0,
      doctoridx: 0,
      galleryImages: [
        {
          src: "feature-01.jpg"
        },
        {
          src: "feature-02.jpg"
        },
        {
          src: "feature-03.jpg"
        },
        {
          src: "feature-04.jpg"
        }
      ]
    };
  },

  methods: {
    fullDayName(abbreviatedDay) {
      const daysMap = {
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        Sunday: "Sunday"
      };
      return daysMap[abbreviatedDay];
    },

    hasTimings(day, clinicid) {
      return this.doctortimmings.some(timing => timing.weekday === day && timing.clinicid === clinicid);
    },
    getTimings(day, clinicid) {
      const timings = this.doctortimmings.filter(
        timing => timing.weekday === day && timing.clinicid === clinicid
      );
      if (timings.length === 0) {
        return "NA";
      }
      return timings
        .map(timing => {
          const startTime = this.convertToAMPM(timing.starttime);
          const endTime = this.convertToAMPM(timing.endtime);
          return `${startTime} - ${endTime}`;
        })
        .join(", ");
    },
    convertToAMPM(timeString) {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      const ampm = hours >= 12 ? "PM" : "AM";
      const hour12 = hours % 12 || 12;
      return `${hour12}:${minutes.toString().padStart(2, "0")} ${ampm}`;
    },
    show(index) {
      this.index = index;
      this.visible = true;
    },
    handlePrev(oldIndex, newIndex) {
      // console.log("when prev btn click or user swipe right ----");
      // console.log("oldIndex of imgs:", oldIndex);
      // console.log("newIndex of imgs:", newIndex);
    },
    handleNext(oldIndex, newIndex) {
      // console.log("when next btn click or user swipe left ----");
      // console.log("oldIndex of imgs:", oldIndex);
      // console.log("newIndex of imgs:", newIndex);
    },

  },

};
</script>
<style scoped>

.day.bold {
  font-weight: bold;
  color: grey;
}

.time {
  color: grey;
}

.text-right-align {
  text-align: left;
}

@media (min-width: 768px) {
  .text-right-align {
    text-align: right; 
  }
}

.responsive-margin {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .responsive-margin {
    margin-left: 30px;
    margin-right: 30px;
  }
}
</style>