<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <clinicheader></clinicheader>
        <breadcrumb-doctor :title="title" :text="text" :text1="text1" />
        <!-- Page Content -->
        <div class="content">
            <div class="container">
                <div class="row">
                    <doctorsidebar2></doctorsidebar2>

                    <div class="col-md-7 col-lg-8 col-xl-9">


                        <div class="row">
                            <div class="col-md-12">
                                <div class="appointment-tab">
                                    <!-- Appointment Tab -->

                                    <!-- <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
                      <h3 style="margin-right:auto; margin-left:8px">REPORT</h3>
  
                    </ul> -->
                                    <!-- /Appointment Tab -->
                                    <div class="date-range-selection-wrapper">
                                        <div class="date-range-selection">
                                            <!-- <i class="fa-solid fa-calendar-days calendar-icon"></i> -->
                                            <div class="daterange-container">

                                                <div id="daterange" @click="fetchAllAppointments"
                                                    class="daterange-input">
                                                    {{ startdatex }}
                                                </div>
                                                <span class="daterange-to">to</span>
                                                <div id="daterangeok" @click="fetchAllAppointments"
                                                    class="daterange-input">
                                                    {{ enddatex }}
                                                </div>
                                            </div>
                                            <div class="dropdown-container" style="margin-left: 10px;">
                                                <div class="custom-select-wrapper" v-if="usertype === 'Doctor'">
                                                    <select v-model="selectedClinic" class="custom-select-modernx"
                                                        @change="clinicframechange()">
                                                        <!-- <option value="AL">All</option> -->
                                                        <option v-for="clinic in clinics" :key="clinic.id"
                                                            :value="clinic.id">{{ clinic.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <!-- <div class="custom-select-wrapper">
                            <select v-model="selectedYear" class="custom-select-modern" @change="yearframechange()">
                              <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                            </select>
                          </div>
                          <div class="custom-select-wrapper">
                            <select v-model="selectedMonth" class="custom-select-modern" @change="monthframechange()">
                              <option v-for="(month, index) in months" :key="index" :value="index + 1">{{ month }}
                              </option>
                            </select>
                          </div> -->
                                                <div class="custom-select-wrapper">
                                                    <select v-model="selectedStatus" class="custom-select-modern"
                                                        @change="statusframechange()">
                                                        <option v-for="(stau, index) in status" :key="index"
                                                            :value="stau.value">
                                                            {{ stau.label }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <!-- <button @click="generateReport()" class="generate-button">Generate</button> -->
                                        </div>
                                        <div class="export-buttons">
                                            <div class="download-report">
                                                <p class="download-text">Download report</p>
                                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="PDF"
                                                    @click="exportAppointments('pdf')">
                                                    <img src="@/assets/img/pdf.svg" alt="Export to PDF"
                                                        class="export-iconx" />
                                                </a>
                                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"
                                                    @click="exportAppointments('excel')">
                                                    <img src="@/assets/img/excel.svg" alt="Export to Excel"
                                                        class="export-iconx" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-content">
                                        <!-- monthly -->
                                        <!-- Upcoming Appointment Tab -->

                                        <div class="tab-pane show active" id="upcoming-appointments">
                                            <div class="card card-table mb-0">
                                                <div class="card-body">
                                                    <div class="table-responsive">
                                                        <table class="table table-hover table-center mb-0">
                                                            <tbody>
                                                                <!-- visited -->
                                                                <template v-for="allAppointment in allappointmentstatus"
                                                                    :key="allAppointment.id">
                                                                    <tr
                                                                        v-if="selectedStatus === 'V' && allAppointment.totalcount_visited !== 0 || selectedStatus === 'AL' && allAppointment.totalcount_visited !== 0">
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-calendar-week fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    {{ selectedClinicName }}
                                                                                    <span
                                                                                        v-if="allAppointment.appointment_date !== undefined">{{
                                                                                            formatedDate(allAppointment.appointment_date)
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-mars fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Male
                                                                                    <span
                                                                                        v-if="allAppointment.malecount_visited !== undefined">{{
                                                                                            allAppointment.malecount_visited
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-venus fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Female
                                                                                    <span
                                                                                        v-if="allAppointment.femalecount_visited !== undefined">{{
                                                                                            allAppointment.femalecount_visited
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-transgender fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Other
                                                                                    <span
                                                                                        v-if="allAppointment.othercount_visited !== undefined">{{
                                                                                            allAppointment.othercount_visited
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-person fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Total Visited
                                                                                    <span
                                                                                        v-if="allAppointment.totalcount_visited !== undefined">{{
                                                                                            allAppointment.totalcount_visited
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td class="text-end">
                                                                            <div class="table-action">
                                                                                <button class="main-btn"
                                                                                    href="javascript:void(0)"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#appt_details"
                                                                                    @click="searchPatientAppointments(allAppointment.appointment_date, allAppointment.appointment_status)">View
                                                                                    Details</button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                                <!-- absent -->
                                                                <template v-for="allAppointment in allappointmentstatus"
                                                                    :key="allAppointment.id">
                                                                    <tr
                                                                        v-if="selectedStatus === 'A' && allAppointment.totalcount_absent !== 0 || selectedStatus === 'AL' && allAppointment.totalcount_absent !== 0">
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-calendar-week fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    {{ selectedClinicName }}
                                                                                    <span
                                                                                        v-if="allAppointment.appointment_date !== undefined">{{
                                                                                            formatedDate(allAppointment.appointment_date)
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-mars fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Male
                                                                                    <span
                                                                                        v-if="allAppointment.malecount_absent !== undefined">{{
                                                                                            allAppointment.malecount_absent
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-venus fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Female
                                                                                    <span
                                                                                        v-if="allAppointment.femalecount_absent !== undefined">{{
                                                                                            allAppointment.femalecount_absent
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-transgender fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Other
                                                                                    <span
                                                                                        v-if="allAppointment.othercount_absent !== undefined">{{
                                                                                            allAppointment.othercount_absent
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-person fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Total No show
                                                                                    <span
                                                                                        v-if="allAppointment.totalcount_absent !== undefined">{{
                                                                                            allAppointment.totalcount_absent
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td class="text-end">
                                                                            <div class="table-action">
                                                                                <button class="main-btn"
                                                                                    href="javascript:void(0)"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#appt_details"
                                                                                    @click="searchPatientAppointments(allAppointment.appointment_date, allAppointment.appointment_status)">View
                                                                                    Details</button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                                <!-- cancelled -->
                                                                <template v-for="allAppointment in allappointmentstatus"
                                                                    :key="allAppointment.id">
                                                                    <tr
                                                                        v-if="selectedStatus === 'C' && allAppointment.totalcount_cancelled !== 0 || selectedStatus === 'AL' && allAppointment.totalcount_cancelled !== 0">
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-calendar-week fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    {{ selectedClinicName }}
                                                                                    <span
                                                                                        v-if="allAppointment.appointment_date !== undefined">{{
                                                                                            formatedDate(allAppointment.appointment_date)
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-mars fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Male
                                                                                    <span
                                                                                        v-if="allAppointment.malecount_cancelled !== undefined">{{
                                                                                            allAppointment.malecount_cancelled
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-venus fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Female
                                                                                    <span
                                                                                        v-if="allAppointment.femalecount_cancelled !== undefined">{{
                                                                                            allAppointment.femalecount_cancelled
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-transgender fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Other
                                                                                    <span
                                                                                        v-if="allAppointment.othercount_cancelled !== undefined">{{
                                                                                            allAppointment.othercount_cancelled
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-person fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Total Cancelled
                                                                                    <span
                                                                                        v-if="allAppointment.totalcount_cancelled !== undefined">{{
                                                                                            allAppointment.totalcount_cancelled
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td class="text-end">
                                                                            <div class="table-action">
                                                                                <button class="main-btn"
                                                                                    href="javascript:void(0)"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#appt_details"
                                                                                    @click="searchPatientAppointments(allAppointment.appointment_date, allAppointment.appointment_status)">View
                                                                                    Details</button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                                <!-- confirmed -->
                                                                <template v-for="allAppointment in allappointmentstatus"
                                                                    :key="allAppointment.id">
                                                                    <tr
                                                                        v-if="selectedStatus === 'E' && allAppointment.totalcount_confirmed !== 0 || selectedStatus === 'AL' && allAppointment.totalcount_confirmed !== 0">
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-calendar-week fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    {{ selectedClinicName }}
                                                                                    <span
                                                                                        v-if="allAppointment.appointment_date !== undefined">{{
                                                                                            formatedDate(allAppointment.appointment_date)
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-mars fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Male
                                                                                    <span
                                                                                        v-if="allAppointment.malecount_confirmed !== undefined">{{
                                                                                            allAppointment.malecount_confirmed
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-venus fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Female
                                                                                    <span
                                                                                        v-if="allAppointment.femalecount_confirmed !== undefined">{{
                                                                                            allAppointment.femalecount_confirmed
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-transgender fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Other
                                                                                    <span
                                                                                        v-if="allAppointment.othercount_confirmed !== undefined">{{
                                                                                            allAppointment.othercount_confirmed
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            <h2 class="table-avatar">
                                                                                <router-link :to="'doctors-report'"
                                                                                    class="avatar avatar-sm me-2">
                                                                                    <i
                                                                                        class="fa-solid fa-person fa-xl"></i>
                                                                                </router-link>
                                                                                <router-link :to="'doctors-report'">
                                                                                    Total Confirmed
                                                                                    <span
                                                                                        v-if="allAppointment.totalcount_confirmed !== undefined">{{
                                                                                            allAppointment.totalcount_confirmed
                                                                                        }}</span>
                                                                                </router-link>
                                                                            </h2>
                                                                        </td>
                                                                        <td class="text-end">
                                                                            <div class="table-action">
                                                                                <button class="main-btn"
                                                                                    href="javascript:void(0)"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#appt_details"
                                                                                    @click="searchPatientAppointments(allAppointment.appointment_date, allAppointment.appointment_status)">View
                                                                                    Details</button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Page Content -->
    </div>
    <div class="modal fade custom-modal" id="appt_details">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Past Appointments</h5>
                    <b-button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></b-button>
                </div>
                <div class="modal-body">
                    <div class="tab-pane show active" id="today-appointments">
                        <div class="card card-table mb-0">
                            <div class="card-body">
                                <div class="filter-collapse"
                                    style="text-align: right; margin-right: 5px; margin-top:10px; margin-bottom:10px">
                                    <ul>
                                        <li>
                                            <label class="custom_check d-inline-flex" style="margin-right: 15px;">
                                                <input type="checkbox" v-model="isActivev"
                                                    @change="toggleActiveStatusvx" />
                                                <span class="checkmark"></span>
                                                <span class="label-text">Visited</span>
                                            </label>
                                            <label class="custom_check d-inline-flex" style="margin-right: 15px;">
                                                <input type="checkbox" v-model="isActivea"
                                                    @change="toggleActiveStatusvx" />
                                                <span class="checkmark"></span>
                                                <span class="label-text">No show</span>
                                            </label>
                                            <label class="custom_check d-inline-flex" style="margin-right: 15px;">
                                                <input type="checkbox" v-model="isActivec"
                                                    @change="toggleActiveStatusvx" />
                                                <span class="checkmark"></span>
                                                <span class="label-text">Cancelled</span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div class="table-responsive" ref="tableContainer">
                                    <table class="table table-hover table-center mb-0">
                                        <thead>
                                            <tr>
                                                <th>Patient Information</th>
                                                <th>Appt Date & Time</th>
                                                <th>Clinic Name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="appointment in pastAppointmentfilter "
                                                :key="appointment.appointmentid">
                                                <td>
                                                    <h2 class="table-avatar">
                                                        <router-link :to="'doctors-report'"
                                                            class="avatar avatar-sm me-2">
                                                            <img class="avatar-img rounded-circle" :src="getPatientImage(appointment)
                                                                " alt="User Image" />
                                                        </router-link>
                                                        <router-link :to="'doctors-report'">
                                                            {{ appointment.firstname }} {{ appointment.lastname }}
                                                            <span>{{ appointment.contactnumbers }}</span>
                                                        </router-link>
                                                    </h2>
                                                </td>
                                                <td>
                                                    {{ formatDate(appointment.appointmentdate) }} {{
                                                        formatTime(appointment.appointmenttime) }}
                                                </td>
                                                <td>
                                                    <h2>
                                                        <router-link :to="'doctors-report'">
                                                            {{
                                                                appointment.clinicname }}
                                                            <span>
                                                                {{ appointment.locationname
                                                                }}
                                                            </span>
                                                        </router-link>
                                                    </h2>
                                                </td>
                                                <td>
                                                    <div class="status-label mt-2">
                                                        <template v-if="appointment.appointmentstatus === 'A'">
                                                            <span class="badge rounded-pill bg-warning-light">No
                                                                show</span>
                                                        </template>

                                                        <template v-else-if="appointment.appointmentstatus === 'C'">
                                                            <span
                                                                class="badge rounded-pill bg-danger-light">Cancelled</span>
                                                        </template>

                                                        <template v-else-if="appointment.appointmentstatus === 'V'">
                                                            <span
                                                                class="badge rounded-pill bg-success-light">Visited</span>
                                                        </template>

                                                        <template v-else-if="appointment.appointmentstatus === 'E'">
                                                            <span
                                                                class="badge rounded-pill bg-warning-light">Confirmed</span>
                                                        </template>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Main Wrapper -->
    <!-- Appointment Details Modal -->

    <clinicfooter />
    <!-- /Appointment Details Modal -->
</template>
<script>
import axios from "axios";
import { format, addMonths, getYear, getMonth } from "date-fns";
import doctorupcoming from "@/assets/json/doctor/doctorupcoming.json";
import doctortoday from "@/assets/json/doctor/doctortoday.json";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import DateRangePicker from "daterangepicker";
import CircleProgress from "vue3-circle-progress";
import "vue3-circle-progress/dist/circle-progress.css";
import { ref } from "vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import jQuery from "jquery";
const $ = jQuery;

export default {
    components: {
        CircleProgress,
        DateRangePicker,
    },
    data() {
        const currentDate = new Date();
        const startdatex = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1)).toISOString().split('T')[0];
        const enddatex = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)).toISOString().split('T')[0];
        return {
            dateRange: {
                start: '',
                end: ''
            },
            timeframechanged: false,
            selectedMonth: new Date().getMonth() + 1,
            selectedYear: new Date().getFullYear().toString(),
            months: [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ],
            selectedStatus: 'AL',
            selectedClinic: '',
            status: [
                { label: "Visited", value: "V" },
                { label: "No show", value: "A" },
                { label: "Confirmed", value: "E" },
                { label: "Cancelled", value: "C" },
                { label: "All", value: "AL" },
            ],
            clinics: [],
            years: [new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1],
            title: "Reports",
            text: "Home",
            text1: "Reports",
            doctorDetails: [],
            DoctorId: null,
            statDetails: [],
            formattedDate: "",
            allappointmentstatus: [],
            allappointments: [],
            upcomingAppointments: [],
            upcomingAppointmentsfilter: [],
            weeklyAppointmentsfilter: [],
            currentAppointments: [],
            chooseAppointments: [],
            AllAppointmentsfilter: [],
            allAppointment: null,
            currentAppointmentsfilter: [],
            selectedAppointment: {},
            filteredAppointments: [],
            doctorinfo: [],
            selectedDate: null,
            doctorupcoming: doctorupcoming,
            doctortoday: doctortoday,
            selectedTab: "choose",
            isActive: false,
            isActivev: true,
            isActivea: false,
            isActivec: false,
            dummyStartTime: '2023-05-01',
            dummyEndTime: '2023-05-15',
            startDate: null,
            endDate: null,
            startdatex: startdatex,
            enddatex: enddatex,
            startdate: 'null',
            enddate: 'null',
            datetime: null,
            currentDate: currentDate,
            AllAppointmentsfilterexcel: [],
            AllAppointmentsfilterpdf: [],
            selectedClinicName: '',
            pastAppointmentfilter: [],
            pastAppointmentfilterx: [],
        };
    },

    methods: {
        handleDateUpdate({ from, to }) {
            //console.log('Selected date range:', from, to);
        },
        timeframechange() {
            this.timeframechanged = true;
        },
        async yearframechange() {
            this.loading = true;
            this.startdatex = format(
                new Date(this.selectedYear, this.selectedMonth - 1, 1),
                "yyyy-MM-dd"
            );
            this.enddatex = format(
                new Date(this.selectedYear, this.selectedMonth, 0),
                "yyyy-MM-dd"
            );
            await this.fetchAllAppointments(this.startdatex, this.enddatex);
        },
        async monthframechange() {
            this.loading = true;
            this.startdatex = format(
                new Date(this.selectedYear, this.selectedMonth - 1, 1),
                "yyyy-MM-dd"
            );
            this.enddatex = format(
                new Date(this.selectedYear, this.selectedMonth, 0),
                "yyyy-MM-dd"
            );
            await this.fetchAllAppointments(this.startdatex, this.enddatex);
        },
        statusframechange() {
            this.fetchAllAppointments();
        },
        getClinicNameById(clinicId) {
            const clinic = this.clinics.find(clinic => clinic.id === clinicId);
            return clinic ? clinic.name : null;
        },
        clinicframechange() {
            this.selectedClinicName = this.getClinicNameById(this.selectedClinic);
            this.fetchAllAppointments();
        },
        initDateRangePicker() {
            const vm = this;
            $(function () {
                $("#daterange").daterangepicker({
                    singleDatePicker: true,
                    showDropdowns: true,
                    autoUpdateInput: false,
                    locale: {
                        format: "YYYY-MM-DD",
                    },
                    startDate: vm.startdatex,
                }, function (start) {
                    vm.startdatex = start.format("YYYY-MM-DD");
                    vm.fetchAllAppointments();
                });
            });
        },
        initDateRangePickerok() {
            const vm = this;
            $(function () {
                $("#daterangeok").daterangepicker({
                    singleDatePicker: true,
                    showDropdowns: true,
                    autoUpdateInput: false,
                    locale: {
                        format: "YYYY-MM-DD",
                    },
                    endDate: vm.enddatex,

                }, function (end) {
                    vm.enddatex = end.format("YYYY-MM-DD");
                    vm.fetchAllAppointments();
                });
            });
        },
        async fetchDatetime() {
            try {
                const response = await axios.get('/doctors/datetime');
                this.datetime = response.data[1];
                this.currentDate = new Date(this.datetime);
            } catch (error) {
                console.error('Error fetching datetime:', error);
            }
        },
        async fetchClinicData() {
            try {
                const response = await axios.get(`/doctors/${this.doctorinfo.doctorId}/unique-clinics`);
                this.clinicData = response.data;
                console.log("this.clinicData", this.clinicData);
                this.clinics = [
                    {
                        id: this.doctorinfo.clinicId,
                        name: this.doctorinfo.clinicname
                    }
                ];
                if (this.clinics.length > 0) {
                    this.selectedClinic = this.doctorinfo.clinicId;
                    this.selectedClinicName = this.doctorinfo.clinicname;
                }
            } catch (error) {
                console.error("API Error:", error);
            }
        },
        selectChooseAppointments() {
            if (this.dateRange.start && this.dateRange.end) {
                this.startDate = this.dateRange.start;
                this.endDate = this.dateRange.end;
            }
        },
        formatDate(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString(undefined, options);
        },
        toggleActiveStatus() {
            this.isActive = !this.isActive;
            this.toggle();
        },
        toggle() {
            this.isActive = !this.isActive;
            if (this.selectedDate) {
                this.filterAppointmentsByDate(this.selectedDate);
            }
        },

        formatShortDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString("en-US", {
                month: "short",
                day: "numeric"
            });
        },

        selectSpecificDay(date) {
            this.selectedDate = new Date(date);
            this.selectedTab = "specific";
            this.filterAppointmentsByDate(this.selectedDate);
        },
        selectChooseAppointments() {
            this.selectedTab = "choose";
            this.fetchAllAppointments();
        },
        selectTodayAppointments() {
            this.selectedTab = "today";
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric"
            });
        },
        formatedDate(date) {
            return new Date(date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric"
            });
        },
        formatTime(time) {
            if (!time || typeof time !== 'string') {
                console.error('Invalid time provided:', time);
                return 'Invalid Time';
            }
            const formattedTime = time.padStart(5, '0');
            return new Date(`2022-01-01T${formattedTime}`).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true
            });
        },
        getPatientImage(appointment) {
            return (
                appointment.patientimage || require("@/assets/img/patients/patient.jpg")
            );
        },
        async fetchDoctorDetails() {
            try {
                const doctoridx = this.doctorinfo.doctorId;
                let docDetails = sessionStorage.getItem(`doctorDetails${doctoridx}`);
                if (!docDetails) {
                    const response = await axios.get(`/doctors/${doctoridx}`);
                    docDetails = response.data;
                    sessionStorage.setItem(
                        `doctorDetails${doctoridx}`,
                        JSON.stringify(docDetails)
                    );
                } else {
                    docDetails = JSON.parse(docDetails);
                }
                this.doctorDetails = docDetails;
                this.fetchDoctorStatCount(this.doctorinfo.doctorId);
                this.formatDate();
            } catch (error) {
                console.error("Error fetching doctor details:", error);
            }
        },
        async fetchDoctorStatCount(doctorId) {
            try {
                const response = await axios.get(`/doctors/${this.doctorinfo.doctorId}/doctorstats`);
                this.statDetails = response.data;
            } catch (error) {
                console.error("Error fetching doctors count:", error);
            }
        },
        fetchUpcomingAppointments() {
            axios
                .get(`/doctors/${this.doctorinfo.doctorId}/appointments?filter_type=upcoming`)
                .then(response => {
                    this.upcomingAppointments = response.data.upcoming_appointments;
                    this.currentAppointments = response.data.current_day_appointments;

                    if (this.selectedDate && this.selectedTab === "specific") {
                        this.filterAppointmentsByDate(this.selectedDate);
                    }
                })
                .catch(error => {
                    console.error("Error fetching upcoming appointments", error);
                });
        },

        async PrefetchAllAppointments() {
            if (!this.datetime) {
                await this.fetchDatetime();
            }
            if (this.selectedTab === "choose") {
                this.startDate = this.startdatex;
                this.endDate = this.enddatex;

            }
            this.startdate = String(this.startDate);
            this.enddate = String(this.endDate);

            try {
                let appointmentsFound = false;
                let attempts = 0;
                const maxAttempts = 12;
                while (!appointmentsFound && attempts < maxAttempts) {
                    const response = await axios.get(`/doctors/${this.doctorinfo.doctorId}/appointmentcount`, {
                        params: {
                            clinicid: this.selectedClinic,
                            custom_clinic_type: this.selectedClinic,
                            filter_type: 'all',
                            startDate: this.startdate,
                            endDate: this.enddate,
                        },
                    });
                    this.allappointmentstatus = response.data.all_appointments;
                    if (this.allappointmentstatus && this.allappointmentstatus.length > 0) {
                        appointmentsFound = true;
                        this.startdatex = this.startdate;
                    } else {
                        const newStartDate = new Date(this.startdate);
                        newStartDate.setUTCMonth(newStartDate.getUTCMonth() - 1);

                        const newEndDate = new Date(this.enddate);
                        newEndDate.setUTCMonth(newEndDate.getUTCMonth() - 1);

                        this.startdate = new Date(Date.UTC(newStartDate.getUTCFullYear(), newStartDate.getUTCMonth(), 1))
                            .toISOString()
                            .split('T')[0];
                        this.enddate = new Date(Date.UTC(newEndDate.getUTCFullYear(), newEndDate.getUTCMonth() + 1, 0))
                            .toISOString()
                            .split('T')[0];

                        attempts++;
                    }
                }
                if (!appointmentsFound) {
                    console.warn("No appointments found in the last 12 months.");
                }

                if (this.selectedDate && this.selectedTab === "specific") {
                    this.filterAppointmentsByDate(this.selectedDate);
                }
             else if (this.selectedTab === "today") {
                    this.filterCurrentAppointments();
                }
            } catch (error) {
                console.error("Error fetching all appointments", error);
            }
        },
        async fetchAllAppointments() {
            if (!this.datetime) {
                await this.fetchDatetime();
            }
            if (this.selectedTab === "choose") {
                this.startDate = this.startdatex;
                this.endDate = this.enddatex;

            }
            this.startdate = String(this.startDate);
            this.enddate = String(this.endDate);
            try {
                const response = await axios.get(`/doctors/${this.doctorinfo.doctorId}/appointmentcount`, {
                    params: {
                        clinicid: this.selectedClinic,
                        custom_clinic_type: this.selectedClinic,
                        filter_type: 'all',
                        startDate: this.startdatex,
                        endDate: this.enddatex,
                    },
                });
                this.allappointmentstatus = response.data.all_appointments;
                if (this.selectedDate && this.selectedTab === "specific") {
                    this.filterAppointmentsByDate(this.selectedDate);
                }
                else if (this.selectedTab === "upcoming") {
                    this.filterUpcomingAppointments();
                } else if (this.selectedTab === "today") {
                    this.filterCurrentAppointments();
                }
            } catch (error) {
                console.error("Error fetching all appointments", error);
            }
        },

        viewAppointmentDetails(appointment) {
            this.selectedAppointment = appointment;
        },
        toggleActiveStatusvx() {
            if (!this.pastAppointmentfilterx) return;
            let activeStatuses = [];
            if (this.isActivev) activeStatuses.push("V");
            if (this.isActivea) activeStatuses.push("A");
            if (this.isActivec) activeStatuses.push("C");
            if (activeStatuses.length > 0) {
                this.pastAppointmentfilter = this.pastAppointmentfilterx.filter(appointment =>
                    activeStatuses.includes(appointment.appointmentstatus)
                );
            }
            else {
                this.pastAppointmentfilter = [];
            }
        },

        async searchPatientAppointments(appointmentDate, apptstatus) {
            let datestring;
            datestring = new Date(appointmentDate)
                .toLocaleDateString("en-CA")
                .substring(0, 10);
            try {
                const response = await axios.get(
                    `/clinic/${this.selectedClinic}/pastappointments/${this.doctorinfo.doctorId}`,
                    {
                        params: {
                            date: datestring
                        }
                    }
                );
                if (
                    response.data &&
                    Array.isArray(response.data.appointmentlist) &&
                    response.data.appointmentlist.length > 0
                ) {
                    this.pastAppointmentfilterx = response.data.appointmentlist;
                    this.toggleActiveStatusvx();
                    return true;
                } else {
                    this.pastAppointmentfilter = [];
                    return false;
                }
            } catch (error) {
                console.error("Error searching patient appointments:", error);
            }
        },
        exportAppointments(format) {
            if (format === "pdf") {
                this.AllAppointmentsfilterpdf = this.allappointmentstatus;
                this.exportToPDF(this.AllAppointmentsfilterpdf);
            } else if (format === "excel") {
                this.AllAppointmentsfilterexcel = this.allappointmentstatus;
                this.exportToExcel(this.AllAppointmentsfilterexcel);
            }
        },
        exportToPDF(Appointments) {

            let appointments = [];
            let appointmentsx = [];
            let appointmentsy = [];
            let appointmentsz = [];
            let allAppointments = [];
            const doc = new jsPDF();
            const doctorName = this.doctorDetails.doctorname
            const clinicnamex = this.selectedClinicName
            const startdatexcel = this.startdate;
            const enddatexcel = this.enddate;
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleDateString();
            const formattedTime = currentDate.toLocaleTimeString();
            const currentDateTimex = `${formattedDate} ${formattedTime}`;
            const formattedDoctorName = `Doctor Name: ${doctorName}`;
            const clinicNamey = `Clinic Name: ${clinicnamex}`;
            const rangey = `Date Range: ${startdatexcel} to ${enddatexcel}`;
            const currentDateTime = `Date: ${currentDateTimex}`;
            const statusLabels = {
                A: "No show",
                V: "Visited",
                C: "Cancelled",
                E: "Confirmed"
            };
            if (this.selectedStatus === 'V' || this.selectedStatus === 'AL') {
                appointments = this.AllAppointmentsfilterpdf.map(appointment => [
                    appointment.appointment_date,
                    appointment.male_count,
                    appointment.female_count,
                    appointment.other_count,
                    appointment.total_count,
                    "Visited"
                ]);
                allAppointments = allAppointments.concat(appointments);
            }
            if (this.selectedStatus === 'A' || this.selectedStatus === 'AL') {
                appointmentsx = this.AllAppointmentsfilterpdf.map(appointment => [
                    appointment.appointment_date,
                    appointment.male_count,
                    appointment.female_count,
                    appointment.other_count,
                    appointment.total_count,
                    "No show",
                ]);
                allAppointments = allAppointments.concat(appointmentsx);
            }
            if (this.selectedStatus === 'E' || this.selectedStatus === 'AL') {
                appointmentsy = this.AllAppointmentsfilterpdf.map(appointment => [
                    appointment.appointment_date,
                    appointment.male_count,
                    appointment.female_count,
                    appointment.other_count,
                    appointment.total_count,
                    "Confirmed",
                ]);
                allAppointments = allAppointments.concat(appointmentsy);
            }
            if (this.selectedStatus === 'C' || this.selectedStatus === 'AL') {
                appointmentsz = this.AllAppointmentsfilterpdf.map(appointment => [
                    appointment.appointment_date,
                    appointment.male_count,
                    appointment.female_count,
                    appointment.other_count,
                    appointment.total_count,
                    "Cancelled",
                ]);
                allAppointments = allAppointments.concat(appointmentsz);
            }
            let heading;
            if (this.selectedTab === "choose") {
                heading = "Appointment Report";
            } else if (this.selectedTab === "upcoming") {
                heading = "Upcomming Appointments";
            } else {
                heading = "Appointment Report"
            }

            const gap = 10;
            const gapx = 25;
            const yPos = gap;
            const textWidth =
                (doc.getStringUnitWidth(heading) * doc.internal.getFontSize()) /
                doc.internal.scaleFactor;
            const xPos = (doc.internal.pageSize.width - textWidth) / 2;
            doc.text(heading, xPos, yPos);
            const doctorNameFontSize = 10;
            const headingFontSize = 16;

            doc.setFontSize(doctorNameFontSize);
            doc.text(formattedDoctorName, 15, gapx);
            const infoFontSize = 10;
            const infoGap = gapx + 10;

            doc.setFontSize(infoFontSize);
            doc.text(clinicNamey, 15, infoGap);

            const rangeGap = infoGap + 10;
            doc.text(rangey, 15, rangeGap);
            doc.setFontSize(doctorNameFontSize);
            const pageWidth = doc.internal.pageSize.width;
            const currentDateTimeWidth = (doc.getStringUnitWidth(currentDateTime) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
            const rightAlignedXPos = pageWidth - currentDateTimeWidth - 15;
            doc.text(currentDateTime, rightAlignedXPos, gapx);

            doc.autoTable({
                head: [["Appt date", "Male", "Female", "Other", "Total", "Status",]],
                body: allAppointments,
                startY: rangeGap + 6
            });
            doc.save("doc-appointments-report.pdf");
        },
        exportToExcel(Appointments) {
            const doctorName = this.doctorDetails.doctorname;
            const clinicnamex = this.selectedClinicName;
            const startdatexcel = this.startdate;
            const enddatexcel = this.enddate;
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Appointments");
            const currentDateTime = new Date().toLocaleString();
            worksheet.addRow([`Doctor Name: ${doctorName}`]);
            worksheet.addRow([`Clinic Name: ${clinicnamex}`]);
            worksheet.addRow([`Start Date: ${startdatexcel} to End Date:${enddatexcel}`]);
            worksheet.addRow([`Date: ${currentDateTime}`]);
            worksheet.mergeCells('A1:G1');
            worksheet.mergeCells('A2:G2');
            worksheet.mergeCells('A3:G3');
            worksheet.mergeCells('A4:G4');
            worksheet.addRow([
                "Appt date", "Male", "Female", "Other", "Total", "Status"
            ]);
            worksheet.columns = [
                { key: "appointmentDate", width: 20 },
                { key: "malecount", width: 15 },
                { key: "femalecount", width: 15 },
                { key: "othercount", width: 15 },
                { key: "totalcount", width: 15 },
                { key: "appointmentstatus", width: 15 }
            ];
            Appointments.forEach(appointment => {
                if (this.selectedStatus === 'V' || this.selectedStatus === 'AL') {
                    worksheet.addRow({
                        appointmentDate: appointment.appointment_date,
                        malecount: appointment.male_count,
                        femalecount: appointment.female_count,
                        othercount: appointment.other_count,
                        totalcount: appointment.total_count,
                        appointmentstatus: 'Visited'
                    });
                }
                if (this.selectedStatus === 'A' || this.selectedStatus === 'AL') {
                    worksheet.addRow({
                        appointmentDate: appointment.appointment_date,
                        malecount: appointment.male_count,
                        femalecount: appointment.female_count,
                        othercount: appointment.other_count,
                        totalcount: appointment.total_count,
                        appointmentstatus: 'No show'
                    });
                }
                if (this.selectedStatus === 'E' || this.selectedStatus === 'AL') {
                    worksheet.addRow({
                        appointmentDate: appointment.appointment_date,
                        malecount: appointment.male_count,
                        femalecount: appointment.female_count,
                        othercount: appointment.other_count,
                        totalcount: appointment.total_count,
                        appointmentstatus: 'Confirmed'
                    });
                }
                if (this.selectedStatus === 'C' || this.selectedStatus === 'AL') {
                    worksheet.addRow({
                        appointmentDate: appointment.appointment_date,
                        malecount: appointment.male_count,
                        femalecount: appointment.female_count,
                        othercount: appointment.other_count,
                        totalcount: appointment.total_count,
                        appointmentstatus: 'Cancelled'
                    });
                }
            });
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "doc-appointments-report.xlsx";
                link.click();
            });
        }
    },
    async created() {
        await this.fetchDatetime();
        await this.PrefetchAllAppointments();
    },
    async mounted() {
        await this.PrefetchAllAppointments();
        this.initDateRangePicker();
        this.initDateRangePickerok();
        await this.fetchDatetime();
        await this.fetchClinicData();
    },

    async beforeMount() {
        this.doctorinfo = this.$store.getters.getclinicDetails;
        this.fetchDoctorDetails();
        this.fetchUpcomingAppointments();
        await this.fetchDatetime();
        await this.fetchClinicData();
        await this.PrefetchAllAppointments();
        const percent = ref(75);
        setInterval(() => {
            if (percent.value === 25) {
                percent.value = 75;
            } else {
                percent.value = 25;
            }
        }, 1000);
    },
    name: "doctor-dashboard"
};
</script>
<style scoped>
.main-btn {
    background-color: #0e82fd;
    border: 1px solid;
    border-radius: 4px;
    color: #fff;
    padding: 2px 7px;
    margin-right: 5px;
    margin-left: 1px;
    font-size: 15px;
}

.main-btn:hover {
    background-color: #ffffff;
    border: 1px solid #0e82fd;
    color: #0e82fd;
}

.custom-select-wrapper {
    position: relative;
    display: inline-block;
}

.custom-select-modern {
    appearance: none;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    cursor: pointer;
    width: 140px;
    color: #000;
}

.custom-select-modern:focus {
    border-color: #245dd6;
    outline: none;
}

.custom-select-modernx {
    appearance: none;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    cursor: pointer;
    width: 170px;
    color: #000;
}

.custom-select-modernx:focus {
    border-color: #245dd6;
    outline: none;
}

.custom-select-wrapper::after {
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #555;
}

.date-range-selection-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.date-range-selection {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 5px;
}

.daterange-container {
    display: flex;
    align-items: center;
    padding: 2px;
}

@media (max-width: 768px) {
    .daterange-container {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }
}

.calendar-icon {
    font-size: 20px;
    color: #4caf50;
    margin-right: 1px;
}

.daterange-input {
    padding: 5px 10px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    cursor: pointer;
    color: #000;
}

.daterange-input:hover {
    background-color: #e0e0e0;
}

.daterange-input:focus {
    outline: none;
    background-color: #e0f7e0;
}

.daterange-to {
    margin: 0 10px;
    font-size: 16px;
    color: #333;
}

.dropdown-container {
    margin-left: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.custom-select {
    padding: 1px;
    border: none;
    border-radius: 4px;
}

.generate-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 6px 20px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 10px;
    margin-left: 5px;
    transition: background-color 0.3s;
}

.generate-button:hover {
    background-color: #1d7eda;
}

.export-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 75px;
}

.download-text {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    opacity: 0.5;
}

.download-report {
    display: flex;
    align-items: center;
}

.export-iconx {
    width: 40px;
    height: 30px;
    margin-left: 10px;
    cursor: pointer;
    align-items: top;
}


.export-iconx:hover {
    opacity: 0.7;
}


@media (max-width: 768px) {
    .export-iconx {
        margin-left: 10px;
        width: 25px;
        height: 45px;
    }
}

@media (max-width: 767px) {
    .export-buttons {
        margin-top: 10px;
    }
}


.modal-content .btn {
    cursor: auto;
}
</style>