<template>
  <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
    <div class="stickysidebar">
      <!-- Profile Sidebar -->
      <div class="profile-sidebar">
        <div class="widget-profile pro-widget-content">
          <div class="profile-info-widget">
            <a href="#" class="booking-doc-img">
              <img :src="profileImageUrl ||defaultImageUrl" alt="User Image" />
            </a>
            <div class="profile-det-info">
              <h3>Dr. {{doctorDetails.doctorname}}</h3>

              <div class="patient-details">
                <span
                  class="qualification-tooltip"
                  :title="`${doctorDetails.qualifications} ${doctorDetails.specialization}`"
                >{{ doctorDetails.qualifications }}</span>
                {{ doctorDetails.specialization }}
              </div>
            </div>
          </div>
        </div>
        <div class="dashboard-widget">
          <nav class="dashboard-menu">
            <ul>
              <li :class="{ active: isActive('/clinic-doctor-dashboard') }">
                <router-link to="/clinic-doctor-dashboard">
                  <i class="fas fa-columns"></i>
                  <span class="ms-1">Dashboard</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/clinics-doctors-report') }">
                <router-link to="/clinics-doctors-report">
                  <i class="fas fa-file-alt"></i>
                  <span class="ms-1">Reports</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('add-clinic-appointment') }">
                <router-link to="/add-clinic-appointment">
                  <i class="fas fa-hourglass-start"></i>
                  <span class="ms-1">Add Appointment</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/clinic-appointment-history') }">
                <router-link to="/clinic-appointment-history">
                  <i class="fas fa-calendar-check"></i>
                  <span class="ms-1">Appointments History</span>
                </router-link>
              </li>
               <!--li :class="{ active: isActive('/clinc-schedule-timings') }">
                <router-link to="/clinic-schedule-timings">
                  <i class="fas fa-hourglass-start"></i>
                  <span class="ms-1">Schedule Timings</span>
                </router-link>
              </li-->
                <li :class="{ active: isActive('/clinic-available-timings') }">
                <router-link to="/clinic-available-timings">
                  <i class="fas fa-edit"></i>
                  <span class="ms-1">Cancel Appointments</span>
                </router-link>
              </li>
              <!-- <li :class="{ active: isActive('/doctor-profile-settings') }">
                <router-link to="/doctor-profile-settings">
                  <i class="fas fa-user-cog"></i>
                  <span class="ms-1">Profile Settings</span>
                </router-link>
              </li> -->
              <!-- <li :class="{ active: isActive('/doctor-change-password') }">
                <router-link to="/doctor-change-password">
                  <i class="fas fa-lock"></i>
                  <span class="ms-1">Change Password</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/') }">
                <router-link to @click="signOutUser">
                  <i class="fas fa-sign-out-alt"></i>
                  <span class="ms-1">Logout</span>
                </router-link>
              </li> -->
            </ul>
          </nav>
        </div>
      </div>
      <!-- /Profile Sidebar -->
    </div>
  </div>
</template>
<script>
import { signOut } from "aws-amplify/auth";
import axios from "axios";
import defaultImageUrl from "@/assets/img/doctors/doctor-thumb-01.jpg";
export default {
  
  data() {
    return {
      activeClass: "active",
      doctorDetails: [],
      doctorinfo: this.$store.getters.getclinicDetails,
      profileImageUrl: "",
      defaultImageUrl:defaultImageUrl,
    };
  },
  methods: {
    isActive(paths) {
      return paths.includes(this.$route.path);
    },
    signOutUser() {
      signOut()
        .then(data => {
          this.$router.push("/login");
        })
        .catch(err => console.log(err));
    },
    async fetchDoctorDetails() {
      const doctorId = this.doctorinfo.doctorId
      //console.log("doctor id is sidebar ", this.doctorinfo.doctorId);
      try {
          const response = await axios.get(`/doctors/${doctorId}`);
          const docDetails = response.data;
          
          this.doctorDetails = docDetails;
          //console.log("Doctor Details:", this.doctorDetails);
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    },

    async fetchPatientProfileImage() {
      const doctorid = this.doctorinfo.doctorId
      try {
        const response = await axios.get(
          `doctors/doctorimg/${doctorid}/download`,
          {
            responseType: "arraybuffer"
          }
        );
        const objectURL = window.URL.createObjectURL(new Blob([response.data]));
        this.profileImageUrl = objectURL;
        this.$store.dispatch("updateAppointmentDraft", {
          doctorImage: this.profileImageUrl
        });
      } catch (error) {
        console.error("Error fetching patient profile image:", error);
      }
    }
  },
  mounted() {
    this.fetchDoctorDetails();
    this.fetchPatientProfileImage();
  }
};
</script>
