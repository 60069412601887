<template>
  <div role="tabpanel" id="doc_reviews" class="tab-pane fade">
    <div class="write-review responsive-margin" style="margin-top: 30px">
      <form v-if="usertype === 'Patient'">
        <!-- start_of_star_review -->
        <div>
          <b-alert v-model="showSuccessAlert" variant="success" dismissible>{{ successMessage }}</b-alert>
        </div>
        <div class="mb-3">
          <div class="star-rating">
            <input id="star-5" type="radio" name="rating" value="star-5" @click="selectedRating = '5'" />
            <label for="star-5" title="5 stars">
              <i class="active fa fa-star ms-1"></i>
            </label>
            <input id="star-4" type="radio" name="rating" value="star-4" @click="selectedRating = '4'" />
            <label for="star-4" title="4 stars">
              <i class="active fa fa-star ms-1"></i>
            </label>
            <input id="star-3" type="radio" name="rating" value="star-3" @click="selectedRating = '3'" />
            <label for="star-3" title="3 stars">
              <i class="active fa fa-star ms-1"></i>
            </label>
            <input id="star-2" type="radio" name="rating" value="star-2" @click="selectedRating = '2'" />
            <label for="star-2" title="2 stars">
              <i class="active fa fa-star ms-1"></i>
            </label>
            <input id="star-1" type="radio" name="rating" value="star-1" @click="selectedRating = '1'" />
            <label for="star-1" title="1 star">
              <i class="active fa fa-star ms-1"></i>
            </label>
          </div>
        </div>
        <!-- end_of_star_review -->

        <div class="ratingheading">
          <h5>Write a review for <strong>{{ review.firstname }}</strong></h5>
        </div>
        <div class="write-star" style="display: flex; justify-content: center;">
          <input class="form-control" type="text" placeholder="If you could say it in one sentence, what would you say?"
            maxLength="50" style="width: 420px;" v-model="titleInput" /><br>
          <!-- start_of optional replacement for inputbox -->
          <!-- <vue-multiselect
            v-model="doctor.languagesspoken"
            :options="languageOptions"
            :max="5"
            placeholder="Select languages"
            label="label"
            track-by="value"
            multiple
          >
            <template slot="tag" slot-scope="{ option }">
              <span class="custom__tag">{{ option.label }}</span>
            </template>
</vue-multiselect> -->
          <!-- end_of optional replacement for inputbox -->
        </div>
        <label style="display: block; text-align: center; margin-top: 3px;"><a href="javascript:;"
            @click="toggleDetailedReview">
            <i class="feather" :class="detailedreview_visible ? 'feather-minus' : 'feather-plus'"></i>{{
              detailedreview_visible
                ? 'Give short review' : 'Give detailed review' }}</a></label>
        <div style="align-items: center">
          <div v-if="detailedreview_visible" class="mb-3">
            <!-- start of recommend -->
            <div class="review-listing" style="display: flex; justify-content: center;">
              <ul class="comments-list">
                <!-- Comment List -->
                <li>
                  <div class="comment">
                    <div class="comment-body">
                      <div class="comment-reply" style="display: flex; justify-content: center;">
                        <!-- <a class="comment-btn" href="javascript:;"> <i class="fas fa-reply"></i> Reply </a> -->
                        <div class="widget review-listing">
                          <p class="recommend-btn">
                            <span style="margin-right: 3px;">Recommend?</span>
                            <a href="javascript:;" class="like-btn" @click="recommend('Y')"
                              :class="{ active: recommendInput === 'Y' }">
                              <i class="far fa-thumbs-up"></i> </a>
                            <a href="javascript:;" class="dislike-btn" @click="recommend('N')"
                              :class="{ active: recommendInput === 'N' }">
                              <i class="far fa-thumbs-down"></i>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <!-- /Comment List -->
              </ul>
            </div>
            <!-- end of recommend -->
            <textarea maxlength="500" class="form-control" style="margin-top:25px; height:200" placeholder=""
              v-model="detailedInput"></textarea>
            <!-- <div class="d-flex justify-content-between mt-3">
            <small class="text-muted"><span id="chars">100</span> characters remaining</small>
          </div> -->
          </div>
          <!-- <hr /> -->
          <div class="submit-section"
            style="margin-top:20px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
            <button @click.prevent="submitReview()" class="btn btn-primary submit-btn"
              :disabled="termscondtion_checked">Submit
              Review</button>
          </div>
          <div class="mb-3">
            <div class="terms-accept"
              style="margin-top:10px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
              <span class="checkmark"></span>
              <div class="custom-check">
                <!-- <input id="terms_accept" class="me-1" /> -->
                <div for="terms_accept" @click="toggleTermsConditionCheckbox"><i v-show="termscondtion_checked"
                    class="fas fa-square hollow-icon" style="margin-right:4px"></i><i v-show="!termscondtion_checked"
                    class="fas fa-check-square" style="margin-right:4px"></i> I have read and accept <a
                    href="https://docdemo.thegiglabs.com/terms-condition">Terms
                    &amp;
                    Conditions</a></div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!-- start of listing -->
      <div class="widget review-listing" style="margin-top:60px;" v-if="pending.length > 0">
        <ul class="comments-list">
          <!-- Comment List -->
          <li v-for="pending in pending" :key="pending.slno">
            <div class="comment">
              <img class="avatar avatar-sm rounded-circle" alt="User Image" src="@/assets/img/patients/patient.jpg" />
              <div class="comment-body">
                <div class="meta-data">
                  <span class="comment-author">{{ pending.firstname }} {{ pending.lastname }} <p
                      class="recommended inline-elements" v-if="review.recommendyn === 'Y'">
                      <i class="far fa-thumbs-up"></i> Recommended
                    </p></span>
                  <div class="rating inline-elements">

                    {{ getDaysAgo(pending.createddate) }} - Pending
                  </div>
                  <!-- <span class="comment-date"> {{ getDaysAgo(review.createddate) }}</span> -->
                </div>
                <p class="recommended inline-elements">
                  <!-- <i class="far fa-thumbs-up"></i> -->
                  <span v-html="getStarRating(pending.rating)"></span>
                </p>
                <p class="comment-content">
                  {{ pending.titlename }} - {{ pending.comments }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="widget review-listing">
        <!-- FIRST -->
        <ul class="comments-list">
          <li v-for="review in review" :key="review.slno">
            <div class="comment">
              <img class="avatar avatar-sm rounded-circle" alt="User Image" src="@/assets/img/patients/patient.jpg" />
              <div class="comment-body">
                <div class="meta-data">
                  <span class="comment-author">{{ review.firstname }} {{ review.lastname }} <p
                      class="recommended inline-elements" v-if="review.recommendyn === 'Y'">
                      <i class="far fa-thumbs-up"></i> Recommended
                    </p></span>
                  <div class="rating inline-elements">

                    {{ getDaysAgo(review.createddate) }}
                  </div>
                  <!-- <span class="comment-date"> {{ getDaysAgo(review.createddate) }}</span> -->
                </div>
                <p class="recommended inline-elements">
                  <!-- <i class="far fa-thumbs-up"></i> -->
                  <span v-html="getStarRating(review.rating)"></span>
                </p>
                <p class="comment-content">
                  {{ review.titlename }} - {{ review.comments }}
                </p>

              </div>
            </div>
          </li>
          <!-- /Comment List -->
        </ul>

        <!-- Show All -->
        <!-- <div class="all-feedback text-center">
          <a href="javascript:;" class="btn btn-primary btn-sm">
            Show all feedback <strong>(167)</strong>
          </a>
        </div> -->
        <!-- /Show All -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import VueMultiselect from "vue-multiselect";
export default {
  props: {
    doctor: {
      type: Object,
      default: {}
    },
    review: {
      type: Array,
      default: []
    },
    pending: {
      type: Array,
      default: []
    }

  },

  components: {
    VueMultiselect
  },
  data() {
    return {
      patientId: '',
      showShareModal: false,
      termscondtion_checked: true,
      detailedreview_visible: false,
      selectedRating: null,
      titleInput: '',
      detailedInput: '',
      recommendInput: null,
      showSuccessAlert: false,
      successMessage: "",
      usertype: sessionStorage.getItem('usertype'),

    };
  },
  mounted() {
    this.patientId = sessionStorage.getItem("userdbid");
  },
  methods: {
    getDaysAgo(createdDateStr) {
      const createdDate = new Date(createdDateStr);
      const currentDate = new Date();
      const timeDifference = currentDate - createdDate;
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      return `${daysDifference} days ago`;
    },
    toggleTermsConditionCheckbox() {
      this.termscondtion_checked = !this.termscondtion_checked;
    },
    toggleDetailedReview() {
      this.detailedreview_visible = !this.detailedreview_visible;
    },
    recommend(input) {
      this.recommendInput = input;
    },

    async submitReview() {
      try {
        this.patientId = sessionStorage.getItem("userdbid")
        const payload = {
          patientid: parseInt(this.patientId),
          titlename: this.titleInput,
          comments: this.detailedInput,
          rating: this.selectedRating,
          recommendyn: this.recommendInput
        };
        const response = await axios.post(`doctors/${this.doctor.doctorid}/doctorreview`, payload);
        this.$emit('review-submitted');
        this.showSuccessAlert = true;
        this.successMessage = "Review submitted successfully!";
        this.selectedRating = null;
        this.titleInput = '';
        this.detailedInput = '';
        this.recommendInput = null;
        this.termscondtion_checked = true;
      } catch (error) {
        console.error('Error submitting review:', error);
      }
    },
    getStarRating(rating) {
      const fullStar = '<i class="fas fa-star"></i>';
      const emptyStar = '<i class="far fa-star"></i>';
      let starRating = '';
      for (let i = 1; i <= 5; i++) {
        if (i <= rating) {
          starRating += fullStar;
        }  else {
          starRating += emptyStar;
        }
      }
      return starRating;
    },
  },
};
</script>
<style>
.fa-check-square,
.fa-square {
  font-size: 14px;
}

.hollow-icon {
  color: #bbb;
  background-color: transparent;
}

.responsive-margin {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .responsive-margin {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.inline-elements {
  display: inline-block;
  vertical-align: middle;
}

.rating i.fas.fa-star,
.rating i.fas.fa-star-half-alt,
.rating i.far.fa-star {
  color: #f8ce0b;
}
</style>
