import {createRouter, createWebHistory} from 'vue-router';

import Index from '@/views/frontend/pages/home/index.vue'
import AboutUs from '@/views/frontend/pages/pages/about-us.vue'

import ContactUs from '@/views/frontend/pages/pages/contact-us.vue'
import VoiceCall from '@/views/frontend/pages/pages/call/voice-call.vue'
import VideoCall from '@/views/frontend/pages/pages/call/video-call.vue'
import Invoices from '@/views/frontend/pages/pages/invoices/invoices.vue'
import InvoiceView from '@/views/frontend/pages/pages/invoices/invoice-view.vue'
import LoginEmail from '@/views/frontend/pages/pages/authentication/login-email.vue'
import LoginPage from '@/views/frontend/pages/pages/authentication/login.vue'
import Mobileotp from '@/views/frontend/pages/pages/authentication/mobile-otp.vue'
import LoginPhone from '@/views/frontend/pages/pages/authentication/login-phone.vue'
import DoctorSignup from '@/views/frontend/pages/pages/authentication/doctor-signup.vue'
import PatientSignup from '@/views/frontend/pages/pages/authentication/patient-signup.vue'
import ForgotPassword from '@/views/frontend/pages/pages/authentication/forgot-password.vue'
import ForgotPasswordTwo from '@/views/frontend/pages/pages/authentication/forgot-password2.vue'
import LoginEmailOtp from '@/views/frontend/pages/pages/authentication/login-email-otp.vue'
import EmailOtp from '@/views/frontend/pages/pages/authentication/email-otp.vue'
import SignupOtp from '@/views/frontend/pages/pages/authentication/signup-otp.vue'
import LoginPhoneOtp from '@/views/frontend/pages/pages/authentication/login-phone-otp.vue'
import DoctorLogin from '@/views/frontend/pages/pages/authentication/doctor-login.vue'
import Error404 from '@/views/frontend/pages/pages/errorpages/error-404.vue'
import Error500 from '@/views/frontend/pages/pages/errorpages/error-500.vue'
import BlankPage from '@/views/frontend/pages/pages/blank-page.vue'
import Pricing from '@/views/frontend/pages/pages/pricing.vue'
import Privacypolicy from '@/views/frontend/pages/pages/privacy-policy.vue'
import Maintenance from '@/views/frontend/pages/pages/maintenance.vue'
import ComingSoon from '@/views/frontend/pages/pages/coming-soon.vue'
import TermsCondition from '@/views/frontend/pages/pages/terms-condition.vue'
import BlogList from '@/views/frontend/pages/blog/blog-list.vue'
import FAQ from '@/views/frontend/pages/pages/faq.vue'
import BlogGrid from '@/views/frontend/pages/blog/blog-grid.vue'
import BlogDetails from '@/views/frontend/pages/blog/blog-details.vue'
import PharmacyIndex from '@/views/frontend/pages/pharmacymenu/pharmacy-index/pharmacy-index.vue'
import PharmacyDetails from '@/views/frontend/pages/pharmacymenu/details/pharmacy-details.vue'
import PharmacySearch from '@/views/frontend/pages/pharmacymenu/pharmacy-search.vue'
import CancelAppointment from '@/views/frontend/pages/pages/appointment-cancellation/cancel-appointment'
import ProductAll from '@/views/frontend/pages/pharmacymenu/product/product-all'
import Product_Description from '@/views/frontend/pages/pharmacymenu/product-description/product-description.vue'
import Cart from '@/views/frontend/pages/pharmacymenu/cart.vue'
import PaymentSuccess from '@/views/frontend/pages/pharmacymenu/payment-success.vue'
import Payment from '@/views/frontend/pages/pharmacymenu/payment.vue'
import PharmacyRegister from '@/views/frontend/pages/pharmacymenu/pharmacy-register.vue'
import ProductCheckout from '@/views/frontend/pages/pharmacymenu/product-checkout.vue'

import Change_Password from '@/views/frontend/pages/patients/change-password.vue'
import Profile_Settings from '@/views/frontend/pages/patients/profile-settings.vue'
import Chat from '@/views/frontend/pages/patients/chat/chat.vue'
import Favourites from '@/views/frontend/pages/patients/favourites.vue'
import Booking_Success from '@/views/frontend/pages/patients/booking-success.vue'
import Booking_Success_one from '@/views/frontend/pages/patients/booking-success-one.vue'
import Booking_Confirm from '@/views/frontend/pages/patients/booking-confirm.vue'
import Checkout from '@/views/frontend/pages/patients/checkout.vue'
import Paitentdetails from '@/views/frontend/pages/patients/patient-details.vue'
import Patient_Dashboard from '@/views/frontend/pages/patients/dashboard/patient-dashboard.vue'
import Patient_Token from '@/views/frontend/pages/patients/dashboard/patient-token.vue'
import map_grid from '@/views/frontend/pages/patients/doctors/map-grid.vue'
import map_list from '@/views/frontend/pages/patients/doctors/maplist/map-list.vue'
import Search from '@/views/frontend/pages/patients/searchdoctor/search2/search-2.vue'
import Search_2 from '@/views/frontend/pages/patients/searchdoctor/search2/search-2.vue'
import Booking from '@/views/frontend/pages/patients/booking/booking.vue'
import Booking_2 from '@/views/frontend/pages/patients/booking/booking-2.vue'
import BookingClinic from '@/views/frontend/pages/clinics/bookingclinic.vue'
import Doctor_Profile from '@/views/frontend/pages/patients/doctorprofile/doctorprofile.vue'
import Doctor_Search_Grid from '@/views/frontend/pages/patients/searchdoctor/search2/doctor-search-grid.vue'
import Pharmacy_Register_Step1 from '@/views/frontend/pages/pharmacymenu/pharmacy-register-step1.vue'
import Pharmacy_Register_Step2 from '@/views/frontend/pages/pharmacymenu/pharmacy-register-step2.vue'
import Pharmacy_Register_Step3 from '@/views/frontend/pages/pharmacymenu/pharmacy-register-step3.vue'
import Medical_Details from '@/views/frontend/pages/patients/medical-details.vue'
import Medical_Records from '@/views/frontend/pages/patients/medical-records/medical-records.vue'
import Orders_List from '@/views/frontend/pages/patients/orders-list.vue'
import Dependent from '@/views/frontend/pages/patients/dependent/dependent.vue'
import Patient_Accounts from '@/views/frontend/pages/patients/accounts/accounts.vue'

//Doctor Pages
import DoctorIndex from '@/views/frontend/pages/doctorhome/index.vue'
import Reviews from '@/views/frontend/pages/doctors/reviews.vue'
import DoctorDashBoard from '@/views/frontend/pages/doctors/doctor-dashboard.vue'
import AddNewDoctor from '@/views/frontend/pages/doctors/add-new-doctor.vue'
import DoctorMedicine from '@/views/frontend/pages/doctors/doctor-medicine.vue'
import DoctorClinicSettings from '@/views/frontend/pages/doctors/doctor-clinic-settings.vue'
import DoctorRegister from '@/views/frontend/pages/doctors/doctor-register.vue'
import DoctorRegisterStep1 from '@/views/frontend/pages/doctors/doctor-register-step1.vue'
import DoctorRegisterStep2 from '@/views/frontend/pages/doctors/doctor-register-step2.vue'
import DoctorRegisterStep3 from '@/views/frontend/pages/doctors/doctor-register-step3.vue'
import Appointments from '@/views/frontend/pages/doctors/doctor-appointment.vue'
import Reports from '@/views/frontend/pages/doctors/doctors-report.vue'
import ClinicsReports from '@/views/frontend/pages/clinics/clinics-doctors-report.vue'
import PatientsList from '@/views/frontend/pages/doctors/patients-list.vue'
import DoctorPatientProfile from '@/views/frontend/pages/doctors/patient-profile/doctor-patientprofile.vue'
import AddPrescription from '@/views/frontend/pages/doctors/add-prescription.vue'
import AddBilling from '@/views/frontend/pages/doctors/add-billing.vue'
import Editbilling from '@/views/frontend/pages/doctors/edit-billing.vue'
import Frontcomponents from '@/views/frontend/pages/pages/components.vue'
import Signupsuccess from '@/views/frontend/pages/pages/signup-success.vue'
import FrontendSignup from '@/views/frontend/pages/pages/signup.vue'
import Consultation from '@/views/frontend/pages/pages/consultation.vue'
import ChatDoctor from '@/views/frontend/pages/doctors/chat/chat-doctor.vue'
import DoctorProfileSettings from '@/views/frontend/pages/doctors/profile-settings/doctorprofile-settings.vue'
import DoctorBlog from '@/views/frontend/pages/doctors/doctorblog/doctor-blog.vue'
import DoctorPendingBlog from '@/views/frontend/pages/doctors/doctorblog/doctor-pendingblog.vue'
import DoctorAddBlog from '@/views/frontend/pages/doctors/doctorblog/doctor-addblog.vue'
import DoctorEditlog from '@/views/frontend/pages/doctors/doctorblog/doctor-editblog.vue'
import EditPrescription from '@/views/frontend/pages/doctors/edit-prescription.vue'
import Accounts from '@/views/frontend/pages/doctors/accounts.vue'
import SocialMedia from '@/views/frontend/pages/doctors/social-media.vue'
import TimeOff from '@/views/frontend/pages/doctors/time-off.vue'
import DoctorChangePassword from '@/views/frontend/pages/doctors/doctor-changepassword.vue'
import Register from '@/views/frontend/pages/doctors/register.vue'
import PatientRegisterstep1 from '@/views/frontend/pages/doctors/patient-register-step1.vue'
import PatientRegisterstep2 from '@/views/frontend/pages/doctors/patient-register-step2.vue'
import PatientRegisterstep3 from '@/views/frontend/pages/doctors/patient-register-step3.vue'
import PatientRegisterstep4 from '@/views/frontend/pages/doctors/patient-register-step4.vue'
import PatientRegisterstep5 from '@/views/frontend/pages/doctors/patient-register-step5.vue'
import ScheduleTimings from '@/views/frontend/pages/doctors/scheduletiming/schedule-timings.vue'
import AppointmentbyDoctor from '@/views/frontend/pages/doctors/appointmentbydoctor/appointmentby-doc.vue'
import AvailableTiming from '@/views/frontend/pages/doctors/scheduletiming/availabletiming.vue'
import OnboardingEmaill from '@/views/frontend/pages/onboard/onboarding-email.vue'
import OnboardingEmaillOtp from '@/views/frontend/pages/onboard/onboarding-email-otp.vue'
import OnboardingPhone from '@/views/frontend/pages/onboard/onboarding-phone.vue'
import OnboardingPhoneOtp from '@/views/frontend/pages/onboard/onboarding-phone-otp.vue'
import OnBoardingPassword from '@/views/frontend/pages/onboard/onboarding-password.vue'
import OnBoardingIdentity from '@/views/frontend/pages/onboard/onboarding-identity.vue'
import OnBoardingPersonalize from '@/views/frontend/pages/onboard/onboarding-personalize.vue'
import OnBoardingVerification from '@/views/frontend/pages/onboard/onboarding-verification.vue'
import OnBoardingPayments from '@/views/frontend/pages/onboard/onboarding-payments.vue'
import OnBoardingPreferences from '@/views/frontend/pages/onboard/onboarding-preferences.vue'
import OnBoardingAvailability from '@/views/frontend/pages/onboard/onboarding-availability.vue'
import OnBoardingConsultation from '@/views/frontend/pages/onboard/onboarding-consultation.vue'
import OnBoardingCost from '@/views/frontend/pages/onboard/onboarding-cost.vue'
import OnBoardingStepVerify from '@/views/frontend/pages/onboard/onboarding-email-step-2-verify.vue'
import OnBoardingLock from '@/views/frontend/pages/onboard/onboarding-lock.vue'
import OnBoardingVerifyAccount from '@/views/frontend/pages/onboard/onboarding-verify-account.vue'
import PatientDetails from '@/views/frontend/pages/patients/patient-details.vue';
import PhoneNumber from '@/views/frontend/pages/patients/phone-number.vue';

// clinic
import ClinicDashboard from '@/views/frontend/pages/clinics/clinic-dashboard.vue';
import ClinicDoctorDashboard from '@/views/frontend/pages/clinics/clinic-doctor-dashboard.vue';
import AddClinicAppointment from '@/views/frontend/pages/clinics/add-clinic-appointment.vue'
import ClinicAppointmentHistory from '@/views/frontend/pages/clinics/clinic-appointment-history.vue'
//import ClinicScheduleTimings from '@/views/frontend/pages/clinics/scheduletiming/clinic-schedule-timings.vue'
import ClinicAvailableTimings from '@/views/frontend/pages/clinics/scheduletiming/clinic-available-timings.vue'
import ClinicChangePassword from '@/views/frontend/pages/clinics/clinic-changepassword';

//referee

import RefereeLogin from '@/views/frontend/pages/referee/referee-login';
import RefereeDashboard from '@/views/frontend/pages/referee/referee-dashboard';
import RefereeChangePassword from '@/views/frontend/pages/referee/referee-change-password';
import RefereeAddDoctor from '@/views/frontend/pages/referee/referee-add-doctor';
import MyReferrals from '@/views/frontend/pages/referee/my-referrals';
import MyReports from '@/views/frontend/pages/referee/my-reports';

import store from '@/store';
import { Hub } from "@aws-amplify/core"
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

const routes = [

/**************** Frontend  *************/
    {
        path: '/',
        name: 'index',
        component: Index,
    },
    {
        path: '/patient-details',
        name: 'patient-details',
        component: PatientDetails,
      },
    {
        path: '/about-us',
        name: 'about-us',
        component: AboutUs
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: ContactUs
    },
    {
        path: '/voice-call',
        name: 'voice-call',
        component: VoiceCall
    },
    {
        path: '/video-call',
        name: 'video-call',
        component: VideoCall
    },
    {
        path: '/invoices',
        name: 'invoices',
        component: Invoices
    },
    {
        path: '/invoice-view',
        name: 'invoice-view',
        component: InvoiceView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage
    },{
        path: '/mobile-otp',
        name: 'mobile-otp',
        component: Mobileotp
    },
    {
        path: '/login-email',
        name: 'login-email',
        component: LoginEmail
    },
    {
        path: '/login-phone',
        name: 'login-phone',
        component: LoginPhone
    },
    {
        path: '/doctor-signup',
        name: 'doctor-signup',
        component: DoctorSignup
    },
    {
        path: '/patient-signup',
        name: 'patient-signup',
        component: PatientSignup
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword
    },
    {
        path: '/forgot-password2',
        name: 'forgot-password2',
        component: ForgotPasswordTwo
    },
    {
        path: '/login-email-otp',
        name: 'login-email-otp',
        component: LoginEmailOtp
    },
    {
        path: '/email-otp',
        name: 'email-otp',
        component: EmailOtp
    },
    {
        path: '/signup-otp',
        name: 'signup-otp',
        component: SignupOtp
    },
    {
        path: '/login-phone-otp',
        name: 'login-phone-otp',
        component: LoginPhoneOtp
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: Error404
    },
    {
        path: '/error-500',
        name: 'error-500',
        component: Error500
    },
    {
        path: '/blank-page',
        name: 'blank-page',
        component: BlankPage
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: Pricing
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: Privacypolicy
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: Maintenance
    },
    {
        path: '/coming-soon',
        name: 'coming-soon',
        component: ComingSoon
    },
    {
        path: '/terms-condition',
        name: 'terms-condition',
        component: TermsCondition
    },
    {
        path: '/faq',
        name: 'faq',
        component: FAQ
    },
    {
        path: '/blog-list',
        name: 'blog-list',
        component: BlogList
    },
    {
        path: '/blog-grid',
        name: 'blog-grid',
        component: BlogGrid
    },
    {
        path: '/blog-details',
        name: 'blog-details',
        component: BlogDetails
    },
    {
        path: '/pharmacy-index',
        name: 'pharmacy-index',
        component: PharmacyIndex
    },
    {
        path: '/pharmacy-details',
        name: 'pharmacy-details',
        component: PharmacyDetails
    },
    {
        path: '/pharmacy-search',
        name: 'pharmacy-search',
        component: PharmacySearch
    },
    {
        path: '/product-all',
        name: 'product-all',
        component: ProductAll
    },
    {
        path: '/product-description',
        name: 'product-description',
        component: Product_Description
    },
    {
        path: '/cart',
        name: 'cart',
        component: Cart
    },
    {
        path: '/payment-success',
        name: 'payment-success',
        component: PaymentSuccess
    },
    {
        path: '/payment',
        name: 'payment',
        component: Payment
    },
    {
        path: '/pharmacy-register',
        name: 'pharmacy-register',
        component: PharmacyRegister
    },
    {
        path: '/product-checkout',
        name: 'product-checkout',
        component: ProductCheckout
    },

    {
        path: '/change-password',
        name: 'change-password',
        component: Change_Password,
        meta: { requiresLogin: true, profile: 'Patient' },
    },

    {
        path: '/profile-settings',
        name: 'profile-settings',
        component: Profile_Settings,
        meta: { requiresLogin: true, profile: 'Patient' },
    },

    {
        path: '/chat',
        name: 'chat',
        component: Chat,
        meta: { requiresLogin: true, profile: 'Patient' },
    },
    {
        path: '/favourites',
        name: 'favourites',
        component: Favourites,
        meta: { requiresLogin: true, profile: 'Patient' },
    },
    {
        path: '/booking-success',
        name: 'booking-success',
        component: Booking_Success
    },
    {
        path: '/booking-success-one/:appointmentId',
        name: 'booking-success-one',
        component: Booking_Success_one
    },
    {
        path: '/booking-confirm',
        name: 'booking-confirm',
        component: Booking_Confirm
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: Checkout
    },
    {
        path: '/patient-details/:doctorId',
        name: 'patient-details',
        component: Paitentdetails
    },
    {
        path: '/patient-dashboard',
        name: 'patient-dashboard',
        component: Patient_Dashboard,
        meta: { requiresLogin: true, profile: 'Patient' },
    },
    {
        path: '/patient-token',
        name: 'patient-token',
        component: Patient_Token,
        // meta: { requiresLogin: true, profile: 'Patient' },
    },
    {
        path: '/map-grid',
        name: 'map-grid',
        component: map_grid
    },
    {
        path: '/map-list',
        name: 'map-list',
        component: map_list
    },
    {
        path: '/search',
        name: 'search',
        component: Search
    },
    {
        path: '/search-2',
        name: 'search-2',
        component: Search_2
    },
    {
        path: '/booking/:id',
        name: 'booking',
        component: Booking_2
    },
    {
        path: '/bookingclinic/:id',
        name: 'bookingclinic',
        component: BookingClinic
    },
    {
        path: '/doctor/:id',
        name: 'doctor-profile',
        component: Doctor_Profile
    },
    {
        path: '/booking-2',
        name: 'booking-2',
        component: Booking_2
    },
    {
        path: '/reviews',
        name: 'reviews',
        component: Reviews
    },
    {
        path: '/doctor-search-grid',
        name: 'doctor-search-grid',
        component: Doctor_Search_Grid
    },
    {
        path: '/pharmacy-register-step1',
        name: 'pharmacy-register-step1',
        component: Pharmacy_Register_Step1
    },
    {
        path: '/pharmacy-register-step2',
        name: 'pharmacy-register-step2',
        component: Pharmacy_Register_Step2
    },
    {
        path: '/pharmacy-register-step3',
        name: 'pharmacy-register-step3',
        component: Pharmacy_Register_Step3
    },
    {
        path: '/medical-details',
        name: 'medical-details',
        component: Medical_Details
    },
    {
        path: '/medical-records',
        name: 'medical-records',
        component: Medical_Records,
        meta: { requiresLogin: true, profile: 'Patient' },
    },
    {
        path: '/orders-list',
        name: 'orders-list',
        component: Orders_List,
        meta: { requiresLogin: true, profile: 'Patient' },
    },
    {
        path: '/dependent',
        name: 'dependent',
        component: Dependent,
        meta: { requiresLogin: true, profile: 'Patient' },
    },
    {
        path: '/patient-accounts',
        name: 'patient-accounts',
        component: Patient_Accounts,
        meta: { requiresLogin: true, profile: 'Patient' },
    },
    

    {
        path: '/doctor-info',
        name: 'doctor-info',
        component: DoctorIndex,
    },
    {
        path: '/doctor-dashboard',
        name: 'doctor-dashboard',
        component: DoctorDashBoard,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/doctor-medicine',
        name: 'doctor-medicine',
        component: DoctorMedicine,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/doctor-clinic-settings',
        name: 'doctor-clinic-settings',
        component: DoctorClinicSettings,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/add-new-doctor',
        name: 'add-new-doctor',
        component: AddNewDoctor,
    },
    {
        path: '/doctor-register',
        name: 'doctor-register',
        component: DoctorRegister
    },
    {
        path: '/doctor-register-step1',
        name: 'doctor-register-step1',
        component: DoctorRegisterStep1
    },
    {
        path: '/doctor-register-step2',
        name: 'doctor-register-step2',
        component: DoctorRegisterStep2
    },
    {
        path: '/doctor-register-step3',
        name: 'doctor-register-step3',
        component: DoctorRegisterStep3
    },
    {
        path: '/appointments',
        name: 'appointments',
        component: Appointments,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/doctors-report',
        name: 'reports',
        component: Reports,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/schedule-timings',
        name: 'schedule-timings',
        component: ScheduleTimings,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/available-timings',
        name: 'available-timings',
        component: AvailableTiming,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/clinic-available-timings',
        name: 'clinic-available-timings',
        component: ClinicAvailableTimings,
        meta: { requiresLogin: true, profile: 'Clinic' },
    },
    {
        path: '/addappointment',
        name: 'addappointment',
        component: AppointmentbyDoctor,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/my-patients',
        name: 'my-patients',
        component: PatientsList,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/patient-profile/:patientid',
        name: 'patient-profile',
        component: DoctorPatientProfile,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/add-prescription',
        name: 'add-prescription',
        component: AddPrescription,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/add-billing',
        name: 'add-billing',
        component: AddBilling,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/edit-billing',
        name: 'edit-billing',
        component: Editbilling,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/components',
        name: 'components',
        component: Frontcomponents
    }, 
    {
        path: '/signup-success',
        name: 'signup-success',
        component: Signupsuccess
    },
    {
        path: '/signup',
        name: 'signup',
        component: FrontendSignup
    },
    {
        path: '/consultation',
        name: 'consultation',
        component: Consultation
    },
    {
        path: '/chat-doctor',
        name: 'chat-doctor',
        component: ChatDoctor
    },
    {
        path: '/doctor-profile-settings',
        name: 'doctor-profile-settings',
        component: DoctorProfileSettings
    },
    {
        path: '/doctor-blog',
        name: 'doctor-blog',
        component: DoctorBlog
    },
    {
        path: '/doctor-pending-blog',
        name: 'doctor-pending-blog',
        component: DoctorPendingBlog
    },
    {
        path: '/doctor-add-blog',
        name: 'doctor-add-blog',
        component: DoctorAddBlog,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/edit-blog',
        name: 'edit-blog',
        component: DoctorEditlog,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/edit-prescription',
        name: 'edit-prescription',
        component: EditPrescription,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/available-timings',
        name: 'available-timings',
        component: AvailableTiming,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/accounts',
        name: 'accounts',
        component: Accounts,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/social-media',
        name: 'social-media',
        component: SocialMedia,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/time-off',
        name: 'time-off',
        component: TimeOff,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/doctor-change-password',
        name: 'doctor-changepassword',
        component: DoctorChangePassword,
        meta: { requiresLogin: true, profile: 'Doctor' },
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/patient-register-step1',
        name: 'patient-register-step1',
        component: PatientRegisterstep1
    },
    {
        path: '/patient-register-step2',
        name: 'patient-register-step2',
        component: PatientRegisterstep2
    },
    {
        path: '/patient-register-step3',
        name: 'patient-register-step3',
        component: PatientRegisterstep3
    },
    {
        path: '/patient-register-step4',
        name: 'patient-register-step4',
        component: PatientRegisterstep4
    },
    {
        path: '/patient-register-step5',
        name: 'patient-register-step5',
        component: PatientRegisterstep5
    },
    {
        path: '/onboarding-email',
        name: '/onboarding-email',
        component: OnboardingEmaill
    },
    {
        path: '/onboarding-email-otp',
        name: '/onboarding-email-otp',
        component: OnboardingEmaillOtp
    },
    {
        path: '/onboarding-phone',
        name: '/onboarding-phone',
        component: OnboardingPhone
    },
    {
        path: '/onboarding-phone-otp',
        name: '/onboarding-phone-otp',
        component: OnboardingPhoneOtp
    },
    {
        path: '/onboarding-password',
        name: '/onboarding-password',
        component: OnBoardingPassword
    },
    {
        path: '/onboarding-identity',
        name: '/onboarding-identity',
        component: OnBoardingIdentity
    },
    {
        path: '/onboarding-personalize',
        name: '/onboarding-personalize',
        component: OnBoardingPersonalize
    },
    {
        path: '/onboarding-verification',
        name: '/onboarding-verification',
        component: OnBoardingVerification
    },
    {
        path: '/onboarding-payments',
        name: '/onboarding-payments',
        component: OnBoardingPayments
    },
    {
        path: '/onboarding-preferences',
        name: '/onboarding-preferences',
        component: OnBoardingPreferences
    },
    {
        path: '/onboarding-availability',
        name: '/onboarding-availability',
        component: OnBoardingAvailability
    },
    {
        path: '/onboarding-consultation',
        name: '/onboarding-consultation',
        component: OnBoardingConsultation
    },
    {
        path: '/onboarding-cost',
        name: '/onboarding-cost',
        component: OnBoardingCost
    },
    {
        path: '/onboarding-email-step-2-verify',
        name: '/onboarding-email-step-2-verify',
        component: OnBoardingStepVerify
    },
    {
        path: '/onboarding-lock',
        name: '/onboarding-lock',
        component: OnBoardingLock
    },
    {
        path: '/onboarding-verify-account',
        name: '/onboarding-verify-account',
        component: OnBoardingVerifyAccount
    },
    { 
        path: '/:pathMatch(.*)*',
        name: 'Error404',
        component: Error404
    },
    {
        path: '/clinic-dashboard',
        name: '/clinic-dashboard',
        component: ClinicDashboard,
        meta: { requiresLogin: true, profile: 'Clinic' },
    },
    {
        path: '/clinic-doctor-dashboard',
        name: '/clinic-doctor-dashboard',
        component: ClinicDoctorDashboard,
        meta: { requiresLogin: true, profile: 'Clinic' },
    },
    {
        path: '/add-clinic-appointment',
        name: 'add-clinic-appointment',
        component: AddClinicAppointment,
        meta: { requiresLogin: true, profile: 'Clinic' },
    },
    {
        path: '/clinic-appointment-history',
        name: 'clinic-appointment-history',
        component: ClinicAppointmentHistory,
        meta: { requiresLogin: true, profile: 'Clinic' },
    },
    {
        path: '/clinic-change-password',
        name: 'clinic-change-password',
        component: ClinicChangePassword,
        meta: { requiresLogin: true, profile: 'Clinic' },
    },
    {
        path: '/clinics-doctors-report',
        name: 'clinics-doctors-report',
        component: ClinicsReports,
        meta: { requiresLogin: true, profile: 'Clinic' },
    },
    {
        path: '/cancel-appointment',
        name: 'cancel-appointment',
        component: CancelAppointment
    },
    {
        path: '/phone-number/:doctorId',
        name: 'phone-number',
        component: PhoneNumber
    },
    {
        path: '/doctor-login',
        name: 'doctor-login',
        component: DoctorLogin
    },
    {
        path: '/referee-login',
        name: 'referee-login',
        component: RefereeLogin
    },
    {
        path: '/referee-dashboard',
        name: 'referee-dashboard',
        component: RefereeDashboard,
        meta: { requiresLogin: true, profile: 'Referee' },
    },
    {
        path: '/referee-change-password',
        name: 'referee-change-password',
        component: RefereeChangePassword,
        meta: { requiresLogin: true, profile: 'Referee' },
    },
    {
        path: '/referee-add-doctor',
        name: 'referee-add-doctor',
        component: RefereeAddDoctor,
        meta: { requiresLogin: true, profile: 'Referee' },
    },
    {
        path: '/my-referrals',
        name: 'my-referrals',
        component: MyReferrals,
        meta: { requiresLogin: true, profile: 'Referee' },
    },
    {
        path: '/my-reports',
        name: 'my-reports',
        component: MyReports,
        meta: { requiresLogin: true, profile: 'Referee' },
    },

    


]

/****** AWS Amplify ******/
import { Amplify } from 'aws-amplify';
import awsconfig from '@/aws-exports'; 
Amplify.configure(awsconfig);

import axios from "axios";

async function getUser() {
    return fetchAuthSession().then((data) => {
        if (data && data.tokens) {
            sessionStorage.setItem("useridpid", data.userSub);
            store.commit('setAuthUser', data);
            axios.defaults.headers.common['id-token'] = data.tokens.idToken;
            axios.defaults.headers.common['Authorization'] = "Bearer "+ data.tokens.accessToken;
            fetchUserAttributes().then((userAttributes) => {
                sessionStorage.setItem("useremail", userAttributes.email);
                sessionStorage.setItem("usergivenname", userAttributes.given_name);
                fetchDBId(data.userSub);
            }).catch((err) => {
                console.log(err);
                sessionStorage.clear("useridpid");
                store.commit('setAuthUser', null);
                return null;
            });
            return data;
        } else {
            sessionStorage.clear("useridpid");
            store.commit('setAuthUser', null);
            return null;
        }
    }).catch((err) => {
        console.log(err);
        sessionStorage.clear("useridpid");
        store.commit('setAuthUser', null);
        return null;
    });
}

async function fetchDBId(idpid) {
    try {
      //console.log('Fetching db ID with idpid:', idpid);
      const response = await axios.get(`/auth/identifier/${idpid}`);

      if (response.data && response.data.dbid) {
        sessionStorage.setItem('userdbid', response.data.dbid);
        const usertype = response.data.profile;
        sessionStorage.setItem('usertype', usertype);
        const preLoginRoute = sessionStorage.getItem("preLoginRoute");
        
        if (usertype === "Patient") {
            if (!preLoginRoute || !preLoginRoute.startsWith('/phone-number')) {
                router.push({path: '/'}); // Route to the home page if preLoginRoute is not set or is not phone-number related
            } else {
                sessionStorage.removeItem("preLoginRoute");
                return; // Stay on the same page
            }
        }else if (usertype === 'Doctor')
            router.push({path: '/doctor-dashboard'});
        else if (usertype === 'Clinic')
            router.push({path: '/clinic-dashboard'});
        else if (usertype === 'Referee')
            router.push({path: '/referee-dashboard'});
        else
            router.push({path: '/'});
      } else {
        console.error('DB ID not found in the response.');
      }

    } catch (error) {
      console.error('Error fetching db ID:', error);
    }
}
//let user = getUser();

export const router = createRouter({
    history: createWebHistory('/'),
    linkActiveClass: 'active',
    routes,
}); 

Hub.listen("auth", async (data) => {
    if (data.payload.event === 'signedOut'){
        store.commit('setAuthUser', null);
        const usertype = sessionStorage.getItem("usertype");
        sessionStorage.clear("useridpid");
        sessionStorage.clear('usertype');
        if (usertype === 'Doctor')
            router.push({path: '/doctor-login'});
        else if (usertype === 'Clinic')
            router.push({path: '/doctor-login'});
        else if (usertype === 'Referee')
            router.push({path: '/referee-login'});
        else
            router.push({path: '/'});
    } else if (data.payload.event === 'signedIn') {
        await getUser();
        
    }
});

router.beforeEach((to, from, next) => {
// Scroll to the top of the page
  window.scrollTo({ top: 0, behavior: 'smooth' });
  if (to.name === 'login') {
    sessionStorage.removeItem('preLoginRoute');
  }
  if (to.name === 'phone-number' && !sessionStorage.getItem("preLoginRoute")) {
    sessionStorage.setItem("preLoginRoute", '/phone-number');
  }
  if (to.matched.some(record => record.meta.requiresLogin)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!sessionStorage.getItem("useridpid")) {
      next({ name: 'login' })
    } else {
      const usertype = sessionStorage.getItem('usertype');
      if (to.matched.some(record => (usertype === record.meta.profile)))
        next(); // go to wherever I'm going
      else if(usertype === "Doctor")
        next({name: 'doctor-dashboard'})
      else if(usertype === "Clinic")
        next({name: 'clinic-dashboard'})
      else if(usertype === "Clinic")
        next({name: 'referee-dashboard'})
      else
        next({name: 'patient-dashboard'}) 
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }

});