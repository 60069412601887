<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb-doctor :title="title" :text="text" :text1="text1" />
    <!-- Page Content -->

    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar></doctorsidebar>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Schedule Timings</h4>
                    <div class="profile-box">
                      <div class="row align-items-center">
                        <div class="col-lg-4 mb-3">
                          <label class="durations">Timing Slot Duration</label>
                          <vue-select
                            v-model="selectedDuration"
                            :options="Sorting"
                            placeholder="15 mins"
                          ></vue-select>
                        </div>
                        <div class="col-lg-2 d-flex justify-content-end align-items-start">
                          <button class="btn btn-primary w-100 mt-2" @click="saveDuration">Save</button>
                        </div>
                        <div class="ml-auto">
                          <b-alert v-model="showDismissibleAlert" variant="danger">{{ error }}</b-alert>
                          <b-alert v-model="showDismissibleSuccess" variant="success">{{ success }}</b-alert>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card schedule-widget mb-0">
                            <!-- Schedule Header -->
                            <div class="schedule-header">
                              <!-- Schedule Nav -->
                              <div class="schedule-nav">
                                <ul class="nav nav-tabs nav-justified">
                                  <li class="nav-item">
                                    <a
                                      class="nav-link"
                                      data-bs-toggle="tab"
                                      href="#slot_sunday"
                                    >Sunday</a>
                                  </li>
                                  <li class="nav-item">
                                    <a
                                      class="nav-link active"
                                      data-bs-toggle="tab"
                                      href="#slot_monday"
                                    >Monday</a>
                                  </li>
                                  <li class="nav-item">
                                    <a
                                      class="nav-link"
                                      data-bs-toggle="tab"
                                      href="#slot_tuesday"
                                    >Tuesday</a>
                                  </li>
                                  <li class="nav-item">
                                    <a
                                      class="nav-link"
                                      data-bs-toggle="tab"
                                      href="#slot_wednesday"
                                    >Wednesday</a>
                                  </li>
                                  <li class="nav-item">
                                    <a
                                      class="nav-link"
                                      data-bs-toggle="tab"
                                      href="#slot_thursday"
                                    >Thursday</a>
                                  </li>
                                  <li class="nav-item">
                                    <a
                                      class="nav-link"
                                      data-bs-toggle="tab"
                                      href="#slot_friday"
                                    >Friday</a>
                                  </li>
                                  <li class="nav-item">
                                    <a
                                      class="nav-link"
                                      data-bs-toggle="tab"
                                      href="#slot_saturday"
                                    >Saturday</a>
                                  </li>
                                </ul>
                              </div>
                              <!-- /Schedule Nav -->
                            </div>
                            <!-- /Schedule Header -->

                            <!-- Schedule Content -->
                            <div class="tab-content schedule-cont">
                              <schedule-sunday
                                :scheduleData="scheduleData.sunday"
                                @open-edit-modal="handleOpenEditModal"
                                @open-add-clinic-modal="openAddTimeSlotRoute"
                              ></schedule-sunday>
                              <schedule-monday
                                :scheduleData="scheduleData.monday"
                                @open-edit-modal="handleOpenEditModal"
                                @open-add-clinic-modal="openAddTimeSlotRoute"
                              ></schedule-monday>
                              <schedule-tuesday
                                :scheduleData="scheduleData.tuesday"
                                @open-edit-modal="handleOpenEditModal"
                                @open-add-clinic-modal="openAddTimeSlotRoute"
                              ></schedule-tuesday>
                              <schedule-wednesday
                                :scheduleData="scheduleData.wednesday"
                                @open-edit-modal="handleOpenEditModal"
                                @open-add-clinic-modal="openAddTimeSlotRoute"
                              ></schedule-wednesday>
                              <schedule-thursday
                                :scheduleData="scheduleData.thursday"
                                @open-edit-modal="handleOpenEditModal"
                                @open-add-clinic-modal="openAddTimeSlotRoute"
                              ></schedule-thursday>
                              <schedule-friday
                                :scheduleData="scheduleData.friday"
                                @open-edit-modal="handleOpenEditModal"
                                @open-add-clinic-modal="openAddTimeSlotRoute"
                              ></schedule-friday>
                              <schedule-saturday
                                :scheduleData="scheduleData.saturday"
                                @open-edit-modal="handleOpenEditModal"
                                @open-add-clinic-modal="openAddTimeSlotRoute"
                              ></schedule-saturday>
                            </div>
                            <!-- /Schedule Content -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <doctorfooter />
  </div>
  <addslot ref="addslot" :selectedWeekday="selectedWeekday" :doctorId="doctorId" @slots-saved="fetchScheduleData(doctorId)"></addslot>
  <editslot :selectedDayData="selectedDayData" @slots-edited="fetchScheduleData(doctorId)"></editslot>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      title: "Schedule Timings",
      text: "Home",
      text1: "Schedule Timings",
      Sorting: [
        "-",
        "5 mins",
        "10 mins",
        "15 mins",
        "20 mins",
        "30 mins",
        "45 mins",
        "60 mins"
      ],

      scheduleData: [],
      selectedDayData: [],
      selectedWeekday: null,
      doctorId: null,
      doctorDetails: {},
      selectedDuration: null,
      showDismissibleAlert: false,
      showDismissibleSuccess: false,
      error: "",
      success: ""
    };
  },
  methods: {
    resetSuccessAlert() {
      setTimeout(() => {
        this.showDismissibleSuccess = false;
      }, 3000);
    },
    resetErrorAlert() {
      setTimeout(() => {
        this.showDismissibleAlert = false;
      }, 3000);
    },
    async fetchScheduleData(doctorId) {
      try {
        const response = await axios.get(`/doctors/${doctorId}/scheduletiming`);
        this.organizeScheduleData(response.data);
      } catch (error) {
        console.error("Error fetching schedule data:", error);
      }
    },
    organizeScheduleData(data) {
      const organizedData = {};

      data.forEach(slot => {
        const weekday = slot.weekday.toLowerCase();
        if (!organizedData[weekday]) {
          organizedData[weekday] = [];
        }
        organizedData[weekday].push(slot);
      });

      this.scheduleData = organizedData;
    },
    handleOpenEditModal(scheduleData) {
      this.selectedDayData = scheduleData;
    },
    openAddTimeSlotRoute(weekday) {
      this.selectedWeekday = weekday;
      this.$refs.addslot.addslot(this.selectedWeekday, this.doctorId);
    },
    setSelectedDurationFromDoctorDetails() {
      const appointmentDuration = this.doctorDetails.appointment_duration;
      const matchingOption = this.Sorting.find(option => {
        const duration = parseInt(option.split(" ")[0]);
        return duration === appointmentDuration;
      });
      this.selectedDuration = matchingOption ? matchingOption : this.Sorting[0];
    },
    async fetchDoctorDetails(doctorId) {
      try {
        const response = await axios.get(`doctors/${doctorId}`);
        this.doctorDetails = response.data;
        this.setSelectedDurationFromDoctorDetails();
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    },
    async updateDoctorDetails() {
      try {
        const durationParts = this.selectedDuration.split(" ");
        const durationValue = parseInt(durationParts[0]);

        const dataToUpdate = {
          ...this.doctorDetails,
          appointment_duration: durationValue
        };

        const response = await axios.put(
          `doctors/${this.doctorId}`,
          dataToUpdate
        );
        this.success = "Appointment duration updated successfully!";
        this.showDismissibleSuccess = true;
        this.resetSuccessAlert();
        this.fetchDoctorDetails(this.doctorId);
      } catch (error) {
        console.error("Error updating doctor details:", error);
        this.error =
          "Failed to update Appointment Duration. Please check details entered/Try again later.";
        this.showDismissibleAlert = true;
        this.resetErrorAlert();
      }
    },
    saveDuration() {
      if (!this.selectedDuration || this.selectedDuration === '-') {
        this.error = "Please select a valid duration before saving.";
        this.showDismissibleAlert = true;
        this.resetErrorAlert();
        return;
      }
      this.updateDoctorDetails();
    }
  },

  beforeMount() {
    const doctorId = sessionStorage.getItem("userdbid");
    this.fetchScheduleData(doctorId);
    this.fetchDoctorDetails(doctorId);
    this.doctorId = doctorId;
  }
};
</script>
