<template>
    <div id="chat-container">
        <deep-chat
            style="height: 400px; width: 300px; border-width: 1px; border-radius: 8px; border-style: solid; border-color: rgb(202, 202, 202); font-family: Inter, sans-serif, Avenir, Helvetica, Arial; font-size: 0.9rem; background-color: white; position: relative; overflow: hidden;"
            :bot-name="'YourDoc'"
            :user-name="'User'"
            :chat-title="'YourDoc Chat'"
            :welcome-message="'Hello! How can I assist you today?'"
            :input-placeholder="'Type your message here...'"
            :connect="connectOptions"
            @message-sent="handleMessageSent"
            @message-received="handleMessageReceived"
            auxiliaryStyle="
                ::-webkit-scrollbar {
                width: 10px;
                height: 10px;
                }
                ::-webkit-scrollbar-thumb {
                background-color: #54a7ff;
                border-radius: 5px;
                }"
        >
        <div
            style="
            width: 200px;
            background-color: #f3f3f3;
            border-radius: 10px;
            padding: 12px;
            padding-bottom: 15px;
            display: none;
            "
        >
            <div>
            <div style="text-align: center; margin-bottom: 8px; font-size: 16px">
                <b>YourDoc AI</b>
            </div>
            <div style="font-size: 15px; line-height: 20px">
                Hello! How can I assist you today?
            </div>
            </div>
        </div>
        </deep-chat>
    </div>
</template>

<script>
import { DeepChat } from 'deep-chat';
export default {
    name: 'Chat',
    data() {
        return {
            connectOptions: {
                url: process.env.VUE_APP_API_URL + '/chat', // Replace with your custom service endpoint
                method: 'POST', // HTTP method (GET, POST, etc.)
                headers: {
                    'Content-Type': 'application/json',
                    'api-key': process.env.VUE_APP_API_KEY // Replace with your actual authorization token if needed
                },
                body: (message) => JSON.stringify({ message }), // Customize the request body as needed
                onResponse: (response) => {
                    console.log('Chat response:', response);
                    return response.json(); // Ensure the response is parsed correctly
                }
            }
        };
    },
    mounted() {
        this.loadChatHistory();
    },
    methods: {
        handleMessageSent(message) {
            console.log(message)
            this.saveMessageToHistory({ type: 'user', message });
        },
        handleMessageReceived(message) {
            this.saveMessageToHistory({ type: 'bot', message });
        },
        saveMessageToHistory(message) {
            let chatHistory = JSON.parse(localStorage.getItem('chatHistory')) || [];
            chatHistory.push(message);
            localStorage.setItem('chatHistory', JSON.stringify(chatHistory));
        },
        loadChatHistory() {
            let chatHistory = JSON.parse(localStorage.getItem('chatHistory')) || [];
            chatHistory.forEach(msg => {
                // Assuming DeepChat has a method to add messages to the chat
                this.$refs.deepChat.addMessage(msg.type, msg.message);
            });
        }
    },
    xmounted() {
        const chatContainer = document.getElementById('chat-container');
        if(chatContainer) {
            console.log("Setting url to ", process.env.VUE_APP_API_URL)
            const ep = process.env.VUE_APP_API_URL + '/chat';
            try {
                const deepChatInstance = new DeepChat(chatContainer, {
                    botName: 'YourDoc',
                    userName: 'User',
                    chatTitle: 'YourDoc Chat',
                    welcomeMessage: 'Hello! How can I assist you today?', // Add a welcome message
                    inputPlaceholder: 'Type your message here...', // Placeholder for the input box
                    connect: {
                        endpoint: ep, // Replace with your custom service endpoint
                        method: 'POST', // HTTP method (GET, POST, etc.)
                        headers: {
                            'Content-Type': 'application/json',
                            'api_key': process.env.VUE_APP_API_KEY // Replace with your actual authorization token if needed
                        },
                        body: (message) => JSON.stringify({ message }) // Customize the request body as needed
                    },
                    demo: {"displayLoading": {"message": true, "history": {"small": true}}},
                });
                console.log('DeepChat instance created:', deepChatInstance);
                
            } catch (error) {
                console.error('Error initializing DeepChat:', error);
            }
        } else {
            console.error('Chat container not found');
        }
        
    },
};
</script>

<style scoped>
#chat-container {
    width: 300px;
    height: 400px; /* Adjust the height as needed */
    border: 1px solid #fff; /* Add a border to make it visible */
    background-color: #fff;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
}
</style>