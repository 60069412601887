<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1 :title="title" :text="text" :text1="text1" />

    <div class="content">
      <div class="container">
        <div class="back-link">
          <router-link to @click="$router.go(-1)">
            <i class="fa-solid fa-arrow-left-long"></i> Back
          </router-link>
        </div>

        <!-- Doctor Widget -->
        <div class="card" v-if="doctor">
          <div class="card-body">
            <div class="doctor-widget-one">
              <div class="doc-info-left">
                <div class="doctor-img">
                  <img :src="profileImageUrl || defaultImageUrl" class="img-fluid" :alt="doctor.doctorname" />
                  <div v-if="isPatient" class="favourite-btn">
                    <a href="javascript:void(0)" class="favourite-icon" :class="{ favourite: isFavorite(doctor) }"
                      @click="toggleFavourite(doctor)">
                      <i class="fas fa-heart"></i>
                    </a>
                  </div>
                </div>
                <div class="doc-info-cont">
                  <h4 class="doc-name">Dr. {{ doctor.doctorname }}</h4>
                  <p class="doc-speciality">{{ doctor.qualifications }}</p>
                  <p class="doc-department">
                    <img :src="getSpecialityImage(doctor.specialization)" class="img-fluid" alt="Speciality" />
                    {{ doctor.specialization }}
                  </p>
                  <div class="clinic-details">
                    <p class="doc-location">
                      <i class="fas fa-map-marker-alt"></i>
                      {{ doctor.clincorhospname }} -
                      <a target="_blank"
                        :href="'https://www.google.com/maps/search/?api=1&ll=12.9141%2C74.8560&query_place_id=&z=13&query=' + doctor.clincorhospname">Get
                        Directions</a>
                    </p>
                  </div>
                  <div class="clinic-services" v-for="service in doctor.services">
                    <span>{{ service }}</span>
                  </div>
                </div>
              </div>
              <div class="doc-info-right">
                <div class="clini-infos">
                  <ul>
                    <li>
                      <i class="fas fa-house-chimney-medical"></i>
                      {{ doctor.clincorhospname }},
                    </li>
                    <li>
                      <i class="fas fa-map-marker-alt"></i>
                      {{ doctor.address }}
                    </li>
                    <li>
                      <i class="fas fa-city"></i>
                      {{ doctor.area }}
                    </li>
                    <li>
                      <i class="fas fa-location-pin-lock"></i>
                      {{ doctor.areacode }}
                    </li>
                  </ul>
                </div>

                <div class="clinic-booking">
                  <router-link @click="bookAppointment(doctor.doctorid)" to class="apt-btn">Book
                    Appointment</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Doctor Widget -->
        <!-- Doctor Details Tab -->
        <div class="card">
          <div class="card-body pt-0">
            <!-- Tab Menu -->
            <nav class="user-tabs mb-4">
              <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                <li class="nav-item">
                  <a class="nav-link active" href="#doc_overview" data-bs-toggle="tab">Overview</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#doc_reviews" data-bs-toggle="tab">Reviews</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#doc_locations" data-bs-toggle="tab">Location & Timings</a>
                </li>

                <!-- <li class="nav-item">
                  <a class="nav-link" href="#doc_business_hours" data-bs-toggle="tab"
                    >Business Hours</a
                  >
                </li>-->
              </ul>
            </nav>
            <!-- /Tab Menu -->
            <!-- Tab Content -->
            <div class="tab-content pt-0">
              <!-- Overview Content -->
              <overview :doctor="doctor"></overview>
              <!-- /Overview Content -->
              <!-- Locations Content -->
              <reviews :review="review" :pending="pending" :doctor="doctor" @review-submitted="fetchReviews"></reviews>
              <locations :doctortimmings="doctortimmings" :doctorclinic="doctorclinic" :doctor="doctor"></locations>
              <!-- /Locations Content -->
              <!-- Reviews Content -->

              <!-- /Reviews Content -->
              <!-- Business Hours Content 
              <businesshours :doctor="doctor"></businesshours>-->
              <!-- /Business Hours Content -->
            </div>
          </div>
        </div>
        <!-- /Doctor Details Tab -->
      </div>
    </div>
    <indexfooter></indexfooter>

    <vue-easy-lightbox :visible="visible" :index="index" :imgs="galleryImages.map((image) => ({
      src: require(`@/assets/img/features/${image.src}`),
    }))
      " @hide="visible = false" @on-prev="handlePrev" @on-next="handleNext"></vue-easy-lightbox>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";
import axios from "axios";
import defaultImageUrl from "@/assets/img/doctors/doctor-thumb-01.jpg";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export default {
  computed: {
    isPatient() {
      return sessionStorage.getItem("usertype") === "Patient";
    }
  },
  components: {
    VueEasyLightbox
  },
  data() {
    return {
      title: "Doctor Profile",
      text: "Home",
      text1: "Doctor Profile",
      visible: false,
      index: 0,
      doctorid: 0,
      doctor: {},
      doctortimmings: [],
      defaultImageUrl: defaultImageUrl,
      profileImageUrl: "",
      favoriteDoctors: [],
      doctorclinic: [],
      patientid: null,
      review: [],
      pending: [],
      isFavourite: false,
      patientIdx: "",
      galleryImages: [
        {
          src: "feature-01.jpg"
        },
        {
          src: "feature-02.jpg"
        },
        {
          src: "feature-03.jpg"
        },
        {
          src: "feature-04.jpg"
        }
      ]
    };
  },
  mounted() {
    this.patientIdx = sessionStorage.getItem("userdbid");
  },
  methods: {
    async fetchDoctorProfileImage() {
      try {
        const response = await axios.get(
          `doctors/doctorimg/${this.doctorid}/download`,
          {
            responseType: "arraybuffer"
          }
        );
        const objectURL = window.URL.createObjectURL(new Blob([response.data]));
        this.profileImageUrl = objectURL;
      } catch (error) {
        this.profileImageUrl = defaultImageUrl;
        console.error("Error fetching patient profile image:", error);
      }
    },
    getSpecialityImage(specialization) {
      try {
        return require(`@/assets/img/specialities/${specialization}.png`);
      } catch (error) {
        console.error("Error loading image:", error);
        return require("@/assets/img/specialities/default-speciality1.png");
      }
    },
    bookAppointment(doctorid) {
      this.$store.dispatch("updateAppointmentDraft", {
        doctorid: doctorid,
        doctorImage: this.profileImageUrl
      });
      this.$router.push("/booking/" + doctorid);
    },
    async fetchDoctorData() {
      const PatientId = sessionStorage.getItem("userdbid");
      this.patientid = PatientId;
      const doctorId = this.$route.params.id;
      try {
        let docDetails = sessionStorage.getItem(`doctorDetails${doctorId}`);
        if (!docDetails) {
          const response = await axios.get(`doctors/${doctorId}`);
          docDetails = response.data;

          sessionStorage.setItem(
            `doctorDetails${doctorId}`,
            JSON.stringify(docDetails)
          );
        } else {
          docDetails = JSON.parse(docDetails);
        }
        this.doctor = docDetails;
        this.doctorid = docDetails.doctorid;
      } catch (error) {
        console.error("Error fetching doctor data:", error);
        /* if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        } else if (error.request) {
          console.error("Request data:", error.request);
        } else {
         */ console.error(
          "Error message:",
          error.message
        );
        //}
      }
      if (sessionStorage.getItem("usertype") === "Patient") {
        try {
          const response = await axios.get(
            `/patients/${this.patientid}/favourites`
          );
          //console.log("Favourites API response:", response.data);
          if (response.data && Array.isArray(response.data.doctors)) {
            this.favoriteDoctors = response.data.doctors.map(
              doctor => doctor.doctorid
            );
          } else {
            this.favoriteDoctors = [];
          }
        } catch (error) {
          console.error("Error fetching favorite doctors:", error);
          this.favoriteDoctors = [];
        }
      }
    },

    async toggleFavourite(doctor) {
      const isCurrentlyFavourite = this.isFavorite(doctor);

      try {
        await axios.post(
          `/patients/${this.patientid}/favourites/${doctor.doctorid}`
        );
        this.updateFavoriteDoctorsList(doctor.doctorid, !isCurrentlyFavourite);
      } catch (error) {
        console.error("Error toggling favorite status:", error);
      }
    },

    isFavorite(doctor) {
      return this.favoriteDoctors.includes(doctor.doctorid);
    },

    updateFavoriteDoctorsList(doctorId, isFavorite) {
      if (isFavorite) {
        this.favoriteDoctors.push(doctorId);
      } else {
        const index = this.favoriteDoctors.indexOf(doctorId);
        if (index !== -1) {
          this.favoriteDoctors.splice(index, 1);
        }
      }
    },
    show(index) {
      this.index = index;
      this.visible = true;
    },
    handlePrev(oldIndex, newIndex) {
      // console.log("when prev btn click or user swipe right ----");
      // console.log("oldIndex of imgs:", oldIndex);
      // console.log("newIndex of imgs:", newIndex);
    },
    handleNext(oldIndex, newIndex) {
      // console.log("when next btn click or user swipe left ----");
      // console.log("oldIndex of imgs:", oldIndex);
      // console.log("newIndex of imgs:", newIndex);
    },
    async fetchScheduleData() {
      try {
        const response = await axios.get(
          `/doctors/${this.doctorid}/scheduletiming`
        );
        this.doctortimmings = response.data;
        //console.log("timmings is ", this.doctortimmings);
      } catch (error) {
        console.error("Error fetching schedule data:", error);
      }
    },
    async fetchReviews() {
      try {
        const response = await axios.get(
          `/doctors/${this.doctorid}/doctorreview`
        );

        if (response.data) {
          this.review = response.data.filter(test => test.approval === "Y");
          this.pending = response.data.filter(
            test => test.approval === "P" && this.patientIdx == test.patientid
          );
        } else {
          console.error("No reviews found in the response.");
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    },
    async fetchDoctorClinicInfo() {
      try {
        const response = await axios.get(
          `/doctors/${this.doctorid}/clinicids?details=true`
        );
        this.doctorclinic = response.data;
      } catch (error) {
        console.error("Error fetching schedule data:", error);
      }
    },
  },
  async beforeMount() {
    try {
      await this.fetchDoctorData();
      await this.fetchReviews();
      await this.fetchScheduleData();
      await this.fetchDoctorProfileImage();
      await this.fetchDoctorClinicInfo();
    } catch (error) {
      console.error("Error in beforeMount initialization:", error);
    }
  },
};
</script>