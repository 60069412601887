// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../node_modules/vue-loader/dist/stylePostLoader.js!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[2]!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[3]!../node_modules/@fortawesome/fontawesome-free/css/all.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#robot-icon{font-size:3rem;cursor:pointer;position:fixed;bottom:20px;right:20px;z-index:1000;color:#0d82ff}#chat-container{height:500px;bottom:75px;right:20px;z-index:999;background:#fff;border:1px solid #ccc;border-radius:10px;box-shadow:0 0 10px rgba(0,0,0,.1)}#chat-container,#loading{width:100%;position:fixed}#loading{height:100%;top:0;left:0;background-color:#fff;z-index:9999;display:flex;justify-content:center;align-items:center;flex-direction:column}.preloader-gif{width:120px;height:120px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
