
<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <referee-header></referee-header>
    <breadcrumb-referee :title="title" :text="text" :text1="text1" />

    <!-- Search Bar -->
    

    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <referee-sidebar></referee-sidebar>
          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="search-bar">
            <div class="d-flex justify-content-between align-items-center">
                <!-- Search Input Group -->
                <div class="input-group">
                    <input
                        type="text"
                        class="form-control search-input-with-icon"
                        placeholder="Search"
                        v-model="searchQuery"
                        @input="onSearch"
                    />
                    <i class="fas fa-search search-input-icon"></i>
                    </div>

                <!-- Add Doctor Button -->
                <router-link to="/referee-add-doctor" class="btn btn-primary add-doctor-btn">
                Add Doctor
                </router-link>
            </div>
            </div>
            <div class="row row-grid">
              <!-- Cards Here -->
              <div
                class="col-md-6 col-lg-4 col-xl-3"
                v-for="doctor in filteredDoctors"
                :key="doctor.doctorid"
              >
                <div class="card widget-profile pat-widget-profile">
                  <div class="card-body">
                    <div class="pro-widget-content">
                      <div class="profile-info-widget">
                        <router-link
                          :to="'doctor/'+ doctor.doctorid"
                          class="booking-doc-img"
                        >
                          <img
                            :src="require(`@/assets/img/doctors/doctor-thumb-01.jpg`)"
                            alt="User Image"
                          />
                        </router-link>

                        <div class="profile-det-info">
                          <h3>
                            <router-link :to="'doctor/'+ doctor.doctorid">Dr.{{
                              doctor.doctorname
                            }}</router-link>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div class="patient-info">
                      <ul>
                        <li>
                          Phone<span>{{ doctor.contactnumbers }}</span>
                        </li>
                        <li>
                          Specialization<span class="specialization-text" :title="doctor.specialization" 
                          >{{ doctor.specialization }}</span>
                        </li>
                        <li>
                          Joining Date
                          <span>{{ formatDate(doctor.joiningdate) }}</span>
                        </li>
                        <li>
                          Current Month Count
                          <span>{{ doctor.cur_month_appointment_count }}</span>
                        </li>
                        <li>
                          Prev Month Count
                          <span>{{ doctor.prev_month_appointment_count }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Cards -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <referee-footer></referee-footer>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import patientlist from "@/assets/json/doctor/patientlist.json";
import axios from "axios";

export default {
  data() {
    return {
      title: "My Referrals",
      text: "Home",
      text1: "My Referrals",
      patientlist: patientlist,
      referedDoctors: [],
      searchQuery: "", // For search input
    };
  },
  computed: {
    filteredDoctors() {
        const query = this.searchQuery.trim().toLowerCase();
        if (!query) {
        return this.referedDoctors;
        }

        return this.referedDoctors.filter((doctor) => {
        const nameMatch = doctor.doctorname.toLowerCase().includes(query);
        const phoneMatch = doctor.contactnumbers
            .toLowerCase()
            .includes(query); 
        return nameMatch || phoneMatch;
        });
    },
    },
  methods: {
    async fetchReferedDoctors() {
      this.refereeid = sessionStorage.getItem("userdbid");
      try {
        const response = await axios.get(
          `referee/${this.refereeid}/doctors`
        );
        this.referedDoctors = response.data.doctors;
        this.activeDoctor = response.data.active_count;
        this.TotalRefer = response.data.total_count;
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    },
    formatDate(dateTimeString) {
      const date = new Date(dateTimeString);
      const options = { day: "numeric", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    },
    onSearch() {
      // Triggered on search input
      console.log("Search Query:", this.searchQuery);
    },
  },
  mounted() {
    this.fetchReferedDoctors();
  },
};
</script>

<style>
.search-bar {
  margin: 20px 0;
}

.input-group {
  width: auto;
  max-width: 250px !important; /* Set a smaller width */
}

.add-doctor-btn {
  margin-left: 20px;
}
.search-input-with-icon {
  width: 100%;
  padding: 10px 15px;
  padding-right: 40px; /* Add padding to make space for the icon */
  border: 1px solid #ddd;
  border-radius: 25px; /* Optional: Rounded input */
  outline: none;
  font-size: 14px;
}

.search-input-icon {
  position: absolute;
  right: 15px; /* Position the icon inside the input */
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d; /* Neutral color */
  font-size: 16px; /* Adjust icon size */
  pointer-events: none; /* Make the icon non-clickable */
}

.search-input-with-icon:focus {
  border-color: #007bff;
}

.specialization-text {
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: inline-block; 
  max-width: 130px; 
  cursor: pointer; 
}


</style>
