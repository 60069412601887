<template>
  <div class="main-wrapper">
    <layoutheader />
    <breadcrumb-1 :title="title" :text="text" :text1="text1" />


    <indexdoctor />

    <indextestimonial />

    <indexapp />

    <cursor />
    <footerindex />
  </div>
</template>
<script>
import breadcrumb1 from '@/components/frontend/breadcrumb/breadcrumb1.vue';
export default {
  components: { breadcrumb1 },
  name: "doctorindex",
  data() {
    return {
      title: "Better reach and visibility",
      text: "Home",
      text1: "Doctor Home",
    }
  }
};
</script>
