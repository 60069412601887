<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <referee-header></referee-header>
        <breadcrumb-referee :title="title" :text="text" :text1="text1" />
        <!-- Page Content -->
        <div class="content">
            <div class="container">
                <div class="row">
                    <referee-sidebar></referee-sidebar>

                    <div class="col-md-7 col-lg-8 col-xl-9">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="appointment-tab">
                                    <!-- Appointment Tab -->

                                    <!-- <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
                      <h3 style="margin-right:auto; margin-left:8px">REPORT</h3>
  
                    </ul> -->
                                    <!-- /Appointment Tab -->
                                    <!-- king -->
                                    <div class="date-range-selection-wrapper">
                                        <div class="date-range-selection">
                                            <div class="dropdown-container" style="margin-right: 2px;">
                                                <div class="custom-select-wrapper">
                                                    <select v-model="selectedYear" class="custom-select-modern"
                                                        @change="checkSelectedYear()">
                                                        <option v-for="(stau, index) in status" :key="index"
                                                            :value="stau.value">
                                                            {{ stau.label }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="dropdown-container" style="margin-right: 2px;">
                                                <div class="custom-select-wrapper">
                                                    <select v-model="selectedMonth" class="custom-select-modernx"
                                                        @change="checkSelectedMonth()">
                                                        <option v-for="(stau, index) in monthsx" :key="index"
                                                            :value="stau.value">
                                                            {{ stau.label }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="dropdown-container" style="margin-right: 2px;">
                                                <div class="custom-select-wrapper">
                                                    <select v-model="selectedDoctor" class="custom-select-modernx"
                                                        @change="checkSelectedDoctor()">
                                                        <option v-for="(stau, index) in referedDoctorsListDoctors"
                                                            :key="index" :value="stau.value">
                                                            {{ stau.label }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="export-buttons">
                                            <div class="download-report">
                                                <p class="download-text">Download report</p>
                                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="PDF"
                                                    @click="exportAppointments('pdf')">
                                                    <img src="@/assets/img/pdf.svg" alt="Export to PDF"
                                                        class="export-iconx" />
                                                </a>
                                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"
                                                    @click="exportAppointments('excel')">
                                                    <img src="@/assets/img/excel.svg" alt="Export to Excel"
                                                        class="export-iconx" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-content">
                                        <!-- monthly -->
                                        <!-- Upcoming Appointment Tab -->

                                        <div class="tab-pane show active">
                                            <div class="card card-table mb-0">
                                                <div class="card-body">
                                                    <div class="table-responsive">
                                                        <table class="table table-hover table-center mb-0">
                                                            <tbody>
                                                                <!-- visited -->
                                                                <!-- king -->
                                                                <tr v-for="doctor in referedDoctorsList"
                                                                    :key="doctor.doctorid">
                                                                    <td>
                                                                        <h2 class="table-avatar">
                                                                            <router-link :to="'doctors-report'"
                                                                                class="avatar avatar-sm me-2">
                                                                                <i
                                                                                    class="fa-solid fa-user-md fa-xl"></i>
                                                                            </router-link>
                                                                            <router-link :to="'doctors-report'">
                                                                                Doctor name
                                                                                <span>{{ doctor.DoctorName }}</span>
                                                                            </router-link>
                                                                        </h2>
                                                                    </td>
                                                                    <td>
                                                                        <h2 class="table-avatar">
                                                                            <router-link :to="'doctors-report'"
                                                                                class="avatar avatar-sm me-2">
                                                                                <i
                                                                                    class="fa-solid fa-calendar-week fa-xl"></i>
                                                                            </router-link>
                                                                            <router-link :to="'doctors-report'">
                                                                                Joining date
                                                                                <span>{{ formatDate(doctor.JoiningDate)
                                                                                    }}</span>
                                                                            </router-link>
                                                                        </h2>
                                                                    </td>
                                                                    <td>
                                                                        <h2 class="table-avatar">
                                                                            <router-link :to="'doctors-report'"
                                                                                class="avatar avatar-sm me-2">
                                                                                <i
                                                                                    class="fa-solid fa-calendar-week fa-xl"></i>
                                                                            </router-link>
                                                                            <router-link :to="'doctors-report'">
                                                                                Appointment month
                                                                                <span>{{
                                                                                    formatMonth(doctor.AppointmentMonth)
                                                                                    }}</span>
                                                                            </router-link>
                                                                        </h2>
                                                                    </td>
                                                                    <td>
                                                                        <h2 class="table-avatar">
                                                                            <router-link :to="'doctors-report'"
                                                                                class="avatar avatar-sm me-2">
                                                                                <i class="fa-solid fa-flag fa-xl"></i>
                                                                            </router-link>
                                                                            <router-link :to="'doctors-report'">
                                                                                Total appointments
                                                                                <span>{{ doctor.AppointmentCount
                                                                                    }}</span>
                                                                            </router-link>
                                                                        </h2>
                                                                    </td>
                                                                    <!-- <td class="text-end">
                                                                            <div class="table-action">
                                                                                <button class="main-btn">View
                                                                                    Details</button>
                                                                            </div>
                                                                        </td> -->
                                                                </tr>

                                                                <!-- absent -->
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <referee-footer></referee-footer>
</template>
<script>
import axios from "axios";
import "sweetalert2/dist/sweetalert2.css";
import { ref } from "vue";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";

export default {
    data() {
        const currentDate = new Date();
        return {
            selectedYear: '',
            selectedClinic: '',
            selectedMonth: '',
            selectedDoctor: '',
            // king
            status: [],
            monthsx: [
                { label: "All months", value: "" },
                { label: "January", value: "01" },
                { label: "February", value: "02" },
                { label: "March", value: "03" },
                { label: "April", value: "04" },
                { label: "May", value: "05" },
                { label: "June", value: "06" },
                { label: "July", value: "07" },
                { label: "August", value: "08" },
                { label: "September", value: "09" },
                { label: "October", value: "10" },
                { label: "November", value: "11" },
                { label: "December", value: "12" }
            ],
            referedDoctorsList: [],
            referedDoctorsListDoctors: [],
            title: "Reports",
            text: "Home",
            text1: "Reports",
            refereeDetails: '',
            reffullname: '',
            AllAppointmentsfilterexcel: [],
            AllAppointmentsfilterpdf: [],
        };
    },

    methods: {
        formatDate(date) {
            if (!date) return '';
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return new Date(date).toLocaleDateString('en-US', options);
        },
        formatMonth(month) {
            if (!month) return '';
            const date = new Date(month + '-01');
            return date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
        },
        checkSelectedYear() {
            this.referedDoctorsList = [];
            this.fetchReferedDoctors();
        },
        checkSelectedMonth() {
            this.referedDoctorsList = [];
            this.fetchReferedDoctors();
        },
        checkSelectedDoctor() {
            this.referedDoctorsList = [];
            this.fetchReferedDoctors();
        },
        checkSelectedYearx() {
            this.selectedYear = new Date().getFullYear();
            const startYear = this.refereeDetails;
            const endYear = this.selectedYear;
            this.status = [];
            for (let year = startYear; year <= endYear; year++) {
                this.status.push({ label: year.toString(), value: year.toString() });
            }
        },
        checkSelectedMonthx() {
            this.selectedMonth = '';
        },
        checkSelectedDoctorx() {
            this.selectedDoctor = '';
        },
        async fetchRefereeDetails() {
            const refereeId = sessionStorage.getItem("userdbid");
            try {
                let refDetails = sessionStorage.getItem(`refereeDetails${refereeId}`);
                if (!refDetails) {
                    const response = await axios.get(`/referee/${refereeId}`);
                    refDetails = response.data;
                    sessionStorage.setItem(
                        `refereeDetails${this.refereeId}`,
                        JSON.stringify(refDetails)
                    );
                } else {
                    refDetails = JSON.parse(refDetails);
                }
                console.log("refDetails", refDetails);
                this.refereeDetails = refDetails.createddate.slice(0, 4);
                this.reffullname = refDetails.fullname;
            } catch (error) {
                console.error("Error fetching doctor details:", error);
            }
        },
        async fetchReferedDoctors() {
            this.refereeid = sessionStorage.getItem("userdbid");
            try {
                let queryParams = `referee/${this.refereeid}/doctors/${this.selectedYear}`;

                if (this.selectedMonth || this.selectedDoctor) {
                    queryParams += '?';

                    if (this.selectedMonth) {
                        queryParams += `month=${this.selectedMonth}&`;
                    }

                    if (this.selectedDoctor) {
                        queryParams += `doctorid=${this.selectedDoctor}`;
                    }
                    queryParams = queryParams.replace(/&$/, '');
                }
                const response = await axios.get(queryParams);
                this.referedDoctorsList = response.data.doctors;
                const seen = new Set();
                this.referedDoctorsListDoctors = [
                    { label: "All doctors", value: "" },
                    ...this.referedDoctorsList
                        .map(doctor => ({
                            label: doctor.DoctorName,
                            value: doctor.DoctorId.toString()
                        }))
                        .filter(doctor => {
                            const duplicate = seen.has(doctor.value);
                            seen.add(doctor.value);
                            return !duplicate;
                        })
                ];
            } catch (error) {
                console.error("Error fetching appointments:", error);
            }
        },
        exportAppointments(format) {
            if (format === "pdf") {
                this.AllAppointmentsfilterpdf = this.referedDoctorsList;
                this.exportToPDF(this.AllAppointmentsfilterpdf);
            } else if (format === "excel") {
                this.AllAppointmentsfilterexcel = this.referedDoctorsList;
                this.exportToExcel(this.AllAppointmentsfilterexcel);
            }
        },

        exportToPDF(Appointments) {
            let appointments = [];
            let allAppointments = [];
            const doc = new jsPDF();
            const doctorName = this.reffullname;
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleDateString();
            const formattedTime = currentDate.toLocaleTimeString();
            const currentDateTimex = `${formattedDate} ${formattedTime}`;
            const formattedDoctorName = `Referee name: ${doctorName}`;
            const currentDateTime = `Date: ${currentDateTimex}`;
            let heading;
            heading = "Referee report";
            const gap = 10;
            appointments = this.referedDoctorsList.map(appointment => [
                appointment.DoctorName,
                new Date(appointment.JoiningDate).getFullYear(),
                new Date(appointment.AppointmentMonth + "-01").toLocaleString('en-US', { month: 'long', year: 'numeric' }),
                appointment.AppointmentCount,
            ]);
            allAppointments = allAppointments.concat(appointments);
            const gapx = 25;
            const yPos = gap;
            const textWidth =
                (doc.getStringUnitWidth(heading) * doc.internal.getFontSize()) /
                doc.internal.scaleFactor;
            const xPos = (doc.internal.pageSize.width - textWidth) / 2;
            doc.text(heading, xPos, yPos);
            const doctorNameFontSize = 10;
            const headingFontSize = 16;

            doc.setFontSize(doctorNameFontSize);
            doc.text(formattedDoctorName, 15, gapx);
            const infoFontSize = 10;
            const infoGap = gapx + 10;

            doc.setFontSize(infoFontSize);

            const rangeGap = infoGap + 10;
            doc.setFontSize(doctorNameFontSize);
            const pageWidth = doc.internal.pageSize.width;
            const currentDateTimeWidth = (doc.getStringUnitWidth(currentDateTime) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
            const rightAlignedXPos = pageWidth - currentDateTimeWidth - 15;
            doc.text(currentDateTime, rightAlignedXPos, gapx);

            doc.autoTable({
                head: [["Doctor name", "Joining date", "Appointment Month", "Total appointments",]],
                body: allAppointments,
                startY: rangeGap + 6
            });
            doc.save("doc-appointments-report.pdf");
        },
        exportToExcel(Appointments) {
            const doctorName = this.reffullname;
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Appointments");
            const currentDateTime = new Date().toLocaleString();
            worksheet.addRow([`Referee name: ${doctorName}`]);
            worksheet.addRow([`Date: ${currentDateTime}`]);
            worksheet.mergeCells('A1:G1');
            worksheet.mergeCells('A2:G2');
            worksheet.mergeCells('A3:G3');
            worksheet.addRow([
                "Doctor name", "Joining date", "Appointment Month", "Total appointments"
            ]).alignment = { horizontal: "center", vertical: "middle" };
            worksheet.columns = [
                { key: "DoctorName", width: 20 },
                { key: "JoiningDate", width: 20 },
                { key: "AppointmentMonth", width: 20 },
                { key: "AppointmentCount", width: 20 },
            ];
            Appointments.forEach(appointment => {
                const joiningYear = new Date(appointment.JoiningDate).getFullYear();
                const formattedMonth = new Date(appointment.AppointmentMonth + "-01").toLocaleString('en-US', { month: 'long', year: 'numeric' });
                const row = worksheet.addRow({
                    DoctorName: appointment.DoctorName,
                    JoiningDate: joiningYear,
                    AppointmentMonth: formattedMonth,
                    AppointmentCount: appointment.AppointmentCount,
                });
            row.eachCell(cell => {
                cell.alignment = { horizontal: "center", vertical: "middle" };
            });
        });
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "doc-appointments-report.xlsx";
            link.click();
        });
    }
},
    async mounted() {
    await this.fetchRefereeDetails();
    await this.checkSelectedYearx();
    await this.checkSelectedMonthx();
    await this.checkSelectedDoctorx();
    await this.fetchReferedDoctors();
},
name: "Referee-reports"
};
</script>
<style scoped>
.custom-select-wrapper {
    position: relative;
    display: inline-block;
}

.custom-select-modern {
    appearance: none;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    cursor: pointer;
    width: 100px;
    color: #000;
}

.custom-select-modern:focus {
    border-color: #245dd6;
    outline: none;
}

.custom-select-modernx {
    appearance: none;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    cursor: pointer;
    width: 150px;
    color: #000;
}

.custom-select-modernx:focus {
    border-color: #245dd6;
    outline: none;
}

.custom-select-wrapper::after {
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #555;
}

.date-range-selection-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.date-range-selection {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 5px;
}

.daterange-container {
    display: flex;
    align-items: center;
    padding: 2px;
}

@media (max-width: 768px) {
    .daterange-container {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }
}

.calendar-icon {
    font-size: 20px;
    color: #4caf50;
    margin-right: 1px;
}

.daterange-input {
    padding: 5px 10px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    cursor: pointer;
    color: #000;
}

.daterange-input:hover {
    background-color: #e0e0e0;
}

.daterange-input:focus {
    outline: none;
    background-color: #e0f7e0;
}

.daterange-to {
    margin: 0 10px;
    font-size: 16px;
    color: #333;
}

.dropdown-container {
    margin-left: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.custom-select {
    padding: 1px;
    border: none;
    border-radius: 4px;
}

.generate-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 6px 20px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 10px;
    margin-left: 5px;
    transition: background-color 0.3s;
}

.generate-button:hover {
    background-color: #1d7eda;
}

.export-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 75px;
}

.download-text {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    opacity: 0.5;
}

.download-report {
    display: flex;
    align-items: center;
}

.export-iconx {
    width: 40px;
    height: 30px;
    margin-left: 10px;
    cursor: pointer;
    align-items: top;
}


.export-iconx:hover {
    opacity: 0.7;
}


@media (max-width: 768px) {
    .export-iconx {
        margin-left: 10px;
        width: 25px;
        height: 45px;
    }
}

@media (max-width: 767px) {
    .export-buttons {
        margin-top: 10px;
    }
}


.modal-content .btn {
    cursor: auto;
}
</style>