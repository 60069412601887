<template>
  <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
    <div class="stickysidebar">
      <!-- Profile Sidebar -->
      <div class="profile-sidebar">
        <div class="widget-profile pro-widget-content">
          <a href="/referee-dashboard">
          <div class="profile-info-widget">
            <a href="/referee-dashboard" class="booking-doc-img">
              <img :src="profileImageUrl || defaultImageUrl" alt="User Image" />
            </a>
            <div href="/referee-dashboard" class="profile-det-info">
              <h3>{{ refereeDetails.fullname }}</h3>
              <div class="patient-details">
                <span class="qualification-tooltip"
                  > <i class="fa fa-phone" ></i>  {{
                    refereeDetails.contactnumber }}</span>
                <!-- {{ doctorDetails.specialization }} -->
              </div>
            </div>
          </div>
        </a>
        </div>
        <div class="dashboard-widget">
          <nav class="dashboard-menu">
            <ul>
              <li :class="{ active: isActive('/referee-dashboard') }">
                <router-link to="/referee-dashboard">
                  <i class="fas fa-columns"></i>
                  <span class="ms-1">Dashboard</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/referee-add-doctor') }">
                <router-link to="/referee-add-doctor">
                  <i class="fas fa-user-doctor"></i>
                  <span class="ms-1">Add Doctor</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/my-referrals') }">
                <router-link to="/my-referrals">
                  <i class="fas fa-user-group"></i>
                  <span class="ms-1">My Referrals</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/my-reports') }">
                <router-link to="/my-reports">
                  <i class="fas fa-file-alt"></i>
                  <span class="ms-1">Reports</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/referee-change-password') }">
                <router-link to="/referee-change-password">
                  <i class="fas fa-lock"></i>
                  <span class="ms-1">Change Password</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/') }">
                <router-link to @click="signOutUser">
                  <i class="fas fa-sign-out-alt"></i>
                  <span class="ms-1">Logout</span>
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <!-- /Profile Sidebar -->
    </div>
  </div>
</template>
<script>
import { signOut } from "aws-amplify/auth";
import axios from "axios";
import defaultImageUrl from "@/assets/img/patients/patient.jpg";
// import eventBus from "@/eventBus";

export default {
  data() {
    return {
      activeClass: "active",
      refereeDetails: [],
      defaultImageUrl: defaultImageUrl,
      profileImageUrl: ""
    };
  },
  methods: {
    isActive(paths) {
      return paths.includes(this.$route.path);
    },
    signOutUser() {
      signOut()
        .then(data => {
          this.$router.push("/login");
        })
        .catch(err => console.log(err));
    },
    async fetchDoctorProfileImage() {
      const doctorid = sessionStorage.getItem("userdbid");
      try {
        const response = await axios.get(
          `doctors/doctorimg/${doctorid}/download`,
          {
            responseType: "arraybuffer"
          }
        );

        const objectURL = window.URL.createObjectURL(new Blob([response.data]));
        this.profileImageUrl = objectURL;
      } catch (error) {
        console.error("Error fetching patient profile image:", error);
      }
    },
    // king
    async fetchRefereeDetails() {
      const refereeId = sessionStorage.getItem("userdbid");
      try {
        let refDetails = sessionStorage.getItem(`refereeDetails${refereeId}`);
        if (!refDetails) {
          const response = await axios.get(`/referee/${refereeId}`);
          refDetails = response.data;
          sessionStorage.setItem(
            `refereeDetails${this.refereeId}`,
            JSON.stringify(refDetails)
          );
        } else {
          refDetails = JSON.parse(refDetails);
        }
        this.refereeDetails = refDetails;
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    }
  },
  async mounted() {
    await this.fetchRefereeDetails();
  },

};
</script>


<style scoped>
/* .profile-sidebar .dashboard-menu > ul > li a {
  color: #ffff;
}

.profile-sidebar {
    background-color: #0E82FD;
} */
</style>
