<template>
  <div class="main-wrapper">
    <referee-header></referee-header>
    <breadcrumb-referee :title="title" :text="text" :text1="text1" />
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <referee-sidebar></referee-sidebar>
         <div class="col-md-7 col-lg-8 col-xl-9">
            <referee-add-doctor :refereeid="refereeid"/>
          </div>
        </div>
      </div>    
    </div>  
  </div>        

</template>
<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import defaultImageUrl from "@/assets/img/patients/patient.jpg";
import eventBus from "@/eventBus";
import cities from "@/assets/json/cities.json";

export default {
  data() {
    return {
      title: "Add Doctor",
      text: "Home",
      text1: "Add Doctor",
      refereeid: sessionStorage.getItem('userdbid')
    };
  },
  
};
</script>

<style scoped>
.b-alert-success {
  background-color: #4caf50;
  color: white;

}

.position-relative {
  position: relative;
}

.clear-icon {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 16px;
  color: #dc3545;
}

.form-control:disabled {
  background-color: hsla(0, 0%, 94%, 0.462);
  color: black;
}

.v3dp__elements[data-v-65eb861b] button.selected span {
  background-color: #20c0f3 !important;
  color: aliceblue !important;
}

.v3dp__elements[data-v-65eb861b] button:not(:disabled):hover span {
  background-color: #20c0f3 !important;
  color: aliceblue !important;
}
</style>
   