<template>
  <div class="tab-pane fade" id="pat_prescriptions">
    <div class="card card-table mb-0">
      <div class="align-container">
        <div class="left-align">
          <i class="fa fa-long-arrow-left" aria-hidden="true" @click="scrollTableLeft"></i>
        </div>
        <div class="right-align">
          <i class="fa fa-long-arrow-right" aria-hidden="true" @click="scrollTableRight"></i>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive" ref="tableContainer">
          <table class="table table-hover table-center mb-0">
            <thead>
              <tr>
                <th>Patient Name</th>
                <th>Date</th>
                <th>Clinic Name</th>
                <th>Doctor Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in pastAppointments" :key="item.appointment_id">
                <td>
                  <h2 class="table-avatar">
                    <span class="avatar avatar-sm me-2">
                      <img src="@/assets/img/patients/patient.jpg" alt="User Image" />
                    </span>
                    {{ item.patient_name }}
                  </h2>
                  <br />
                  <div class="status-label mt-2">
                    <template v-if="item.appointmentstatus === 'A'">
                      <span class="badge rounded-pill bg-warning-light">Absent</span>
                    </template>

                    <template v-else-if="item.appointmentstatus === 'C'">
                      <span class="badge rounded-pill bg-danger-light">Cancelled</span>
                    </template>

                    <template v-else-if="item.appointmentstatus === 'E'">
                      <span class="badge rounded-pill bg-warning-light">Absent</span>
                    </template>

                    <template v-else-if="item.prescripid > 0">
                      <a
                        href="javascript:void(0);"
                        class="btn btn-sm bg-info-light"
                        @click="viewPrescription(item)"
                      >
                        <i class="far fa-eye"></i> View Prescription
                      </a>
                    </template>

                    <template v-else-if="item.appointmentstatus === 'V' && item.prescripid < 1">
                      <span class="badge rounded-pill bg-success-light">Visited</span>
                    </template>
                  </div>
                </td>
                <td>{{ formatDate(item.date_and_time) }}</td>
                <td>{{ item.clinic_name }}</td>
                <td>
                  <h2 class="table-avatar">
                    <router-link :to="'doctor/' + item.doctor_id" class="avatar avatar-sm me-2">
                      <img
                        class="avatar-img rounded-circle"
                        :src="//item.doctor_image ? require('@/assets/img/doctors/'+item.doctor_image) : 
                        require('@/assets/img/doctors/doctor-thumb-01.jpg')"
                        alt="Doctor Image"
                      />
                    </router-link>
                    <router-link :to="'doctor/' + item.doctor_id">
                      Dr. {{ item.doctor_name }}
                      <span>{{ item.speciality }}</span>
                    </router-link>
                  </h2>
                </td>
                <td class="text-end">
                  <div v-if="(item.appointmentstatus ==='V')" class="table-action">
                    <a
                      href="javascript:void(0)"
                      class="btn btn-sm bg-info-light"
                      data-bs-toggle="modal"
                      data-bs-target="#appointment_medical_records_modal"
                      @click="addMedicalRecords(item.appointment_id, item.patientid, item.doctor_id, item.doctor_name)"
                    >
                      <i class="fas fa-file-medical"></i> Add Medical Record
                    </a>
                    <a
                      href="javascript:void(0);"
                      class="btn btn-sm bg-info-light ms-1"
                      data-bs-toggle="modal"
                      data-bs-target="#view_medical_records_modal"
                      @click="viewMedicalRecords(item.patientid, item.appointment_id, item.doctor_id)"
                    >
                      <i class="far fa-eye"></i> View Records
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isViewModalOpen" class="modal-overlay" @click.self="closeViewModal">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Prescription</h2>
        <button @click="closeViewModal">✕</button>
      </div>

      <div id="prescription-modal" class="modal-body">
        <div class="doctor-clinic-info">
          <p>
            <strong>Dr. {{ apptdata.doctor_name }}</strong>
          </p>
          <p>{{ apptdata.speciality }}</p>
          <p>{{ apptdata.clinic_name }}</p>
        </div>

        <div class="prescription-header">
          <span v-html="apptdata.formattedDate"></span>

          <span>
            <strong>Patient:</strong>
            {{ apptdata.patient_name }}
          </span>
        </div>
        <span class="rx-symbol">℞</span>
        <div class="patient-description-section">
          <h3>Patient Description:</h3>
          <p v-html="apptdata.prescripNote"></p>
        </div>
        <h3>Medicines:</h3>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Medicine Name</th>
              <th>Brand Name</th>
              <th>Dosage</th>
              <th>Frequency</th>
              <th>Duration</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="medication in prescriptionData" :key="medication.medicinename">
              <td>{{ medication.medicinename }}</td>
              <td>{{ medication.brandname }}</td>
              <td>{{ medication.dosage }}</td>
              <td>{{ medication.frequency }}</td>
              <td>{{ medication.duration }}</td>
              <td>{{ medication.note }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="modal-footer">
        <button v-if="!isMobile" @click="printPrescription">Print</button>
        <button @click="downloadPrescriptionPDF">Download as PDF</button>
        <button @click="closeViewModal">Close</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
export default {
  props: {
    pastAppointments: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isViewModalOpen: false,
      prescriptionData: [],
      patientName: "",
      doctorName: "",
      showPrescriptionModal: false
    };
  },
  computed: {
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Exclude Windows devices
      const isWindows = /Windows/i.test(userAgent);

      const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

      const isMobile = /Mobi|Android/i.test(userAgent);

      return (isMobile || isiOS) && !isWindows;
    }
  },
  methods: {
    async viewPrescription(appt_details) {
      try {
        const prescripdata = await this.fetchPrescripDetails(
          appt_details.patientid,
          appt_details.appointment_id,
          appt_details.prescripid
        );
        if (prescripdata) {
          this.apptdata = prescripdata.appointment_details;
          this.prescriptionData = prescripdata.prescription_medications;
          const prescripNote = prescripdata.prescriptions[0].note;
          this.apptdata.prescripNote = prescripNote
            ? prescripNote.replace(/\n/g, "<br>")
            : "No description provided.";
          this.openViewModal();
        }
        this.apptdata.formattedDate = await this.formattedDate(
          appt_details.date_and_time
        );
        /*const response = await axios.get(
          `/patients/1/prescriptions/${appt_details.prescripid}/medications`
        );*/
        //this.prescriptionData = response.data;
      } catch (error) {
        console.error("Error fetching prescription data:", error);
      }
    },
    openViewModal() {
      this.$emit("openModal");
      this.isViewModalOpen = true;
    },
    closeViewModal() {
      this.$emit("closeModal");
      this.isViewModalOpen = false;
    },
    async formattedDate(dateString) {
      const date = new Date(dateString);

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];

      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      let hours = date.getHours();
      const minutes = date
        .getMinutes()
        .toString()
        .padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;

      return `<strong>Date:</strong> ${month} ${day}, ${year}, <strong>Time:</strong> ${hours}:${minutes} ${ampm}`;
    },
    formatDate(dateTimeString) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(dateTimeString).toLocaleDateString("en-US", options);
    },
    addMedicalRecords(appointmentId, patientId, doctorId, doctor_name) {
      this.$emit("add-medical-records", {
        appointmentId,
        patientId,
        doctorId,
        doctor_name
      });
    },
    viewMedicalRecords(patientId, appointmentId, doctorId) {
      this.$emit("view-medical-records", {
        patientId,
        appointmentId,
        doctorId
      });
    },
    async downloadPrescriptionPDF() {
      try {
        const prescripNote = this.apptdata.prescripNote
          ? this.apptdata.prescripNote.replace(/\n/g, "<br>")
          : "No description provided.";
        const apptdata = this.apptdata;
        const prescriptionData = this.prescriptionData;
        const logoUrl = require("@/assets/img/logo.png");
        const doc = await this.fetchDoctorDetails(apptdata.doctor_id);
        const clinicSettings = await this.fetchClinicSettings(
          apptdata.doctor_id,
          apptdata.clinicid
        );
        const headerImage = clinicSettings.prescrip_header_image
          ? `data:image/png;base64,${clinicSettings.prescrip_header_image}`
          : null;
        const footerImage = clinicSettings.prescrip_footer_image
          ? `data:image/png;base64,${clinicSettings.prescrip_footer_image}`
          : null;
        const doctorSignature = clinicSettings.docsignature
          ? `data:image/png;base64,${clinicSettings.docsignature}`
          : null;

        const tableRows = prescriptionData
          .map(
            medication => `
      <tr>
        <td>${medication.medicinename}</td>
        <td>${medication.brandname}</td>
        <td>${medication.dosage}</td>
        <td>${medication.frequency}</td>
        <td>${medication.duration}</td>
        <td>${medication.note || "-"}</td>
      </tr>`
          )
          .join("");

        const tableHTML = `
      <table>
        <thead>
          <tr>
            <th>Medicine Name</th>
            <th>Brand Name</th>
            <th>Dosage</th>
            <th>Frequency</th>
            <th>No. of Days</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
      </table>
    `;

        const htmlContent = `
      <html>
        <head>
          <title>Prescription - YourDoc</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              color: black;
              margin: 0;
              padding: 0;
              height: 297mm;
              width: 210mm;
              display: flex;
              flex-direction: column;
            }
            .content-wrapper {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: calc(297mm - 20mm);
              page-break-before: avoid;
              page-break-inside: always;
              margin: 0;
              padding: 0;
            }
            .header, .footer {
              page-break-inside: avoid;
            }
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #000;
              padding-bottom: 10px;
              margin-bottom: 20px;
            }
            .header-left, .header-right {
              width: 45%;
            }
            .header-image {
              max-width: 100px;
              height: auto;
            }
            .doctor-info {
              text-align: left;
            }
            .doctor-info p {
              margin: 2px 0;
            }
            .clinic-info {
              text-align: right;
            }
            .clinic-info p {
              margin: 2px 0;
            }
            .patient-details {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 14px;
              margin: 10px 0;
            }
            .patient-details p {
              margin: 0; 
            }
            .patient-details p strong {
              font-weight: bold;
            }
            .prescription-details {
              flex: 1;
            }
            
            .rx-symbol {
              font-size: 18px;
              margin: 2px 0;
              text-align: left;
              font-weight: bold;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 10px;
            }
            th, td {
              padding: 8px;
              border: 1px solid #ddd;
            }
            th {
              background-color: #f4f4f4;
              text-align: left;
            }
            tr {
              page-break-inside: avoid;
            }
            .doctor-signature {
              margin-top: 20px;
              text-align: right;
            }
            .doctor-signature img {
              max-width: 200px;
              height: auto;
            }
            .footer {
              text-align: right;
              margin-top: auto;
              background: none;
            }
            .footer-image {
              width: 100%;
              max-height: 40px;
            }
            .powered-by {
              margin-top: 10px;
              display: flex;
              justify-content: flex-end;
              align-items: right;
            }
            .powered-by img {
              max-height: 30px;
              width: auto;
            }
          </style>
        </head>
        <body>
          <div class="content-wrapper">
            <div class="header">
              <div class="header-left">
                <div class="doctor-info">
                  <p><strong>Dr. ${apptdata.doctor_name}</strong></p>
                  <p>${apptdata.speciality}</p>
                  <p>${doc.qualifications} | Reg. No: ${
          doc.registration_info
        }</p>
                </div>
              </div>
              ${
                headerImage
                  ? `<img src="${headerImage}" class="header-image" />`
                  : ""
              }
              <div class="header-right">
                <div class="clinic-info">
                  <p><strong>${apptdata.clinic_name}</strong></p>
                  <p>${apptdata.clinicaddress}</p>
                  <p>Phone: ${apptdata.clinic_phone}</p>
                </div>
              </div>
            </div>

            <div class="prescription-details">
              <div class="patient-details">
                <p><strong>Patient Name:</strong> ${apptdata.patient_name}</p>
                <p>${apptdata.formattedDate}</p>
              </div>
              <p><strong>Prescription Note:</strong> 
              ${prescripNote ? `${prescripNote}` : ""}</p>
              <div class="rx-symbol">℞</div>
              <div>${tableHTML}</div>

              ${
                doctorSignature
                  ? `<div class="doctor-signature">
                <img src="${doctorSignature}" alt="Doctor Signature" />
                <p><strong>Dr. ${apptdata.doctor_name}</strong></p>
                <p>${apptdata.speciality}</p>
              </div>`
                  : ""
              }
            </div>

            <div class="footer">
              ${
                footerImage
                  ? `<img src="${footerImage}" class="footer-image" />`
                  : ""
              }
              <div class="powered-by">
                <span>Powered By</span>
                <img src="${logoUrl}" alt="YourDoc Logo" />
              </div>
            </div>
          </div>
        </body>
      </html>
    `;

        const element = document.createElement("div");
        element.innerHTML = htmlContent;
        const opt = {
          margin: 5,
          filename: `prescription_${apptdata.patient_name}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          pagebreak: { mode: ["css", "legacy"], avoid: ".footer" }
        };

        html2pdf()
          .set(opt)
          .from(element)
          .save();
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
    async printPrescription() {
      try {
        const prescripNote = this.apptdata.prescripNote
          ? this.apptdata.prescripNote.replace(/\n/g, "<br>")
          : "No description provided.";
        const apptdata = this.apptdata;
        const prescriptionData = this.prescriptionData;
        const logoUrl = require("@/assets/img/logo.png");
        const doc = await this.fetchDoctorDetails(apptdata.doctor_id);
        const clinicSettings = await this.fetchClinicSettings(
          apptdata.doctor_id,
          apptdata.clinicid
        );
        const headerImage = clinicSettings.prescrip_header_image
          ? `data:image/png;base64,${clinicSettings.prescrip_header_image}`
          : null;
        const footerImage = clinicSettings.prescrip_footer_image
          ? `data:image/png;base64,${clinicSettings.prescrip_footer_image}`
          : null;
        const doctorSignature = clinicSettings.docsignature
          ? `data:image/png;base64,${clinicSettings.docsignature}`
          : null;

        const tableRows = prescriptionData
          .map(
            medication => `
      <tr>
        <td>${medication.medicinename}</td>
        <td>${medication.brandname}</td>
        <td>${medication.dosage}</td>
        <td>${medication.frequency}</td>
        <td>${medication.duration}</td>
        <td>${medication.note || "-"}</td>
      </tr>`
          )
          .join("");

        const tableHTML = `
      <table>
        <thead>
          <tr>
            <th>Medicine Name</th>
            <th>Brand Name</th>
            <th>Dosage</th>
            <th>Frequency</th>
            <th>No. of Days</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
      </table>
    `;

        const htmlContent = `
      <html>
        <head>
          <title>Prescription - YourDoc</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              color: black;
              margin: 0;
              padding: 0;
              height: 297mm;
              width: 210mm;
              display: flex;
              flex-direction: column;
            }
            .content-wrapper {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: calc(297mm - 20mm);
              page-break-before: avoid;
              page-break-inside: always;
              margin: 0;
              padding: 0;
            }
            .header, .footer {
              page-break-inside: avoid;
            }
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #000;
              padding-bottom: 10px;
              margin-bottom: 20px;
            }
            .header-left, .header-right {
              width: 45%;
            }
            .header-image {
              max-width: 100px;
              height: auto;
            }
            .doctor-info {
              text-align: left;
            }
            .doctor-info p {
              margin: 2px 0;
            }
            .clinic-info {
              text-align: right;
            }
            .clinic-info p {
              margin: 2px 0;
            }
            .patient-details {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 14px;
              margin: 10px 0;
            }
            .patient-details p {
              margin: 0; 
            }
            .patient-details p strong {
              font-weight: bold;
            }
            .prescription-details {
              flex: 1;
            }
            
            .rx-symbol {
              font-size: 18px;
              margin: 2px 0;
              text-align: left;
              font-weight: bold;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 10px;
            }
            th, td {
              padding: 8px;
              border: 1px solid #ddd;
            }
            th {
              background-color: #f4f4f4;
              text-align: left;
            }
            tr {
              page-break-inside: avoid;
            }
            .doctor-signature {
              margin-top: 20px;
              text-align: right;
            }
            .doctor-signature img {
              max-width: 200px;
              height: auto;
            }
            .footer {
              text-align: right;
              margin-top: auto;
              background: none;
            }
            .footer-image {
              width: 100%;
              max-height: 40px;
            }
            .powered-by {
              margin-top: 10px;
              display: flex;
              justify-content: flex-end;
              align-items: right;
            }
            .powered-by img {
              max-height: 30px;
              width: auto;
            }
          </style>
        </head>
        <body>
          <div class="content-wrapper">
            <div class="header">
              <div class="header-left">
                <div class="doctor-info">
                  <p><strong>Dr. ${apptdata.doctor_name}</strong></p>
                  <p>${apptdata.speciality}</p>
                  <p>${doc.qualifications} | Reg. No: ${
          doc.registration_info
        }</p>
                </div>
              </div>
              ${
                headerImage
                  ? `<img src="${headerImage}" class="header-image" />`
                  : ""
              }
              <div class="header-right">
                <div class="clinic-info">
                  <p><strong>${apptdata.clinic_name}</strong></p>
                  <p>${apptdata.clinicaddress}</p>
                  <p>Phone: ${apptdata.clinic_phone}</p>
                </div>
              </div>
            </div>

            <div class="prescription-details">
              <div class="patient-details">
                <p><strong>Patient Name:</strong> ${apptdata.patient_name}</p>
                <p>${apptdata.formattedDate}</p>
              </div>
              <p><strong>Prescription Note:</strong> 
              ${prescripNote ? `${prescripNote}` : ""}</p>
              <div class="rx-symbol">℞</div>
              <div>${tableHTML}</div>

              ${
                doctorSignature
                  ? `<div class="doctor-signature">
                <img src="${doctorSignature}" alt="Doctor Signature" />
                <p><strong>Dr. ${apptdata.doctor_name}</strong></p>
                <p>${apptdata.speciality}</p>
              </div>`
                  : ""
              }
            </div>

            <div class="footer">
              ${
                footerImage
                  ? `<img src="${footerImage}" class="footer-image" />`
                  : ""
              }
              <div class="powered-by">
                <span>Powered By</span>
                <img src="${logoUrl}" alt="YourDoc Logo" />
              </div>
            </div>
          </div>
        </body>
      </html>
    `;

        const element = document.createElement("div");
        element.innerHTML = htmlContent;
        const opt = {
          margin: 5,
          filename: `prescription_${apptdata.patient_name}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          pagebreak: { mode: ["css", "legacy"], avoid: ".footer" }
        };

        html2pdf()
          .set(opt)
          .from(element)
          .toPdf()
          .get("pdf")
          .then(function(pdf) {
            pdf.autoPrint();
            const pdfBlob = pdf.output("bloburl");

            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.src = pdfBlob;

            document.body.appendChild(iframe);
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
          });
      } catch (error) {
        console.error("Error printing prescription:", error);
      }
    },
    scrollTableRight() {
      const tableContainer = this.$refs.tableContainer;
      tableContainer.scrollBy({
        left: 200,
        behavior: "smooth"
      });
    },

    scrollTableLeft() {
      const tableContainer = this.$refs.tableContainer;
      tableContainer.scrollBy({
        left: -200,
        behavior: "smooth"
      });
    },
    async fetchPrescripDetails(patientid, appointmentid, prescripid) {
      try {
        const response = await axios.get(
          `/patients/${patientid}/appointmentdetails/${appointmentid}?prescripid=${prescripid}`
        );
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchDoctorDetails(doctor_id) {
      try {
        const response = await axios.get(`/doctors/${doctor_id}`);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchClinicSettings(doctor_id, clinic_id) {
      try {
        const response = await axios.get(
          `/doctors/${doctor_id}/doctorclinic-settings/${clinic_id}`
        );
        return response.data;
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
<style scoped>
.left-align {
  text-align: left;
  font-size: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.align-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-align {
  text-align: right;
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
}
.status-label {
  margin-left: 40px;
}

@media print {
  body {
    font-size: 14px;
    margin: 0;
  }
  .non-printable {
    display: none;
  }
}
.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 10px;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  width: 850px;
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 850px;
  max-height: 95vh;
  min-height: 95vh;
}

.modal-header h2 {
  margin: 0;
}

.modal-body {
  margin-top: 20px;
  overflow-y: auto;
  max-height: 300;
  max-height: 69vh;
  min-height: 69vh;
}

.doctor-clinic-info p {
  margin: 0;
}

.prescription-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.rx-symbol {
  font-size: 24px;
  font-weight: bold;
}
.patient-description-section {
  margin-top: none;
  margin-bottom: 5px;
  padding: 5px;
  color: black;
}
.table-striped {
  width: 100%;
  margin-top: 20px;
}

.table-striped th,
.table-striped td {
  text-align: left;
  padding: 8px;
}

.table-striped th {
  white-space: nowrap;
}

.modal-footer {
  margin-top: 20px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

button {
  padding: 8px 12px;
  margin-left: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
</style>